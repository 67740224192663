import { combineReducers } from 'redux';
import assistant from './assistant';
import document from './document';
import feedback from './feedback';
import lang from './lang';
import query from './query';
import results from './results';
import wizardSearch from './wizard-search';
import suggestionSearch from './suggestion';
import permits from './permit';
import commune from './commune';
import drupal from './drupal';
import sidePanelOverviewWizard from './side-panel-overview-wizard';

const rootReducer = combineReducers({
  assistant,
  document,
  feedback,
  lang,
  query,
  results,
  wizardSearch,
  suggestionSearch,
  permits,
  commune,
  drupal,
  sidePanelOverviewWizard
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
