import {
  DOCUMENT_LOADING,
  GET_DOCUMENT,
  GET_ERRORS,
} from '../../constants/types';
import { Action } from '../../constants/interfaces';

const initialState = {
  item: {},
  loading: false,
};

export default function (state = initialState, action: Action) {
  const { payload, type } = action;

  switch (type) {
    case DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCUMENT:
      // eslint-disable-next-line no-case-declarations
      const base = {
        ...state,
        loading: false,
      };
      return payload
        ? {
            ...base,
            item: payload,
          }
        : base;
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
