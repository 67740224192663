import React, { useEffect, useState } from 'react';
import star_icon from '../../assets/images/star.svg';

import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';

import getTranslation, { TypeTranslation } from '../../constants/translations';

export const MandatoryCip = ({
  showChip,
  hoverEffect,
}: {
  showChip: boolean;
  hoverEffect?: boolean;
}) => {
  const lang = useSelector((state: RootState) => state?.lang);

  const [mandatoryText, setMandatoryText] = useState<string>('');

  useEffect(() => {
    setMandatoryText(
      getTranslation(lang, 'page.mandatoryText', TypeTranslation.LANDING)
    );
  }, [lang]);

  if (hoverEffect) {
    return (
      <div
        className={`${
          showChip
            ? 'mandatory-icon-has-hover-effect'
            : 'mandatory-icon-display-has-hover-none'
        }`}
      >
        <img src={star_icon} alt="star icon" />
        <span>{mandatoryText}</span>
      </div>
    );
  } else {
    return (
      <span
        className={`${
          showChip ? 'mandatory-icon' : 'mandatory-icon-display-none'
        }`}
      >
        <img src={star_icon} alt="star icon" />
        {mandatoryText}
      </span>
    );
  }
};
