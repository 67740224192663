import {
  GET_WIZARD_SEARCH,
  WIZARD_SEARCH_LOADING,
  GET_ERRORS,
  WIZARD_RESET_DATA,
} from '../../constants/types';
import { wizardDataModelDTO } from '../../models/wizardDataModelDTO';

interface NaceSearchInitialStateInterface {
  wizardData: wizardDataModelDTO[];
  wizardDataLoading: boolean;
}

const initialState: NaceSearchInitialStateInterface = {
  wizardData: [],
  wizardDataLoading: false,
};

type wizardDataDispatchType = {
  payload: wizardDataModelDTO[];
  type: string;
};

export default (
  state: NaceSearchInitialStateInterface = initialState,
  action: wizardDataDispatchType
): NaceSearchInitialStateInterface => {
  const { payload, type } = action;

  switch (type) {
    case WIZARD_SEARCH_LOADING:
      return {
        ...state,
        wizardDataLoading: true,
      };
    case GET_WIZARD_SEARCH:
      return {
        wizardData: payload ? payload : initialState?.wizardData,
        wizardDataLoading: false,
      };
    case GET_ERRORS:
      return {
        ...state,
        wizardDataLoading: false,
      };
    case WIZARD_RESET_DATA:
      return {
        wizardData: [],
        wizardDataLoading: false,
      };
    default:
      return state;
  }
};
