import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import Landingv2 from './screens/landingv2/Landingv2';
import { Wizardv2 } from './screens/wizardv2/wizardv2';

import store from './store';
import './styles/App.css';

const Footer = lazy(() => import('./components/navigation/Footer'));
const Home = lazy(() => import('./screens/Home'));
const Landing = lazy(() => import('./screens/Landing'));
const NotFound = lazy(() => import('./screens/NotFound'));
const Result = lazy(() => import('./screens/Result'));
const RouteContainer = lazy(
  () => import('./components/navigation/RouteContainer')
);
const Search = lazy(() => import('./screens/Search'));

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={null}>
        <Router>
          <div className="App">
            <Suspense fallback={null}>
              <Switch>
                <RouteContainer exact path="/" component={Landingv2} />
                <RouteContainer exact path="/landing" component={Landingv2} />
                {/* <RouteContainer exact path="/wizard" component={Wizardv2} /> */}
                <RouteContainer exact path="/home" component={Home} />
                <RouteContainer exact path="/search" component={Search} />
                <RouteContainer
                  exact
                  path="/result/:result"
                  component={Result}
                />
                <RouteContainer component={NotFound} />
              </Switch>
            </Suspense>
          </div>
        </Router>
        <Footer />
      </Suspense>
    </Provider>
  );
};

export default App;
