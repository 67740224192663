import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Belgium_logo from '../../assets/images/Belgium_logo.png';
import { Lang } from '../../constants/enums';
import { setLang } from '../../store/actions/lang';
import { RootState } from '../../store/reducers';

export const Headerv2 = ({ headerTitle }: { headerTitle: string }) => {
  const lang = useSelector((state: RootState) => state?.lang);

  const dispatch = useDispatch();

  const changeLanguage = (newLanguage: Lang): void => {
    dispatch(setLang(newLanguage));
  };

  const isLanguageActive = (language: string): string => {
    return lang === language ? 'language-active' : '';
  };

  return (
    <header className="header-landing">
      <div>
        <div className="language-container">
          <div
            className={`${isLanguageActive('nl')}`}
            onClick={() => changeLanguage(Lang.NL)}
          >
            NL
          </div>
          <div
            className={`${isLanguageActive('fr')}`}
            onClick={() => changeLanguage(Lang.FR)}
          >
            FR
          </div>
          <div
            className={`${isLanguageActive('de')}`}
            onClick={() => changeLanguage(Lang.DE)}
          >
            DE
          </div>
          <div
            className={`${isLanguageActive('en')}`}
            onClick={() => changeLanguage(Lang.EN)}
          >
            EN
          </div>
        </div>
      </div>
      <div
        className="landing-title"
        dangerouslySetInnerHTML={{ __html: headerTitle }}
      />
      <img className="belgium-logo" src={Belgium_logo} alt="Belgium logo" />
    </header>
  );
};
