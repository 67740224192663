import {
  GET_PERMIT_DATA,
  GET_PERMIT_LOADING,
  GET_PERMIT_ERRORS,
  RESET_PERMIT_DATA,
} from '../../constants/types';
import { Permit } from '../../models/permit.interface';

interface InitialPermitState {
  permitData: Permit[];
  permitLoading: boolean;
}

const initialState: InitialPermitState = {
  permitData: [
    {
      a4_activity: '',
      type: '',
      local: '',
      description: '',
      contact: '',
      relevance: '',
      direction: '',
      administration: '',
      procedure: undefined,
      Language: '',
    },
  ],
  permitLoading: false,
};

type permitDataDispatchType = {
  payload: Permit[];
  type: string;
};

export default (
  state: InitialPermitState = initialState,
  action: permitDataDispatchType
): InitialPermitState => {
  const { payload, type } = action;

  switch (type) {
    case GET_PERMIT_LOADING:
      return {
        ...state,
        permitLoading: true,
      };
    case GET_PERMIT_DATA:
      return {
        permitData: payload ? payload : initialState?.permitData,
        permitLoading: false,
      };
    case GET_PERMIT_ERRORS:
      return {
        ...state,
        permitLoading: false,
      };
    case RESET_PERMIT_DATA:
      return {
        permitData: [],
        permitLoading: false,
      };
    default:
      return state;
  }
};
