import { SET_LANG } from '../../constants/types';
import { Action } from '../../constants/interfaces';
import { Lang } from '../../constants/enums';

export const getLangEnum = (value: string) => {
  switch (value) {
    case 'en':
      return Lang.EN;
    case 'de':
      return Lang.DE;
    case 'fr':
      return Lang.FR;
    case 'nl':
      return Lang.NL;
    default:
      return Lang.EN;
  }
};

let lang: Lang = Lang.NL;
if (window?.location?.search) {
  const urlSearchParams = window?.location?.search.substr(1);
  const searchParams = new URLSearchParams(urlSearchParams);

  const newLang = searchParams.get('lang');
  if (newLang && newLang !== '') {
    lang = getLangEnum(newLang);
  }
}

const initialState = lang;

export default function (state = initialState, action: Action) {
  const { payload, type } = action;

  switch (type) {
    case SET_LANG:
      return payload;
    default:
      return state;
  }
}
