export default {
    main: {
        buttonStartAgain: 'Start again',
        buttonNext: 'Next',
        buttontSkip: 'Skip',
        buttonPrevious: 'Previous',
        stepperStep1Text: 'Enterprise location',
        stepperStep2Text: 'NACE codes',
        stepperStep3Text: 'Other suggestions',
        wizardTableTitle: 'Title',
        wizardTableTooltipPermit: 'Permit required',
        wizardTableTooltipQualification: 'Professional qualification required',
        wizardTableTooltipAssociation: 'Affiliation required',
    },
    lucien: {
        step1Title: 'Why is the location important?',
        step1Text1: 'Depending on where you register your enterprise, permits could apply differently and professional qualifications might be required.',
        step1Text2: 'For example, a butcher in Wallonia requires their skills to be certified, while this is not required in Flanders.',
        step2Title: 'What are the implications of NACE codes?',
        step2Text1: 'Once registered, NACE codes define the economic activities that you enterprise is officially allowed to perform. ',
        step2Text2: 'Here you can play with them, and see further implications of each NACE-code, such as the need for a required permit or professional qualification.',
        step3LoadingTitle: 'There are more than 1 million enterprises registered in Belgium',
        step3LoadingText1Part1: 'In Belgium, enterprises must be officially registered in the ',
        step3LoadingText1Part2: 'the Crossroads Bank for Enterprises',
        step3LoadingText1Part3: ', the main database where you\'ll find all existing companies.',
        step3Title: 'Be accurate with your choices',
        step3Text1: 'Once you do the official registration, we advise you to only choose those NACE codes that truly represent what you will do as part of your enterprise in the next few years.',
        step3Text2: 'If your enterprise changes later, you will still be able to add or remove NACE codes free of charge.',
    },
    step1: {
        title: 'Indicate your enterprise location',
        question1Title: 'Where will your business be based ?',
        question1Flanders: 'Flanders',
        question1Brussels: 'Brussels',
        question1Wallonia: 'Wallonia',
        question2Title: 'In which commune ?',
        question2Placeholder: 'Select commune'
    },
    step2: {
        title: 'Find the NACE codes that are closely in line with your enterprise activities',
        errorMessageText: 'We are sorry, we couldn\'t find any result for ',
        errorMessageListItem1: 'Double check your search for any typos or spelling errors',
        errorMessageListItem2: 'Try using different words',
    },
    step3: {
        title: 'Be inspired by other existing enterprises',
        text: 'Below are suggested NACE codes from similar existing enterprises that could apply to yours',
        loadingTitle: 'Just a moment...',
        loadingText: 'I\'m looking through all the Belgian enterprises to suggest other NACE codes that might interest you...',
    },
    step4: {
        title: 'Great! Based on your choices, here\'s what you should do: ',
        stepperTitleStep1: 'NACE code',
        stepperTitleStep2: 'Permit',
        step1Title: 'Register these NACE codes on your enterprise',
        step1Text1Part1: 'When you have to sign your deed of incorporation at the notary\'s office (BV, NV and CV), you must ',
        step1Text1Part2: 'ask the notary to include these activities',
        step1Text1Part3: ' in the official definition of your company.',
        step1Text2Part1: 'If you become self-employed (sole proprietorship), you will have ',
        step1Text2Part2: 'to report these activities',
        step1Text2Part3: 'to your accredited business counter.',
        step1NaceOverviewTitle: 'Your list of NACE codes',
        step1NaceOverviewButtonText: 'See full list',
        step1SidePanelTitle: 'Your list of NACE codes',
        step2Title: 'Request the necessary permits',
        step2Text1: 'Some of the selected NACE codes have implications. For example, the permits you\'ll need to apply for and other requirements.',
        step2Text2: 'See further instructions on how to proceed.',
        step2UsefulInfoButton: 'Information',
        step2NoPermits: 'No permits needed for this activities.',
        saveMyResult: 'Save my result',
        buttonEmailResult: 'Email result',
        buttonDownloadResult: 'Download result',
    },
    modal: {
        startAgain: 'Start again',
        title: 'Start the wizard questionnaire again?',
        text: 'Your progress will not be saved and your answers will be lost.',
        cancel: 'Cancel',
    }
};
