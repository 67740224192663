/* eslint-disable @typescript-eslint/no-explicit-any */
import { Lang } from '../enums';
import DE from './de';
import EN from './en';
import FR from './fr';
import NL from './nl';

import DE_LANDING from './landing-v2/landing-v2-de';
import FR_LANDING from './landing-v2/landing-v2-fr';
import EN_LANDING from './landing-v2/landing-v2-en';
import NL_LANDING from './landing-v2/landing-v2-nl';

import DE_WIZARDV2 from './wizard-v2/wizard-v2-de';
import FR_WIZARDV2 from './wizard-v2/wizard-v2-fr';
import EN_WIZARDV2 from './wizard-v2/wizard-v2-en';
import NL_WIZARDV2 from './wizard-v2/wizard-v2-nl';

const getValueFromPath = (obj: any, path: string): string | any => {
  if (!obj) return '';
  const parts = path ? path.split('.') : [];
  const newObj = obj && parts[0] && obj[parts[0]];
  if (parts.length === 1) return newObj;
  return getValueFromPath(newObj, parts.slice(1).join('.'));
};

export enum TypeTranslation {
  DEFAULT,
  LANDING,
  WIZARD,
}

export default (
  lang: Lang,
  path: string,
  typeTranslation?: TypeTranslation
) => {

  if (typeTranslation && typeTranslation === TypeTranslation.LANDING) {
    switch (lang) {
      case Lang.DE:
        return getValueFromPath(DE_LANDING, path);
      case Lang.EN:
        return getValueFromPath(EN_LANDING, path);
      case Lang.FR:
        return getValueFromPath(FR_LANDING, path);
      case Lang.NL:
        return getValueFromPath(NL_LANDING, path);
      default:
        return getValueFromPath(EN_LANDING, path);
    }
  } else if (typeTranslation === TypeTranslation.WIZARD) {
    switch (lang) {
      case Lang.DE:
        return getValueFromPath(DE_WIZARDV2, path);
      case Lang.EN:
        return getValueFromPath(EN_WIZARDV2, path);
      case Lang.FR:
        return getValueFromPath(FR_WIZARDV2, path);
      case Lang.NL:
        return getValueFromPath(NL_WIZARDV2, path);
      default:
        return getValueFromPath(EN_WIZARDV2, path);
    }
  } else {
    switch (lang) {
      case Lang.DE:
        return getValueFromPath(DE, path);
      case Lang.EN:
        return getValueFromPath(EN, path);
      case Lang.FR:
        return getValueFromPath(FR, path);
      case Lang.NL:
        return getValueFromPath(NL, path);
      default:
        return getValueFromPath(EN, path);
    }
  }
};
