/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'react-router-dom';

export default {
  footer: {
    service: 'Service public fédéral Belge ',
    lastUpdated: 'Dernière mise à jour le: ',
    contactButtonTextImprove: 'Aidez-nous à améliorer',
    contactButtonTextReport: 'Signaler un obstacle',
    contactButtonTextContact: 'Contacter les services d\'assistance',
    contactButtonTitle: 'Souhaitez-vous nous contacter?',
  },
  header: {
    example:
      'Par exemple: reconnaissance des qualifications professionnelles, permis, réglementations techniques, ...',
    language: 'Langue',
    placeholder: 'Je recherche…',
    search: 'Rechercher',
    title: 'Comment puis-je vous aider ?',
  },
  landing: {
    asterix:
      '* Une demande de reconnaissance via le formulaire de demande central n\'est pas encore possible pour toutes les professions réglementées. Le formulaire n\'est actuellement accessible que via eID ou itsme®. L\'accès via eIDAS sera possible à partir d\'une phase suivante.',
    buttons: [
      'Pour en savoir plus, cliquez ici',
      'Pour plus d\'information',
      'Initier une procédure*',
      'Pour en savoir plus, cliquez ici',
      'Pour en savoir plus, cliquez ici',
      'Wallonie',
      'Flandre',
      'Bruxelles',
      'Pour en savoir plus, cliquez ici',
    ],
    colStepsEndText: 'Vous êtes prêt à entreprendre !',
    intro: {
      step: {
        descriptions: [
          'Au cœur de l\'Europe, la Belgique est l\'endroit idéal pour votre entreprise.',
          'Après avoir rempli certaines obligations spécifiques, vous pouvez commencer les formalités de démarrage.',
          'Démarrez votre entreprise en Belgique à moindre coût.',
        ],
        question: 'Comment commencer ?',
      },
      title: 'Créer votre entreprise en Belgique',
    },
    links: [
      'https://business.belgium.be/fr/creer_votre_entreprise/creation_dune_societe',
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/affinez-votre-projet',
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/la-constitution-dune-societe',
      'https://www.wallonie.be/fr/demarches/demander-un-permis-denvironnement-ou-un-permis-unique-pour-un-etablissement-de-classe-1-ou-2',
      'https://www.vlaanderen.be/omgevingsvergunning',
      'https://urbanisme.irisnet.be/lepermisdurbanisme/autres-premis-et-certificats/le-permis-unique?set_language=fr',
      'https://business.belgium.be/fr/creer_votre_entreprise/affiliation_a_une_caisse_dassurances_sociales',
      '/search?q=qualification%20professionnelle&lang=fr',
      'https://competencerecognition.belgium.be/app/',
    ],
    texts: [
      <>
        Avant de créer votre entreprise, vous devez faire quelques préparatifs.
        Cela vous permettra de vous concentrer pleinement sur le développement
        de votre entreprise après sa création. Ces préparatifs comprennent :
        <br />
        <br />
        <ul>
          <li>
            Disposer d'un compte à vue dans une banque établie en Belgique.
          </li>
          <br />
          <li>
            Choisir un emplacement / une adresse et si nécessaire des locaux
            commerciaux.
          </li>
          <br />
          <li>Décidez du nom commercial de votre entreprise.</li>
          <br />
          <li>
            Sous quel statut souhaitez-vous exercer votre profession principale
            ? En tant qu’indépendant, en tant que profession secondaire ou une
            personne assimilée à une profession secondaire ?
          </li>
          <br />
          <li>
            Choisissez la structure juridique la mieux adaptée à votre projet :
            l’entreprise individuelle (indépendant en personne physique) ou la
            société (personne morale).
          </li>
          <br />
          <li>
            Si vous avez opté pour la création d’une société, il vous faudra
            encore choisir quel type de société. Vous devrez également effectuer
            les actes préalables à l’enregistrement à savoir l’établissement
            d’un plan financier et la rédaction d’un projet des statuts de votre
            société.
          </li>
        </ul>
      </>,
      <>
        Certaines professions sont réglementées. Cela signifie que seuls ceux
        qui remplissent certaines conditions peuvent obtenir la reconnaissance
        pour exercer ces professions.
        <br />
        <br />
        La reconnaissance de vos qualifications doit être demandée au préalable.
        Les règles en la matière diffèrent en fonction du lieu d’établissement
        et d’exercice de votre profession : de manière durable, occasionnelle ou
        temporaire en Belgique. Vous pouvez demander la reconnaissance en
        Belgique au moyen d'un formulaire de demande unique.
      </>,
      <>
        Au cours de cette étape, vous remplissez les autres formalités de
        départ. Votre entreprise devra être enregistrée auprès de certaines
        autorités. En outre, il est important d'obtenir les permis ou
        autorisations légales nécessaires à l'exercice de toutes vos activités.
        <br />
        <br />
        Les formalités de départ concernent les éléments suivants :
        <br />
        <br />
        <div className="ContentLinks ContentLinks--col">
          <a
            href="https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/demarches-aupres-dun-guichet"
            target="_blank"
            className="Link Links--small"
            rel="noreferrer"
          >
            Votre inscription à la Banque-Carrefour des Entreprises
          </a>
          <a
            href="https://finances.belgium.be/fr/entreprises/tva/declaration/debut-fin-modification-activite"
            target="_blank"
            className="Link Links--small"
            rel="noreferrer"
          >
            Votre enregistrement auprès de l'administration de la TVA
          </a>
          <a
            href="https://sinstallerenbelgique.be/fr/faire-des-affaires/s-enregistrer-en-tant-qu-employeur"
            target="_blank"
            className="Link Links--small Links--externalLink"
            rel="noreferrer"
          >
            Votre éventuelle inscription en tant qu'employeur
          </a>
          <Link
            to="/home?lang=fr"
            target="_blank"
            className="Link Links--small"
          >
            Votre demande de permis pour le secteur et l'activité concernés
          </Link>
        </div>
      </>,
      <>
        Avant de créer votre entreprise, vous devez d'abord mettre certaines
        choses en ordre. Ceci tant au niveau personnel (étude, formation, ...)
        que pour votre entreprise (étude de marché, financement, business plan,
        ...) :
        <br />
        <br />
        <ul>
          <li>
            Vous devez remplir les conditions de base telles que l’âge minimum,
            la nationalité (belge ou européenne), la capacité juridiques ou les
            connaissances de la gestion de base.
          </li>
          <br />
          <li>
            Vous devez également démontrer que vous avez effectué les recherches
            nécessaires afin de démontrer la faisabilité de votre projet. Votre
            business plan doit comprendre une présentation de votre projet, une
            analyse concurrentielle, un plan commercial et un plan financier.
          </li>
        </ul>
      </>,
      <>
        Une fois que vous avez pris la décision d'exercer vos activités
        commerciales par l'intermédiaire d'une société, vous devrez créer une
        entité juridique.
        <br />
        <br />
        Après avoir effectué les actes de pré-enregistrement, vous devrez
        ensuite rédiger l'acte de constitution de la personne morale. Si vous
        optez pour une SA, une SRL ou une SC, vous serez obligé de faire appel
        aux services d'un notaire de droit civil.
        <br />
        <br />
        Afin de compléter la création de votre entité juridique, l'acte de
        constitution doit être déposé au greffe du tribunal de l’entreprise du
        siège de votre entité juridique. Ensuite, vous recevrez le numéro
        d'entreprise de votre entité juridique.
      </>,
      <>
        En fonction des activités concrètes et des caractéristiques de votre
        entreprise, vous pouvez être amené à demander certains permis :
        <br />
        <br />
        <ul>
          <li>
            Vous pourrez solliciter tous les permis et demandes dans le cadre
            des procédures environnementales, de lotissement et d'aménagement du
            territoire auprès des services régionaux compétents.
          </li>
          <br />
          <li>
            En outre, vous pouvez également avoir besoin d'une autorisation pour
            exercer certaines activités.
          </li>
        </ul>
        <br />
        Découvrez ci-dessous quel(s) permis par région vous devez avoir pour
        démarrer votre entreprise.
      </>,
      <>
        En tant que travailleur indépendant, aidant ou conjoint aidant, vous
        êtes responsable de la gestion de votre couverture sociale. Vous devez
        donc vous inscrire avant de commencer votre activité indépendante.
        <br />
        <br />
        Vous payez des cotisations de sécurité sociale à votre caisse
        d'assurance afin d'avoir droit aux prestations familiales, aux
        prestations de maladie et d'invalidité, à l'assurance maternité, à votre
        pension et à l'assurance faillite.
        <br />
        <br />
        En outre, vous devrez également souscrire à une assurance maladie et
        vous inscrire à une caisse d'allocations familiales.
      </>,
    ],
    text2:
      'Si vous souhaitez de plus amples informations sur les professions réglementées ou sur la demande de reconnaissance des qualifications professionnelles, veuillez visiter le catalogue.',
    titles: [
      'Prendre les mesures préparatoires nécessaires',
      'Faire reconnaître votre qualification professionnelle',
      'Formalités de démarrage',
      'Réglez vos obligations privées',
      'Enregistrement de la personne morale',
      'Obtenir les permis d\'environnement, d\'aménagement du territoire ou d\'urbanisme nécessaires',
      'Affiliation à une caisse d\'assurance sociale',
    ],
  },
  procedure: {
    available: 'Momentanément uniquement accessible aux Belges',
    body: 'Souhaitez-vous initier la procédure de demande de reconnaissance pour votre qualification professionnelle ? Je vous dirige avec plaisir vers celle-ci.',
    buttonStart: 'Initier une procédure',
    disclamer:
      'Pour y accéder, vous devrez vous identifier. Préparez donc votre carte d\'identité électronique ou votre smartphone.',
    notFinding: 'Vous n\'avez pas trouvé ce que vous cherchiez?',
    buttonContact: 'Contacter Be-Assist',
    beAssistURL:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/conditions-dacces-la/centre-dassistance-belge-pour',
    contact:
      'Vous pouvez toujours contacter les services d\'assistance belges à la reconnaissance des qualifications professionnelles pour plus d\'informations.',
  },
  result: {
    action: 'Action',
    average: 'Temps moyen de traitement',
    back: 'Retour aux résultats',
    competent: 'Autorité compétente',
    contact: 'Information de contact',
    cost: 'Coût',
    description: 'Description',
    eligibility: 'Critères requis',
    fiche: 'Information de fiche',
    last: 'Dernière modification',
    public: 'Organisation Publique',
    procedure: 'Procédure',
    required: 'Documents nécessaires',
    result: 'Résultat',
    website: 'Site web',
  },
  search: {
    buttonContact: 'Contacter les services d\'assistance',
    all: 'Tous les résultats',
    brussel: 'Bruxelles',
    federal: 'Fédéral',
    flemish: 'Flandre',
    noResult: {
      button: 'Contactez les services d\'assistance belges',
      desc1: 'Je n\'ai pas trouvé de résultats pour cette recherche.',
      desc2:
        'Vous pouvez toujours contacter les centres d\'assistance belges.',
      link: 'https://www.belgium.be/fr/services_dassistance_et_de_resolution_de_problemes',
      title: 'Pas de résultat de recherche',
    },
    title: 'résultats de recherche',
    walloon: 'Wallonie',
  },
  tab: {
    card: {
      address: {
        country: '1000 Bruxelles',
        name: 'Centre d\'assistance belge',
        road: 'City Atrium, Rue du Progrès, 50',
      },
      email: {
        email: 'BE_assist@economie.fgov.be',
        label: 'Email',
      },
      fax: {
        label: 'Fax',
        mention: '(numéro gratuit)',
      },
      online: 'Formulaire en ligne (FR, NL, DE, EN)',
      phone: {
        label: 'Téléphone',
        mention: '(numéro gratuit)',
      },
    },
    feedback: {
      buttons: [
        'Aidez-nous à nous améliorer',
        'Signaler un obstacle',
        'Contacter les services d\'assistance',
      ],
      link: [
        'https://foq.youreurope.europa.eu/info-service-ft?lang=fr',
        'https://fosmo.youreurope.europa.eu/',
        'https://www.belgium.be/fr/services_dassistance_et_de_resolution_de_problemes',
      ]
    },
    resultCount: ['Affichage de ', ' résultats sur ', ''],
  },
  thumbs: {
    popup:
      'Merci pour votre avis ! J\'apprends chaque jour, pour vous montrer des articles plus pertinents à l\'avenir et pour mieux vous aider la prochaine fois !',
  },
};
