import { GET_ERRORS, GET_COMMUNES } from '../../constants/types';

interface InitialRegionState {
  communeData: string[];
  isCommunedataLoading: boolean;
}

const initialState: InitialRegionState = {
  communeData: [],
  isCommunedataLoading: false,
};

type permitDataDispatchType = {
  payload: string[];
  type: string;
};

export default (
  state: InitialRegionState = initialState,
  action: permitDataDispatchType
): InitialRegionState => {
  const { payload, type } = action;

  switch (type) {
    case GET_COMMUNES:
      return {
        communeData: payload ? payload : initialState?.communeData,
        isCommunedataLoading: false,
      };
    case GET_ERRORS:
      return {
        ...state,
        isCommunedataLoading: false,
      };
    default:
      return state;
  }
};
