import { SET_QUERY } from '../../constants/types';
import { Action } from '../../constants/interfaces';

let initialState = '';

if (window?.location?.search) {
  const urlSearchParams = window?.location?.search.substr(1);
  const searchParams = new URLSearchParams(urlSearchParams);

  const q = searchParams.get('q');
  if (q && q !== '') {
    initialState = q;
  }
}

export default function (state = initialState, action: Action) {
  const { payload, type } = action;

  switch (type) {
    case SET_QUERY:
      return payload;
    default:
      return state;
  }
}
