import {
  GET_SUGGESTION_SEARCH,
  GET_SUGGESTION_LOADING,
  GET_SUGGESTION_ERRORS,
  RESET_SUGGESTION_DATA,
} from '../../constants/types';
import { wizardDataModelDTO } from '../../models/wizardDataModelDTO';

interface SuggestionhInitialStateInterface {
  suggestionData: wizardDataModelDTO[];
  suggestionLoading: boolean;
}

const initialState: SuggestionhInitialStateInterface = {
  suggestionData: [],
  suggestionLoading: false,
};

type SuggestionDataDispatchType = {
  payload: wizardDataModelDTO[];
  type: string;
};

export default (
  state: SuggestionhInitialStateInterface = initialState,
  action: SuggestionDataDispatchType
): SuggestionhInitialStateInterface => {
  const { payload, type } = action;

  switch (type) {
    case GET_SUGGESTION_LOADING:
      return {
        ...state,
        suggestionLoading: true,
      };
    case GET_SUGGESTION_SEARCH:
      return {
        suggestionData: payload ? payload : initialState?.suggestionData,
        suggestionLoading: false,
      };
    case GET_SUGGESTION_ERRORS:
      return {
        ...state,
        suggestionLoading: false,
      };
    case RESET_SUGGESTION_DATA:
      return {
        suggestionData: [],
        suggestionLoading: false,
      };
    default:
      return state;
  }
};
