import axios from 'axios';
import { Dispatch } from 'redux';
import {
  GET_DRUPAL_STEPS,
  GET_DRUPAL_HEADER,
  GET_ERRORS,
  GET_DRUPAL_ENTERPRISE_TYPES,
  GET_DRUPAL_CHANGED_DATE,
  SET_DRUPAL_BASE_URL,
  GET_DRUPAL_WIZARD_STEPS
} from '../../constants/types';
import { APIv2 } from '../../constants/strings';

const BASE_URL_BACKEND = APIv2 + 'wizard/drupal-endpoint';
const QUERY_PARAMS = '?include=field_mandatory_for,field_step_icon.field_media_image,field_tool,field_step_details,field_step_details.field_details_section_tabs&sort=field_order';

const getStepsDateUtility = (steps: any) => {
  const latestChangedDate = new Date(Math.max(...steps.map((e: { changed: string }) => new Date(e.changed))));

  const day = latestChangedDate.getDate();
  const month = latestChangedDate.getMonth();
  const year = latestChangedDate.getFullYear();

  return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
};

export const fetchAllDrupal = (lang: string, isWizard?: boolean) => async (dispatch: any) => {
  try {
    const baseUrlRes = await axios.get(BASE_URL_BACKEND);

    if (baseUrlRes && baseUrlRes.data) {
      if (isWizard) {
        dispatch(getDrupalWizardSteps(lang, baseUrlRes.data));
      } else {
        dispatch({
          type: SET_DRUPAL_BASE_URL,
          payload: baseUrlRes.data
        });
        dispatch(getDrupalHeader(lang, baseUrlRes.data));
        dispatch(getDrupalSteps(lang, baseUrlRes.data));
        dispatch(getDrupalEnterpriseTypes(lang, baseUrlRes.data));
      }
    }

  } catch (err) {
    console.error('err', err);
  }
};

export const getDrupalSteps = (lang: string, baseUrl: string) => async (dispatch: Dispatch) => {
  try {
    if (baseUrl) {
      const res = await axios.get(
        `${baseUrl}${lang}/api/node/step${QUERY_PARAMS}`
      );

      if (res && res.data) {
        dispatch({
          type: GET_DRUPAL_STEPS,
          payload: res.data,
        });
        dispatch({
          type: GET_DRUPAL_CHANGED_DATE,
          payload: getStepsDateUtility(res.data.data)
        });
      }
    }
  } catch (err) {
    console.error('err', err);
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};

export const getDrupalWizardSteps = (lang: string, baseUrl: string) => async (dispatch: Dispatch) => {
  try {
    if (baseUrl) {
      const res = await axios.get(
        `${baseUrl}${lang}/api/node/wizard_step`
      );
      if (res && res.data) {
        dispatch({
          type: GET_DRUPAL_WIZARD_STEPS,
          payload: res.data,
        });
        dispatch({
          type: GET_DRUPAL_CHANGED_DATE,
          payload: getStepsDateUtility(res.data.data)
        });
      }
    }
  } catch (err) {
    console.error('err', err);
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};

export const getDrupalHeader = (lang: string, baseUrl: string) => async (dispatch: Dispatch) => {
  try {
    if (baseUrl) {
      const res = await axios.get(
        `${baseUrl}${lang}/api/node/page_copy`
      );

      if (res && res.data) {
        dispatch({
          type: GET_DRUPAL_HEADER,
          payload: res.data,
        });
      }
    }
  } catch (err) {
    console.error('err', err);
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};

export const getDrupalEnterpriseTypes = (lang: string, baseUrl: string) => async (dispatch: Dispatch) => {
  try {
    if (baseUrl) {
      const res = await axios.get(
        `${baseUrl}${lang}/api/enterprise-type`
      );

      if (res && res.data) {
        dispatch({
          type: GET_DRUPAL_ENTERPRISE_TYPES,
          payload: res.data,
        });
      }
    }
  } catch (err) {
    console.error('err', err);
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};
