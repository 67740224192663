import { Action } from '../../constants/interfaces';
import {
  GET_DRUPAL_STEPS,
  GET_DRUPAL_HEADER,
  GET_DRUPAL_ENTERPRISE_TYPES,
  GET_DRUPAL_CHANGED_DATE,
  SET_DRUPAL_BASE_URL,
  GET_DRUPAL_WIZARD_STEPS
} from '../../constants/types';

const initialState = {
  drupalSteps: {},
  drupalHeader: {},
  drupalEnterpriseTypes: {},
  currDate: ''
};

export default function (
  state = initialState,
  action: Action
) {
  const { payload, type } = action;

  switch (type) {
    case SET_DRUPAL_BASE_URL:
      return {
        ...state,
        baseUrl: payload
      };
    case GET_DRUPAL_STEPS:
      return {
        ...state,
        drupalSteps: payload?.data
      };
    case GET_DRUPAL_WIZARD_STEPS:
      return {
        ...state,
        drupalWizardSteps: payload?.data
      };
    case GET_DRUPAL_HEADER:
      return {
        ...state,
        drupalHeader: payload?.data && payload.data[0]
      };
    case GET_DRUPAL_ENTERPRISE_TYPES:
      return {
        ...state,
        drupalEnterpriseTypes: payload?.data
      };
    case GET_DRUPAL_CHANGED_DATE:
      return {
        ...state,
        currDate: payload
      };
    default:
      return state;
  }
}
