import React from 'react';
import './accordion.scss';

import arrow_down from '../../../assets/images/arrow-down.png';

import * as AccordionPrimitive from '@radix-ui/react-accordion';

export const Accordion = ({
  children,
  title,
}: {
  children: React.ReactChild;
  title: string;
}) => {
  return (
    <AccordionPrimitive.Root type={'multiple'} className="accordion-container">
      <AccordionPrimitive.Item value={`${title}`}>
        <AccordionPrimitive.Header>
          <AccordionPrimitive.Trigger className="accordion__title-container">
            {<div dangerouslySetInnerHTML={{ __html: title }} />}
            <img
              src={arrow_down}
              alt="arrow down to manipulate the accordion"
            />
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>

        <AccordionPrimitive.Content className="AccordionContent">
          {children}
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  );
};
