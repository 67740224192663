export const ASSISTANT_LOADING = 'ASSISTANT_LOADING';
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK';
export const DOCUMENT_LOADING = 'DOCUMENT_LOADING';
export const FEEDBACK_LOADING = 'FEEDBACK_LOADING';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_ERRORS = 'GET_ERRORS';
export const GET_RESULTS = 'GET_RESULTS';
export const POST_ASSISTANT = 'POST_ASSITANT';
export const POST_FEEDBACK = 'POST_FEEDBACK';
export const PUT_FEEDBACK = 'PUT_FEEDBACK';
export const RESULTS_LOADING = 'RESULTS_LOADING';
export const SET_LANG = 'SET_LANG';
export const SET_QUERY = 'SET_QUERY';
export const GET_COMMUNES = 'GET_COMMUNES';
export const GET_WIZARD_SEARCH = 'GET_WIZARD_SEARCH';
export const WIZARD_SEARCH_LOADING = 'WIZARD_SEARCH_LOADING';
export const WIZARD_RESET_DATA = 'WIZARD_RESET_DATA';
export const GET_SUGGESTION_SEARCH = 'GET_SUGGESTION_SEARCH';
export const RESET_SUGGESTION_DATA = 'RESET_SUGGESTION_DATA';
export const GET_SUGGESTION_LOADING = 'GET_SUGGESTION_LOADING';
export const GET_SUGGESTION_ERRORS = 'GET_SUGGESTION_ERRORS';
export const RESET_PERMIT_DATA = 'RESET_PERMIT_DATA';
export const GET_PERMIT_LOADING = 'GET_PERMIT_LOADING';
export const GET_PERMIT_ERRORS = 'GET_PERMIT_ERRORS';
export const GET_PERMIT_DATA = 'GET_PERMIT_DATA';
export const GET_DRUPAL_STEPS = 'GET_DRUPAL_STEPS';
export const GET_DRUPAL_HEADER = 'GET_DRUPAL_HEADER';
export const GET_DRUPAL_ENTERPRISE_TYPES = 'GET_DRUPAL_ENTERPRISE_TYPES';
export const GET_DRUPAL_CHANGED_DATE = 'GET_DRUPAL_CHANGED_DATE';
export const SET_DRUPAL_BASE_URL = 'SET_DRUPAL_BASE_URL';
export const GET_DRUPAL_WIZARD_STEPS = 'GET_DRUPAL_WIZARD_STEPS';
export const GET_SIDE_PANEL_STATE = 'GET_SIDE_PANEL_STATE';
export const CLOSE_SIDE_PANEL = 'CLOSE_SIDE_PANEL';
export const OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL';
export const SET_SIDE_PANEL_DATA = 'SET_SIDE_PANEL_DATA';
