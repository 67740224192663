export default {
    main: {
        buttonStartAgain: 'Opnieuw starten',
        buttonNext: 'Volgende',
        buttontSkip: 'Overslaan',
        buttonPrevious: 'Vorige',
        stepperStep1Text: 'Locatie van de onderneming',
        stepperStep2Text: 'NACE-codes',
        stepperStep3Text: 'Andere suggesties',
        wizardTableTitle: 'Titel',
        wizardTableTooltipPermit: 'Vereiste vergunning',
        wizardTableTooltipQualification: 'Vereiste professionele kwalificatie',
        wizardTableTooltipAssociation: 'Vereiste affiliatie',
    },
    lucien: {
        step1Title: 'Waarom is de locatie belangrijk?',
        step1Text1: 'Afhankelijk van waar je je onderneming registreert, kunnen vergunningen anders van toepassing zijn en professionele kwalificaties vereist zijn.',
        step1Text2: 'Bijvoorbeeld, een slager in Wallonië moet zijn vaardigheden laten certificeren, terwijl dit niet vereist is in Vlaanderen.',
        step2Title: 'Wat zijn de implicaties van NACE-codes?',
        step2Text1: 'Eenmaal geregistreerd, bepalen NACE-codes de economische activiteiten die je onderneming officieel mag uitvoeren.',
        step2Text2: 'Hier kun je ermee experimenteren en verdere implicaties van elke NACE-code zien, zoals de noodzaak van een vereiste vergunning of professionele kwalificatie.',
        step3LoadingTitle: 'Er zijn meer dan 1 miljoen geregistreerde ondernemingen in België',
        step3LoadingText1Part1: 'In België moeten ondernemingen officieel geregistreerd zijn bij de ',
        step3LoadingText1Part2: 'Kruispuntbank van Ondernemingen',
        step3LoadingText1Part3: ', de belangrijkste database waar je alle bestaande bedrijven vindt.',
        step3Title: 'Wees nauwkeurig in je keuzes',
        step3Text1: 'Zodra je de officiële registratie hebt gedaan, adviseren we je alleen die NACE-codes te kiezen die werkelijk vertegenwoordigen wat je de komende jaren in je onderneming zult doen.',
        step3Text2: 'Als je onderneming later verandert, kun je nog steeds gratis NACE-codes toevoegen of verwijderen.',
    },
    step1: {
        title: 'Geef de locatie van je onderneming aan',
        question1Title: 'Waar zal je bedrijf gevestigd zijn?',
        question1Flanders: 'Vlaanderen',
        question1Brussels: 'Brussel',
        question1Wallonia: 'Wallonië',
        question2Title: 'In welke gemeente?',
        question2Placeholder: 'Selecteer gemeente'
    },
    step2: {
        title: 'Zoek de NACE-codes die nauw aansluiten bij de activiteiten van je onderneming',
        errorMessageText: 'Sorry, we konden geen resultaten vinden voor ',
        errorMessageListItem1: 'Controleer je zoekopdracht op eventuele typfouten of spellingfouten',
        errorMessageListItem2: 'Probeer andere woorden te gebruiken',
    },
    step3: {
        title: 'Laat je inspireren door andere bestaande ondernemingen',
        text: 'Hieronder worden NACE-codes voorgesteld van vergelijkbare bestaande ondernemingen die van toepassing kunnen zijn op jouw onderneming',
        loadingTitle: 'Even geduld...',
        loadingText: 'Ik doorzoek alle Belgische ondernemingen om andere NACE-codes voor te stellen die je mogelijk interesseren...',
    },
    step4: {
        title: 'Geweldig! Op basis van je keuzes, dit is wat je moet doen:',
        stepperTitleStep1: 'NACE-code',
        stepperTitleStep2: 'Vergunning',
        step1Title: 'Registreer deze NACE-codes voor je onderneming',
        step1Text1Part1: 'Wanneer je de akte van oprichting bij de notaris ondertekent (BV, NV en CV), moet je ',
        step1Text1Part2: 'de notaris vragen om deze activiteiten op te nemen',
        step1Text1Part3: ' in de officiële definitie van je bedrijf.',
        step1Text2Part1: 'Als je zelfstandige wordt (eenmanszaak), moet je ',
        step1Text2Part2: 'deze activiteiten melden',
        step1Text2Part3: 'bij je erkend ondernemingsloket.',
        step1NaceOverviewTitle: 'Uw NACE-codes om te registreren',
        step1NaceOverviewButtonText: 'Volledige lijst bekijken',
        step1SidePanelTitle: 'Uw NACE-codes om te registreren',
        step2Title: 'Vraag de benodigde vergunningen aan',
        step2Text1: 'Sommige van de geselecteerde NACE-codes hebben implicaties, zoals de vergunningen die je moet aanvragen en andere vereisten.',
        step2Text2: 'Bekijk verdere instructies over hoe verder te gaan.',
        step2UsefulInfoButton: 'Informatie',
        saveMyResult: 'Mijn resultaat opslaan',
        buttonEmailResult: 'Resultaat e-mailen',
        buttonDownloadResult: 'Resultaat downloaden',
        step2NoPermits: 'Geen vergunningen nodig voor deze activiteiten.'
    },
    modal: {
        startAgain: 'Opnieuw beginnen',
        title: 'De wizard vragenlijst opnieuw starten?',
        text: 'Uw voortgang wordt niet opgeslagen en uw antwoorden gaan verloren.',
        cancel: 'Annuleren',
    }
};
