/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ASSISTANT_LOADING,
  GET_ERRORS,
  GET_RESULTS,
  POST_ASSISTANT,
} from '../../constants/types';
import { Action } from '../../constants/interfaces';

const initialState = {
  loading: false,
  output: {},
  question: null,
  options: [],
  sessionId: undefined,
  suggestions: [],
};

export default function (state = initialState, action: Action) {
  const { payload, type } = action;

  switch (type) {
    case ASSISTANT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_RESULTS:
      return {
        ...state,
        suggestions: getSuggestions(payload?.assistant?.output),
        options: getOptions(payload?.assistant?.output),
        output: payload?.assistant?.output,
        question: getQuestion(payload?.assistant?.output),
        sessionId: payload?.assistant?.context?.global?.session_id,
      };
    case POST_ASSISTANT:
      return {
        ...state,
        loading: false,
        suggestions: getSuggestions(payload?.assistant?.output),
        options: getOptions(payload?.assistant?.output),
        output: payload?.output,
        question: getQuestion(payload?.assistant?.output),
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

const getQuestion = (output: any) => {
  return output?.generic?.length
    ? output?.generic?.[0]?.text
      ? output?.generic?.[0]?.text?.startsWith('Search results use case')
        ? null
        : output?.generic?.[0]?.text
      : output?.generic?.[0]?.title
    : null;
};

const getOptions = (output: any) => {
  return output?.generic?.length
    ? output?.generic?.[0]?.options?.length
      ? output?.generic?.[0]?.options
      : null
    : null;
};

const getSuggestions = (output: any) => {
  return output?.generic?.length
    ? output?.generic?.[0]?.suggestions?.length
      ? output?.generic?.[0]?.suggestions
      : null
    : null;
};
