import { m } from 'framer-motion';

/* eslint-disable */
export default {
  header: {
    title: 'Official information and services',
  },
  page: {
    title: 'Your journey to start a company in Belgium',
    subtitle: 'Go through the steps and get your company set up!',
    buttonContainerTitle: 'What type of enterprise will you start?',
    buttonNvBcCv: 'NV, BV, CV',
    buttonVofCommV: 'VOF, Comm. V',
    buttonSoleProprietor: 'Sole proprietor',
    buttonNonProfit: 'Non-profits',
    buttonContainerTextBelowPart1: "You don't know yet?",
    buttonContainerTextBelowPart2: 'Explore possible legal structures',
    listItemTitle1:
      'Check if you fulfil the conditions to start a company in Belgium',
    listItemTitle2:
      ' Once you have defined the type of company you wish to launch, we will let you know the steps that are mandatory',
    itemTitle1: 'Check the conditions to start an enterprise in Belgium',
    itemTitle2: 'Evaluate your starting point',
    itemTitle3: 'Choose a legal structure for your enterprise',
    itemTitle4: 'Open a professional bank account',
    itemTitle5: 'Choose the address of your enterprise',
    itemTitle6: 'Choose the name of your enterprise',
    itemTitle7: 'Create a business plan',
    itemTitle8: 'Define the economic activities of your enterprise',
    itemTitle9: 'Get your professional qualification recognized',
    itemTitle10:
      'Register your enterprise at the Crossroads Bank for Enterprises',
    itemTitle11: 'Register your enterprise in the Tax Administration Office',
    itemTitle12: 'Apply for permits',
    itemTitle13: 'Affiliate to a social insurance fund',
    itemTitle14: 'Register as an employer',
    buttonReadMore: 'Read more',
    findMoreServiceText: 'Find service providers',
    registerNameSectionStep1: 'Decide a name for your company',
    registerNameSectionStep2Part1: 'Check if the',
    registerNameSectionStep2Part2: 'name is still available',
    registerNameSectionStep3: 'Register the company name',
    toolCardTitle:
      'Tool for choosing the NACE-BEL activities to define your company',
    toolCardText:
      'Use this online tool to discover and select the right activities for your business and discover if you require permits.',
    toolCardButtonText: 'Launch tool',
    moreInfoBeAssistTitle: 'More information',
    moreInfoBeAssistText:
      'Reach out the contact center of the Belgian government for more information on getting your professional qualification recognized',
    moreInfoBeAssistContact: 'Contact',
    mandatoryText: 'Mandatory',
    optionalText: 'Optional',
    step1Text1:
      'In order to exercise a self-employed activity, you must meet certain prerequisites regarding:',
    step1ListItem1: 'Individual legal requirements',
    step1ListItem2:
      'Nationality and the exercise of a specific profession in Belgium',
    step1ListItem3: 'The activities you wish to perform in your enterprise',
    step1ExternalLinkText: 'Conditions to exercise a self-employed activity',
    step1ExternalLinkUrl:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/conditions-entering',
    step1SidePanelTitle:
      'Check the conditions to start an enterprise in Belgium ',
    step1SidePanelText:
      'Before setting anything up, there are certain legal requirements to exercise a self-employed activity: ',
    step1SidePanelDropdown1Title1: 'Conditions regarding the individual ',
    step1SidePanelDropwdown1ListItem1:
      'you must have the legal majority of 18 years of age ',
    step1SidePanelDropwdown1ListItem2:
      'you must not be declared legally incompetent or placed under judicial supervision ',
    step1SidePanelDropwdown1ListItem3:
      'you must enjoy civil and political rights',
    step1SidePanelDropwdown1ListItem4:
      'you must not have been banned from performing acts relating to a self-employed professional activity. ',
    step1SidePanelDropdown2Title:
      'Conditions regarding nationality and the exercise of a specific profession in Belgium ',
    step1SidePanelDropdown2ListItem1:
      'If you are a professional from an EU Member State, the European Economic Area or Switzerland, you do not need a professional card to exercise your activity in Belgium. However, given that certain professions are regulated, you will, first, need to check whether your professional qualifications are recognized in those professions. ',
    step1SidePanelDropdown2ListItem2:
      'If you do not have Belgian nationality, the nationality of one of the Member States of the European Economic Area or Swiss nationality, you must be in possession of a professional card to be able to exercise a self-employed activity in Belgium (unless you are exempt) ',
    step1SidePanelDropdown3Title: 'Conditions relating to the activity ',
    step1SidePanelDropdown3ListItem1Part1:
      'Proven entrepreneurial capacities (Only needed in Wallonia ',
    step1SidePanelDropdown3ListItem1Part2IsUrl: 'Wallonia ',
    step1SidePanelDropdown3ListItem1Part2Url:
      'https://www.wallonie.be/en/demarches/sitting-exam-central-jury-obtain-management-knowledge-and-access-regulated-profession',
    step1SidePanelDropdown3ListItem1Part3: '& ',
    step1SidePanelDropdown3ListItem1Part4IsUrl: 'Brussels ',
    step1SidePanelDropdown3ListItem1Part4Url:
      'https://1819.brussels/en/information-library/start-business-formalities/ways-prove-your-basic-knowledge-business-management',
    step1SidePanelDropdown3ListItem1Part5: 'region)',
    step1SidePanelDropdown3ListItem2Part1: 'Conditions regarding liberal and ',
    step1SidePanelDropdown3ListItem2Part2IsUrl: 'intellectual professions ',
    step1SidePanelDropdown3ListItem2Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/conditions-entering',
    step1SidePanelDropdown3ListItem3Part1: 'Conditions regarding ',
    step1SidePanelDropdown3ListItem3Part2IsUrl:
      'specific licenses and authorisations',
    step1SidePanelDropdown3ListItem3Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/conditions-entering',
    step2Text:
      'To kick off the journey of creating an enterprise, it is important to evaluate what is your starting point. Individual situations might have different challenges and opportunities. \n For example, being able to start an enterprise as a student, or keeping your unemployment benefits while developing your business idea and setting up your enterprise.',
    step2ExternalLinkText: 'The social status of the self-employed',
    step2ExternalLinkUrl:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/social-insurance-fund/social-status-self-employed',
    step2SidePanelTitle: 'Evaluate your starting point ',
    step2SidePanelText:
      'You may be starting your enterprise while still studying, while holding down a job or while being unemployed. Find out the specific conditions that apply to your situation: ',
    step2SidePanelDropdown1Title:
      'Starting while holding down a job (secondary occupation)',
    step2SidePanelDropdown1ItemPart1: 'More information on how to ',

    step2SidePanelDropdown1Item1Part2IsUrl:
      'start an enterprise as a secondary occupation ',
    step2SidePanelDropdown1Item1Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/social-insurance-fund/social-status-self-employed',
    step2SidePanelDropdown1ItemPart3: 'while holding down a job',
    step2SidePanelDropwdown2Title: 'Starting while on unemployment benefit',
    step2SidePanelDropdown2ItemPart1: 'More information on how to ',
    step2SidePanelDropdown2ItemPart2IsUrl:
      'start an enterprise while on unemployment benefit',
    step2SidePanelDropdown2Item1Part2Url:
      'https://www.rva.be/burgers/volledige-werkloosheid/mag-u-werken-tijdens-uw-werkloosheid/mag-u-tijdens-uw-werkloosheid-een-nevenactiviteit-als-zelfstandige-in-het-kader-van-het-voordeel-springplank-naar-zelfstandige-uitoefenen',
    step2SidePanelDropdown3Title:
      'Starting from an incapacity/disability benefit',
    step2SidePanelDropwdown3ListItem1Part1: 'Disability benefit in ',
    step2SidePanelDropwdown3ListItem1Part2IsUrl: 'Flanders',
    step2SidePanelDropwdown3ListItem1Part2Url:
      'https://www.vlaio.be/nl/subsidies-financiering/subsidiedatabank/vlaamse-ondersteuningspremie-vop-voor-zelfstandigen',
    step2SidePanelDropwdown3ListItem2Part1: 'Disability benefit in ',
    step2SidePanelDropwdown3ListItem2Part2IsUrl: 'Brussels',
    step2SidePanelDropwdown3ListItem2Part2Url:
      'https://1819.brussels/en/information-library/start-business-formalities/entrepreneurship-and-disability-what-support',
    step2SidePanelDropwdown3ListItem3Part1: 'Disability benefit in ',
    step2SidePanelDropwdown3ListItem3Part2IsUrl: 'Wallonia',
    step2SidePanelDropwdown3ListItem3Part2Url:
      'https://www.1890.be/solution/aides-integration-handicap/',
    step2SidePanelDropwdown4Title: 'Assisting spouse',
    step2SidePanelDropdown4ItemPart1: 'More information on ',
    step2SidePanelDropdown4ItemPart2IsUrl: ' how to become the assisting spouse ',
    step2SidePanelDropdown4Item1Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/social-insurance-fund/social-status-self-employed',
    step2SidePanelDropdown4ItemPart3: ' of a self-employed person',
    step2SidePanelDropdown5Title1Part1: 'Starting as a student entrepreneur',
    step2SidePanelDropwdown5ListItem1Part1: 'Student entrepreneur in ',
    step2SidePanelDropwdown5ListItem1Part2IsUrl: 'Flanders',
    step2SidePanelDropwdown5ListItem1Part2Url:
      'https://www.vlaio.be/nl/begeleiding-advies/start/je-statuut-als-zelfstandige/statuut-van-student-zelfstandige',
    step2SidePanelDropwdown5ListItem2Part1: 'Student entrepreneur in ',
    step2SidePanelDropwdown5ListItem2Part2IsUrl: 'Brussels',
    step2SidePanelDropwdown5ListItem2Part2Url:
      'https://1819.brussels/en/information-library/start-business-formalities/start-business-student-entrepreneur',
    step2SidePanelDropwdown5ListItem3Part1: 'Student entrepreneur in ',
    step2SidePanelDropwdown5ListItem3Part2IsUrl: 'Wallonia',
    step2SidePanelDropwdown5ListItem3Part2Url:
      'https://www.1890.be/solution/lancer-une-activite-independante-pendant-ses-etudes-est-tout-a-fait-possible/',
    step3Text1:
      'You have decided to start your own company and the next most important thing you need to do is choose the most suitable legal structure for your business. Each legal structure available in Belgium has different cost and liability implications, among other specificities. ',
    step3Text2:
      'In effect, you have 3 options for exercising your self-employed activity:',
    step3ListItem1: 'Sole proprietorship (self-employed individual)',
    step3ListItem2: 'Company (with or without legal personality)',
    step3ListItem3: 'Non profit organization',
    step3ExternalLinkText: 'What legal business structure should I choose?',
    step3ExternalLinkUrl:
      'https://www.belgium.be/en/economy/business/creation/company_formats',
    step3SidePanelTitle: 'Choose a legal structure for your enterprise',
    step3TSidePanelext1:
      'Sole proprietorship or  company? Each formula has its advantages and disadvantages, and your choice will have a significant impact on the life of your business. Among the criteria at stake are the following:',
    step3SidePanelListItem1: 'the type of activity envisaged',
    step3SidePanelListItem2: 'the number of people who will participate',
    step3SidePanelListItem3: 'the available capital',
    step3SidePanelListItem4: 'the financial contribution of the partners',
    step3SidePanelListItem5: 'the financial contribution of the partners',
    step3SidePanelListItem6: 'the most appropriate tax regime',
    step3SidePanelListItem7:
      'the foreseeable development of the activity, etc.',
    step3SidePanelText2:
      "Choosing either of these two options requires in-depth reflection, and it must be adapted to your business project. Don't hesitate to ask for advice from a notary, lawyer, business consultant, accountant or tax expert.",
    step3SidePanelText3Part1:
      'More information about legal structures can be found in ',
    step3SidePanelText3Part2IsUrl: 'FOD Justice',
    step3SidePanelText3Part2Url:
      'https://justitie.belgium.be/nl/themas_en_dossiers/vennootschappen_verenigingen_en_stichtingen',
    step3SidePanelDropdown1Title: 'Sole proprietorship',
    step3SidePanelDropdown1Text:
      'A sole proprietorship is the simplest way to perform a self-employed activity:',
    step3SidePanelDropdown1ListItem1:
      'it does not require the drafting of articles of association, nor a minimum starting capital',
    step3SidePanelDropdown1ListItem2:
      'the costs of incorporation and operation are low',
    step3SidePanelDropdown1ListItem3: 'you can start your business quickly',
    step3SidePanelDropdown1ListItem4:
      'you are the only one in charge and you can make the decisions necessary for the exercise of your activity without having to consult other partners',
    step3SidePanelDropdown1ListItem5:
      'the accounting to be kept is, as a general rule, simplified.',
    step3SidePanelDropwdon2Title: 'Company',
    step3SidePanelDropdown2Text1:
      'The incorporation of a company requires the following, depending on the case:',
    step3SidePanelDropdown2ListItem1: 'the intervention of a notary',
    step3Dropdown2ListItem2: 'minimum capital',
    step3Dropdown2ListItem3: 'a financial plan',
    step3Dropdown2ListItem4: 'the drafting of articles of association',
    step3Dropdown2ListItem5:
      "an auditor's report (e.g. in the case of a contribution in kind).",
    step3Dropdown2ListItem6:
      'In addition, there are also costs associated with the termination and liquidation of a company.',
    step3Dropdown2ListItem7:
      'A company may have a separate legal personality which confers on it its own rights as well as obligations.',
    step3Dropdown2Text2: 'Most common company forms: ',
    step3Dropdown2ListItem8:
      'Public Limited Company (PLC): this is the form for companies of a certain size, where raising capital is the main focus. Only one founder is needed to set up an NV.',
    step3Dropdown2ListItem9:
      "Private Limited Liability Company (LTD): a limited company is the 'basic company form' par excellence. Because there are very few mandatory regulations, a BV gives economic operators a lot of room to manoeuvre in forming a company that is 'made to measure'. For example, there is freedom in the articles of association to determine how limited the BV actually is. Only one founder is needed to set up a BV.",
    step3Dropdown2ListItem10:
      'Cooperative Society (SCOP): a cooperative society is a company whose shareholders pursue a cooperative ideal, which explains why it always requires three founders. The main purpose of the cooperative society is to meet the needs of its shareholders and it may also serve as a tool to meet the economic or social needs of those shareholders.',
    step3Dropdown2ListItem11:
      'General partnership: a general partnership is a partnership entered into between jointly and severally liable partners who have unlimited liability. All decisions must be taken unanimously, unless the partnership agreement provides for decisions to be taken by a majority.',
    step3Dropdown2ListItem12:
      'Limited Partnership (LP): a limited partnership is a partnership entered into between one or more jointly and severally liable partners who have unlimited liability, referred to as managing or limited partners, and one or more partners limited to contributions in cash or in kind, referred to as silent or limited partners. The managing partners are involved in the management. The silent partners are not involved in the management. All decisions must be taken unanimously, unless the partnership agreement provides for decisions to be taken by a majority.',
    step3Dropwdon3Title: 'Non-profit organization',
    step3Dropdown2ListItem13:
      ' Association sans but lucratif (ASBL): a non-profit association is a group of natural persons or legal entities pursuing a charitable goal. The non-profit association consists of at least two persons.',
    step3Dropdown2ListItem14:
      'Association internationale sans but lucratif (AISBL): an international non-profit association is a group of natural persons or legal persons that pursues a charitable goal of international benefit.',
    step4Text1:
      'If you wish to start your own self-employed activity, whether it be a sole proprietorship or a company, you need to open a professional current account with a bank or other financial institution. ',
    step4Text2:
      'Having a professional bank account is important because you must state the number of this account on all your business documents (letters, invoices, etc.) next to your business number, the name of your company and the name of your financial institution.',
    step4ExternalLinkText:
      'Basic banking service for businesses and diplomatic missions',
    step4ExternalLinkUrl:
      'https://economie.fgov.be/en/themes/financial-services/basic-banking-service/basic-banking-service',
    step4SidePanelTitle: 'Open a professional bank account',
    step4SidePanelText1:
      'For a company, the account must be in the name of the company. For a sole proprietorship, it is also advised to open a new professional account because in this way, commercial and private transactions remain separate. ',
    step4SidePanelText2:
      'As you often incur expenses before the official launch of your business, make sure you create your professional accounts as soon as possible. ',
    step4SidePanelText3:
      'Remember that It is mandatory to have a local (Belgian) professional bank account for companies registered in Belgium. Depending on the bank, it usually takes 2 days to set up the account and 7 days to receive the card. ',
    step4SidePanelMoreInformation: 'More information',
    step4SidePanelMoreInformationUrl:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/opening-current-account',
    step5Text1:
      'You must choose an official address in which to register your enterprise. This address will become what is called the "seat" of the enterprise after registration. Depending on the legal structure that you choose (sole proprietorship or company), different liabilities will apply. ',
    step5ExternalLinkText: 'Types of establishment units',
    step5ExternalLinkUrl:
      'https://economie.fgov.be/en/themes/enterprises/crossroads-bank-enterprises/registration-crossroads-bank',
    step5SidePanelTitle: 'Choose the address of your enterprise',
    step5SidePanelText1:
      'The  different legal structures have implications in regards to responsibilities and asset liability. This is also  linked to the address of your enterprise and other physical assets. ',
    step5SidePanelMoreInformation: 'More information',
    step5SidePanelMoreInformationUrl:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/choosing-legal-status-company',
    step5SidePanelDropwdon1Title: 'Sole proprietorship',
    step5SidePanelDropdown1Text1:
      'In a sole proprietorship, there is no separation between the assets allocated to the professional activity and the private assets of the entrepreneur. Therefore, the official enterprise address or "seat" is the official domicile of the sole proprietor.',
    step5SidePanelDropdown1Text2:
      "The entrepreneur is therefore, with all their assets, responsible for the commitments of their enterprise. This is not without risk, for example in the event of bankruptcy of a major client. Their debts can be recovered from all of the entrepreneur's assets, whether movable or immovable, present or future.",
    step5SidePanelDropdown1Text3:
      "The assets of the spouse may also be used to pay the company's debts, unless a matrimonial agreement provides otherwise.",
    step5SidePanelDropdown1SubDropdownText1:
      'Protection of the main residence of the self-employed person',
    step5SidePanelDropdown1SubDropdownText2:
      "The risk hanging over the home of the self-employed person has been somewhat reduced by a measure that allows them to protect their home by having it declared exempt from seizure in certain circumstances. The protection only applies to the self-employed person's main residence, i.e. the place where they live for most of the year.",
    step5SidePanelDropdown1SubDropdownText3:
      'The protection is not automatic, the self-employed person must make a declaration of unseizability before the notary of their choosing.',
    step5SidePanelDropdown1SubDropdownText4Part1:
      'The protection only relates to debts pertaining to the professional activity after the',
    step5SidePanelDropdown1SubDropdownText4Part2IsUrl:
      'declaration of unseizability',
    step5SidePanelDropdown1SubDropdownText4PartsUrl:
      'https://www.notaris.be/ondernemen/de-gevolgen-van-ondernemen-op-mijn-gezin/je-gezinswoning-beschermen-met-een-verklaring-van-onbeslagbaarheid',
    step5SidePanelDropdown1SubDropdownText4Part3:
      '. As such, it will not apply to private debts.',
    step5SidePanelDropdown1SubDropdownText5:
      'The self-employed person can, of course, waive the protection by making a new declaration. This also ends if the self-employed person changes status or dies.',
    step5SidePanelDropdown1Text4Part1:
      'For all information on this legal provision, contact a notary. You can consult the directory of notaries on the website of the ',
    step5SidePanelDropdown1Text4Part2IsUrl:
      'Royal Federation of Belgian Notaries.',
    step5SidePanelDropdown1Text4Part2Url: 'https://notaris.be/',
    step5SidePanelDropdown1Text5:
      'In the case of a sole proprietorship, if a change of address (the seat) occurs, no action is required from the enterprise. The municipality will change the address in the national register and the CBE will be adapted based on this new information.',
    step5SidePanelDropwdon2Title: 'Company',
    step5SidePanelDropdown2Text1:
      'In a company, there is a separation between the assets of the enterprise and the assets of the entrepreneur. Part of the assets can therefore be exempted from the entrepreneurial risk. Therefore, the official company address is agreed by the owner(s) of the enterprise.',
    step5SidePanelDropdown2ListItem1: 'Limited liability',
    step5SidePanelDropdown2ListItem1Text1:
      'In limited liability companies (PLC, LTD, SCOP), the shareholder is only liable for the debts of the company up to the amount of their contribution. This implies that the creditors of the company cannot make a claim on the personal assets of the entrepreneur. The personal assets of the entrepreneur and the shareholders are therefore protected.',
    step5SidePanelDropdown2ListItem1Text2:
      'In certain cases, however, liability may be invoked. This is the case in particular if the company is declared bankrupt within three years of incorporation and if the initial capital or the capital contribution was clearly insufficient to ensure the normal functioning of the company for two years.',
    step5SidePanelDropdown2ListItem2: 'Unlimited Liability',
    step5SidePanelDropdown2ListItem2Text1:
      'In unlimited liability companies (General partnership, Limited partnership), the partners (with the exception of the limited partners) put their own assets as collateral for the possible debts of the company. If the company is unable to honour its debts, its creditors may pursue the payment of their claim on the private assets of the partners.',
    step5SidePanelDropdown2ListItem2Text2:
      'In the case of a company, if a change of address (the seat) occurs, action is required from the enterprise. The enterprise must publish the change of address in the Belgian Official Gazette and then the Enterprise Court will take care of updating this information in the CBE.',
    step6Text1:
      'Choosing a name for your enterprise may seem simple, yet is a crucial step: you need to find the ideal name that attracts customers and sets you apart from your competitors. ',
    step6Text2:
      'Moreover, you need to comply with various specific provisions depending on whether you start your business as a sole proprietorship or a company.',
    step6Text3:
      'In some cases, sole proprietors might choose to tu run their business under their own name.',
    step6SidepanelTitle: 'Choose the name of your enterprise',
    step6SidePanelText1:
      'The name of your enterprise has further implications like the trademark of the goods and services you sell, your website domain, email address, packaging of your products, etc.',
    step6SidePanelText2Part1:
      'Before choosing a trade or company name for your enterprise, you may want to check whether there is a comparable or similar earlier trademark for similar activities and territories. You can carry out a search via the website of the ',
    step6SidePanelText2Part2IsUrl:
      'Benelux Office for Intellectual Property (BOIP)',
    step6SidePanelText2Url: 'https://www.boip.int/en',
    step6SidePanelText2Part3:
      ', which manages the Benelux trademark register. This register provides access to trademarks which are protected on the Benelux territory.',
    step6SidePanelTextPart4:
      'There are different options for choosing and protecting your trade and company name, depending on the type of legal structure. ',
    step6SidePanelDropdown1Title:
      'Choose a trade and/or company name (Sole prioprietors may only need a trade name)',
    step6SidePanelDropdown1ListItem1:
      'Keep in mind possible negative or controversial connotations of the name of your enterprise before making any final decision. it is advised to check its meaning in other languages, specially if you are going to sell your goods and services outside of Belgium.',
    step6SidePanelDropdown1ListItem2Part1:
      ' Search in the Crossroads Bank for Enterprises for existing enterprises to ',
    step6SidePanelDropdown1ListItem2Part2IsUrl:
      ' check wether a name already exists',
    step6SidePanelDropdown1ListItem2Part2Url:
      'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?lang=en',
    step6SidePanelDropdown2Title: 'Protect your trade name',
    step6SidePanelDropdown1Text1:
      'The right to a trade name requires no prior filing or registration formalities. The right arises from the first public use made of the trade name.',
    step6SidePanelDropdown1Text2:
      'Publishing the trade name in the Crossroads Bank for Enterprises might constitute this first use, but in the event of a dispute, proof will have to be provided, meaning that the rules of the Civil Code will apply.',
    step6SidePanelDropdown3Title: 'Protect your company name',
    step6SidePanelDropdown2Text1:
      'The right to the company name of a legal entity is acquired upon registration at the Enterprise Court, since the company acquires legal personality on that date.',
    step6SidePanelDropdown4Title: 'Trademark',
    step6DSidePanelropdown4Text2Part1:
      'While the trade name and company name are distinctive signs of the company itself, the trademark is the distinctive sign of the goods or services of the company. ',
    step6SidePanelDropdown4Text2Part2:
      'In order to be protected, the trademark must be diligently registered according to a procedure. The ',
    step6SidePanelDropdown4Text2Part3IsUrl:
      'Benelux Office for Intellectual Property (BOIP) ',
    step6SidePanelDropdown4Text2Part3Url: 'https://www.boip.int/en',
    step6SidePanelDropdown4Text2Part4:
      'can provide you with all the information you need on this subject.',
    step7Text1:
      'The quality of the business and financial plans is crucial to convince your future partners of the seriousness of the project, its profitability and viability. They are also necessary when you are going to apply to a lending institution to apply for credit .',
    step7Text2:
      'For the establishment of a PLC (Public Limited Company), a LTD (Private Limited Company), or a SCOP (Cooperative Society), the submission of a business and financial plan is required by law while it is also recommended for sole proprietors.',
    step7ExtraExternalLinkText:
      'Protect your business idea with Intellectual Property',
    step7ExtraExternalLinkTextUrl: 'https://www.boip.int/en',
    step7SidepanelTitle: 'Create a business plan',
    step7SidepanelText1:
      'The business plan is a document in which you detail your short- and long-term goals and term and your strategy for achieving those goals. The financial plan is the numerical part of the business plan. ',
    step7SidepanelText2:
      'You can call on accountants to help you with your plans as they can detect any weaknesses and give you advice. The different regions also provide free templates and useful resources to facilitate the creation of your business plan.',
    step7SidepanelText3Part1: 'Resources to create a business plan (',
    step7SidepanelText3Part2IsUrl: 'Brussels, ',
    step7SidepanelText3Part2Url:
      'https://1819.brussels/en/information-library/before-starting-business/writing-business-plan',
    step7SidepanelText3Part3IsUrl: 'Wallonia, ',
    step7SidepanelText3Part3Url:
      'https://www.1890.be/outil/business-plan/?search&terms=business%20plan',
    step7SidepanelText3Part4IsUrl: 'Flanders)',
    step7SidepanelText3Part4Url:
      'https://www.vlaio.be/nl/begeleiding-advies/groei-innovatie/je-bedrijf-onder-de-loep/ondernemingsplan',
    step7SidepanelMoreInformation: 'More information',
    step7SidepanelMoreInformationUrl:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/choosing-legal-status-company',
    step7SidePanelDropdown1Title: 'Business plan',
    step7SidePanelDropdown1Text1:
      'The business plan allows you to demonstrate the quality of your business opportunity, identify main risks, define the future strategy and vision, project growth and establish targets. ',
    step7SidepnaleDropdown1Text2:
      'As a strategic tool for entrepreneurs, the business plan include a project description, a commercial offer, a market analysis, a commercial plan, the human resources, the long-term vision and the financial plan.',
    step7SidePanelDropdown2Title: 'Financial plan',
    step7SidePanelDropdown2Text1Part1:
      'The financial plan, which contains the financial data about your company, is the part of the business plan that is required by law for corporations (NV, BV, CV). It can be used to assess your liability as a founder ',
    step7SidePanelDropdown2Text1Part2IsUrl:
      'if your company is declared bankrupt within three years. ',
    step7SidePanelDropdown2Text1Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/choosing-legal-status-company',
    step7SidePanelDropdown2Text1Part3:
      ' If the initial assets are judged manifestly insufficient, you can be held personally liable.',
    step7SidePanelDropdownText2:
      "The financial plan documents your business' short- and long-term financial goals and includes a strategy to achieve them. Try to make a realistic, feasible estimate, for a few years ahead. As a minimum, it should include : a forecast of the results after 1 and 2 years and a projected balance after 1 and 2 years.",
    step7SidePanelListItem1: 'an overview of all sources of funding ',
    step7SidePanelListItem2: 'an opening balance sheet',
    step7SidePanelListItem3:
      'a projected income statement after 12 and 24 months',
    step7SidePanelListItem4:
      'a budget of projected revenues and expenses for a period of at least two years',
    step7SidePanelListItem5:
      'a description of the assumptions made in estimating projected revenues and profitability.',
    step8Text1:
      "The economic activities that you will perform in your enterprise are based on a defined list of codes called 'NACE' codes and are an important part of the registration of your enterprise in the Crossroads Bank for Enterprises (CBE).",
    step8Text2:
      'It is important that the NACE codes registered in the CBE are correct and accurate in relation to the economic activities that you perform in your enterprise. If you perform other activities different to the ones included in the CBE, you may encounter administrative problems.',
    step8ToolTitle: 'Wizard',
    step8ToolText:
      'An online tool that will help you choose the right NACE codes to define the activities of your enterprise, its permits and required qualifications. Take the output of using this wizard to the next steps of your journey to start a company',
    step8ToolButtonText: 'Launch tool',
    step8SidePanelTitle: 'Define the economic activities of your enterprise',
    step8SidePanelText1:
      'Navigating the NACE codes on your own might be time consuming and on top of that, some of the economic activities that you will perform might have implications depending on the region. This tool helps you navigate all those dependecies while giving you the freedom to define your business as per your idea.',
    step8SidePanelDropdown1Title: 'Changing or adding NACE codes',
    step8SidePanelDropdown1Text1:
      'After registering the entreprise in the Crossroads Bank fro Enterprises (CBE), NACE codes can be removed or added to the enterprise in the CBE. The enterprise is responsible for the correctness of the data registered in the CBE, not only when you start your enterprise, but also when you add or no longer carry out certain activities afterwards. ',
    step8SidePanelDropdown1Text2Part1: 'This adjustment via an ',
    step8SidePanelDropdown1Text2Part2IsUrl: 'accredited business counter',
    step8SidePanelDropdown1Text2Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-enterprise-counter/accredited-business-counters',
    step8SidePanelDropdown1Text2Part3:
      ' must be made within one month of the date on which the new activities start. An adjustment of the activities of the enterprise in the CBE costs € 90.50 per establishment unit.',
    step8SidePanelDropdown2Title: 'Establishment units and NACE codes',
    step8SidePanelDropdown2Text1:
      "The definition of an 'Establishment Unit' considers every place where the enterprise has an activity (represented by a NACE code) that can be identified by an official address in Belgium.",
    step8SidePanelDropdown2Text2:
      'Every enterprise that has a characteristic obliged for registration needs to have at least one active establishment unit.',
    step8SidePanelDropdown2ListItem1:
      'In case of sole proprietorship, the main establishment unit address is the seat of the enterprise.',
    step8SidePanelDropdown2ListItem2:
      'In case of a company, the main establishment unit is the address where the legal person can be contacted.',
    step8SidePanelDropdown2Text3:
      'When the address of an existing establishment unit changes, the enterprise can make this change by themselves in the app My Enterprise or go to an accredited business counter that offers this service for a fee.',
    step8SidePanelDropdown2Text4:
      'Adding a new establishment unit to your enterprise always has a cost, and is done by an accredited business counter.',
    step9text1:
      'If you are a national of the European Economic Area (EEA) wishing to pursue your profession in Belgium, you may need to get your professional qualification recognized.',
    step9text2:
      'Some of the economic activities (NACE codes) that you will perform as part of your enterprise might be regulated and therefore require having a certain professional qualification or diploma. ',
    step9ToolTitle: 'Wizard',
    step9ToolText:
      'An online tool that will help you figure out which NACE codes require you to have a specific professional qualification. Take the output of using this wizard to your next steps in the journey to start a enterprise.',
    step9ToolButtonText: 'Launch tool',
    step9SidePanelTitle: 'Get your professional qualification recognized',
    step9SidePanelText1:
      'The online tool helps you identify if a certain economic activity (NACE) will require you to have a specific professional qualification or diploma.',
    step9SidePanelText2:
      'There is 3 ways to find out if a profession is regulated in Belgium : ',
    step9SidePanelListItem1Part1: 'By consulting the ',
    step9SidePanelListItem1Part2IsUrl:
      'European database of regulated professions.',
    step9SidePanelListItem1Part2Url:
      'https://ec.europa.eu/growth/tools-databases/regprof/home',
    step9SidePanelListItem2Part1: 'By  using the help of ',
    step9SidePanelListItem2Part2IsUrl: 'Be-Assist',
    step9SidePanelListItem2Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/conditions-dacces-la/centre-dassistance-belge-pour',
    step9SidePanelListItem2Part3:
      ', the support centre for the recognition of professional qualifications.',
    step9SidePanelListItem3Part1: 'By hiring the services of an ',
    step9SidePanelListItem3Part2IsUrl: 'accredited business counter',
    step9SidePanelListItem3Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-enterprise-counter/accredited-business-counters',
    step9SidePanelListItem3Part3: ' that offers these services for a fee.',
    step9SidePanelText3Part1:
      'If you need to get a professional qualification recognized, you can register yourself on the ',
    step9SidePanelText3Part2IsUrl: 'Competence Recognition',
    step9SidePanelText3Part2Url:
      'https://competencerecognition.belgium.be/app/form',
    step9SidePanelText3Part3: ' site.',
    step10Text1:
      'Registering your enterprise in the Crossroads Bank for Enterprises and obtaining your company number is one of the most important steps in the process.',
    step10TextExternalLinkText: 'More information regarding registration',
    step10TextExternalLinkTextUrl:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-business-counter',
    step10SidePanelTitle:
      'Register your enterprise at the Crossroads Bank for Enterprises',
    step10SidePanelDropdown1Title: 'Sole proprietorship',
    step10SidePanelDropdown1Text1Part1: 'Go to an ',
    step10SidePanelDropdown1Text1Part2IsUrl: 'accredited business counter',
    step10SidePanelDropdown1Text1Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-enterprise-counter/accredited-business-counters',
    step10SidePanelDropdown1Text1Part3:
      ' for your registration in the Crossroads Bank for Enterprises. They will ask you for the NACE codes that define the economic activities of your business since they are used by the acredited business counter to register you as a sole proprietor.',
    step10SidePanelDropdown1Text2:
      'Once your enterprise has been registered, you will receive your 10-digit company number.',
    step10SidePanelDropdown2Title: 'Company',
    step10SidePanelDropdown2SubDropdown1Title1: '1. Request bank certificate',
    step10SidePanelDropdown2SubDropdown1Text1:
      'You must request a bank certificate for the NV (starting capital requirement) and for the BV and CV (sufficient capital). In this certificate, the financial institution confirms that you have deposited the required amount into a current account in the name of the company.',
    step10SidePanelDropdown2SubDropdown2Title:
      '2. Draft the articles of association',
    step10SidePanelDropdown2SubDropdown2Text2: 'Include: ',
    step10SidePanelDropdown2SubDropdown2Listitem1: 'Identity of the founders',
    step10SidePanelDropdown2SubDropdown2Listitem2:
      'Name and purpose of the company',
    step10SidePanelDropdown2SubDropdown2Listitem3:
      'Regulation of the general meetings',
    step10SidePanelDropdown2SubDropdown2Listitem4:
      'Other rules that will apply in the company',
    step10SidePanelDropdown2SubDropdown3Title:
      '3. Draw the deed of incorporation',
    step10SidePanelDropdown2SubDropdown3Text1:
      'The first obligation is the written deed of incorporation. It contains the articles of association of the company, with all its characteristics (name, registered office, purpose, capital, if applicable, ...) and its operating rules.',
    step10SidePanelDropdown2SubDropdown3ListItem1:
      ' For limited liability companies (PLC,LTD, SCOP) which require an authentic/notarial deed, the procedure for drawing up the authentic deed of incorporation takes place via a civil-law notary. The cost depends on the complexity of the deed. ',
    step10SidePanelDropdown2SubDropdown3ListItem2:
      'For unlimited liability companies (general and limited partnership), which require a private deed, the private deed is a written agreement drawn up by the parties themselves or by third parties. This agreement must be signed by the parties.',
    step10SidePanelDropdown2SubDropdown4Title:
      '4. File the deed of incorporation',
    step10SidePanelDropdown2SubDropdown4Text1:
      'The notary will file the deed at the competent Business Court.',
    step10SidePanelDropdown2SubDropdown4Text2:
      'The deed of incorporation must be deposited with the clerk of the competent Business Court. You have 30 days to do so.',
    step10SidePanelDropdown2SubDropdown4ListItem1:
      'For limited liability companies (PLC,LTD, SCOP), the civil-law notary takes care of the filing. The authentic deed is included in the register of legal entities and there are two ways to file the deed:',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1:
      'Physical registration via a civil-law notary.',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part1:
      'Online in ',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part2IsUrl:
      'startmybusiness.be.',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part2Url:
      'https://startmybusiness.be/?lang=en',
    step10SidePanelDropdown2SubDropdown4ListItem2:
      ' For unlimited liability companies (general and limited partnership), the private deed must be filed by the partners in one of these three ways:',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part1:
      'Online in ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part2IsUrl:
      ' eGRIFFIE ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part2Url:
      'https://www.e-greffe.be/evzw/nl_BE/homepage',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part3:
      'Physical deposit with a clerk of the competent Business Court',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part1:
      'Via a ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part2IsUrl:
      'accredited business counter.',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-enterprise-counter/accredited-business-counters',
    step10SidePanelDropdown2SubDropdown4ListItem3:
      'For unlimited liability companies (general and limited partnership) , the partners must do so within four months of the partnership with legal personality.',
    step10SidePanelDropdown2SubDropdown5Title:
      '5. Register the deed of incorporation',
    step10SidePanelDropdown2SubDropdown5Text1:
      'The deed of incorporation of a company must be registered at the registry office of the FPS Finance (Administration of the Cadaster, Registration and Domains) to charge a registration tax (fiscal). You must pay registration fees for this.',
    step10SidePanelDropdown2SubDropdown5ListItem1:
      'For limited liability companies (PLC, LTC, SCOP), the registration at FPS Finance must be done by the civil-law notary within 15 days.',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem1:
      'Physical registration via a civil-law notary',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part1:
      'Online in ',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part2IsUrl:
      'startmybusiness.be',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part2Url:
      'https://startmybusiness.be/?lang=en',
    step10SidePanelDropdown3Title: 'Non-profit organization',
    step10SidePanelDropdown3TextPart1: 'The ',
    step10SidePanelDropdown3TextPart2:
      'process to register a non-proft organization',
    step10SidePanelDropdown3TextPart2Url:
      'https://business.belgium.be/en/setting_up_your_business/incorporating_a_company',
    step10SidePanelDropdown3TextPart3:
      ' such as VZW or IVZW can be found in the FOD Justice wbesite.',
    step10SidePanelDropdown4Title: 'Foreign companies',
    step10SidePanelDropdown4Text1:
      'Foreign company opening a branch in Belgium can do the same steps as above online or by physically filling certain documents with the competent Business Court.',
    step10SidePanelDropdown4Text2Part1: 'More information about ',
    step10SidePanelDropdown4Text2Part2IsUrl: 'incorporating a foreign company',
    step10SidePanelDropdown4Text2Part2Url:
      'https://business.belgium.be/en/setting_up_your_business/incorporating_a_company',
    step10SidePanelDropdown4Text2Part3: ' in Belgium',
    step10SidePanelDropdown1Text3IsUrl: 'More information (FOD Justice)',
    step10SidePanelDropdown1Text3Url:
      'https://justitie.belgium.be/nl/themas_en_dossiers/vennootschappen_verenigingen_en_stichtingen',
    step11Text1:
      'A business established in Belgium must register for VAT in the Tax Administration Office if it is subject to VAT. A Belgian (or non-Belgian) business is liable for VAT if its activity is the supply of goods or services as set out in the Belgian VAT Code.',
    step11Text2:
      "If the business's only activities are exempt from VAT (e.g. certain socio-cultural activities, financial transactions, transactions in the medical sector), and the business therefore does not have a right of deduction, then the business is not required to register.",
    step11ButtonText: 'Should my business register for VAT?',
    step11ButtonTextUrl:
      'https://finance.belgium.be/en/enterprises/vat/vat-obligation',
    step11SidePanelTitle:
      'Register your enterprise in the Tax Administration Office',
    step11SidePanelText:
      'When your enterprise has been registered, you  receive your 10-digit company number. Once registered in the Tax Administration Office, the VAT number of your enterprise is the same 10-digit number as your company number.',
    step11SidePanelDropdown1Title: 'Sole proprietorship',
    step11SidePanelDropdown1TextPart1:
      'To activate your VAT number, you can enter an ',
    step11SidePanelDropdown1TextPart2IsUrl: 'online form',
    step11SidePanelDropdown1TextPart2Url:
      'https://finance.belgium.be/en/enterprises/vat/declaration/registration-change-cancellation-activity',
    step11SidePanelDropdown1TextPart3:
      ' or you can hire the services of an accredited business counter for a fee.',
    step11SidePanelDropdown2Title: 'Company',
    step11SidePanelDropdown2Text:
      'If you hired the services of a civil-law notary, this person will take care of activating your VAT and sending back the information.',
    step12Text1:
      'Before exercising any economic activities of your enterprise (NACE codes), you have to make sure that you have the required permits and authorizations todo so. Some NACE codes require specific permits, and these might vary from region to region.',
    step12ToolTitle: 'Aggregation platform',
    step12ToolText:
      'Use this tool to search for details regarding permits, technical product specifications and professional qualification requirements.',
    step12ToolButtonText: 'Launch tool',
    step12SidePanelTitle: 'Apply for permits',
    step12SidePanelText1:
      'Figuring out if the economic activities (NACE codes) that you want to perform in your enterprise require specific permits can be time consuming. For that reason, we offer you an online tool that will help you figure out which NACE codes require specific permits in the region where you have registered your enterprise.',
    step12SidePanelText2Part1:
      'While some permits depend on the economic activity like ',
    step12SidePanelText2Part2IsUrl: 'FASFC',
    step12SidePanelText2Part2Url:
      'https://www.fasfc.be/approval-authorisation-and-registration',
    step12SidePanelText2Part3:
      '(Federal Agency for the safety of the food chain) there are other additional permits and authorisations (such as ',
    step12SidePanelText2Part4IsUrl: 'SABAM',
    step12SidePanelText2Part4Url: 'https://www.sabam.be/en',
    step12SidePanelText2Part5:
      ' or environment-related permits) that might not be linked to a NACE code but that can still be necessary in your situation.',
    step12SidePanelText3Part1:
      'Applying for permits is always associated with a cost that can vary. Requesting a permit can be done by the individual in the different comunes or regions, or by hiring the services of an ',
    step12SidePanelText3Part2: 'accredited business counter.',
    step12SidePanelText3Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-business-counter',
    step12SidePanelText3: 'Regional-specific permits:',
    step12SidePanelListItem1IsUrl: 'Flanders',
    step12SidePanelListItem1Url: 'https://www.omgevingsloketvlaanderen.be/',
    step12SidePanelListItem2IsUrl: 'Wallonia',
    step12SidePanelListItem2Url:
      'https://www.wallonie.be/fr/demarches/demander-un-permis-denvironnement-ou-un-permis-unique-pour-un-etablissement-de-classe-1-ou-2',
    step12SidePanelListItem3IsUrl: 'Brussels',
    step12SidePanelListItem3Url:
      'https://be.brussels/online-diensten/werken-en-ondernemen/ondernemen-in-brussel/vergunningen-licenties-en-toelatingen',
    step13Text1:
      'Every self-employed person, director, business manager (except for an unpaid mandate), working partner, company, etc. who is not bound by a contract of employment must join a social insurance fund. ',
    step13Text2:
      'This guarantees having certain social security rights and obligations such as family benefits, sickness and disability benefits, maternity insurance, your pension and bankruptcy insurance.',
    step13ExternalLinkText: 'List of social insurance funds in Belgium',
    step13ExternalLinkUrl:
      'https://www.nisse.be/en/list-social-security-funds?_ga=2.84272153.1048966331.1512132709-1783043858.1512132709',
    step13SidePanelTitle: 'Affiliate to a social insurance fund',
    step13SidePanelText1Part1:
      'Information regarding options of social insurance funds in Belgium, the right time to do it and details on the contribution can be found ',
    step13SidePanelTextPart2IsUrl: 'here.',
    step13SidePanelTextPart2Url:
      'https://settlinginbelgium.be/en/do-business/starting-out-as-a-self-employed-person',
    step13SidePanelText2Part1:
      'Also note that as a self-employed person, when you join a social insurance fund you are obliged to join a ',
    step13SidePanelText2Part2IsUrl: 'health insurance fund',
    step13SidePanelText2Part2Url:
      'https://www.inami.fgov.be/nl/professionals/andere-professionals/ziekenfonds/Paginas/contacteer-ziekenfondsen.aspx',
    step13SidePanelText2Part3: ' too.',
    step13SidePanelText3Part1:
      'Affiliating to a social insurance fund and a health insurance fund can be done on your own. ',
    step13SidePanelText3Part2IsUrl: 'Accredited business counters',
    step13SidePanelText3Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-enterprise-counter/accredited-business-counters',
    step13SidePanelText3Part3: ' also offer these services for a fee.',
    step14text:
      'Any enterprise wishing to employ staff in Belgium must register as an employer with the National Social Security Office (NSSO).',
    step14SidePanelText:
      'Any enterprise wishing to employ staff in Belgium must register as an employer with the National Social Security Office (NSSO).',
    step14SidePanelExternalLink: 'National Social Security Office',
    step14SidePanelExternalLinkUrl:
      'https://www.socialsecurity.be/site_nl/general/first_visit/todo/first-worker.htm',
    step14SidePanelTitle: 'Register as an employer',
    step14SidePanelText1:
      'If you are hiring one or several workers for the first time, these are the steps to take at the NSSO:',
    step14SidePanelListItem1Part1: 'Identify yourself as an employer on the ',
    step14SidePanelListItem1Part2IsUrl: 'online service WIDE',
    step14SidePanelListItem1Part2Url:
      'https://www.socialsecurity.be/site_nl/employer/applics/empdir/index.htm',
    step14SidePanelListItem1Part3:
      ' : you receive the NSSO number that identifies you as an employer',
    step14SidePanelListItem2Part1:
      'Make an immediate employment declaration on the ',
    step14SidePanelListItem2Part2IsUrl: 'online service DIMONA',
    step14SidePanelListItem2Part2Url:
      'https://www.socialsecurity.be/site_nl/employer/applics/dimona/index.htm',
    step14SidePanelListItem2Part3: ': when hiring your first employee',
    step14SidePanelListItem3Part1:
      'Make a quarterly multifunctional declaration with the ',
    step14SidePanelListItem3Part2IsUrl: 'online service DmfA',
    step14SidePanelListItem3Part2Url:
      'https://www.socialsecurity.be/site_nl/employer/applics/dmfa/index.htm',
    step14SidePanelListItem3Part3:
      ' : In addition, you have to submit a quarterly multifunctional declaration to the NSSO, which specifies the work and remuneration of all your employees.',
    step14SidePanelText2Part1:
      'These formalities are free of charge if you complete them yourself. A fee is charged, if you do it via ',
    step14SidePanelText2Part2IsUrl: 'accredited business counters.',
    step14SidePanelText2Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-enterprise-counter/accredited-business-counters',
    step14SidePanelText3:
      'More information on how to register as an employer in Belgium.',
  },
};
