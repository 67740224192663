/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unknown-property */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import './landingv2.scss';

import landing_image from '../../assets/images/landing_peoples.png';
import information_icon_blue from '../../assets/images/blue-information-icon.svg';
import arrow_upwards_blue from '../../assets/images/arrow_upwards_blue.svg';

import { MandatoryCip } from '../../components/landingv2/mandatory-chip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';

import { Headerv2 } from '../../components/landingv2/headerv2';

import { Helmet } from 'react-helmet';
import { fetchAllDrupal } from '../../store/actions/drupal';
import StepComponent from '../../components/landingv2/stepComponent';
import CheckboxItemComponent from '../../components/landingv2/checkboxItemComponent';

const Landingv2 = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state: RootState) => state?.lang);
  const { drupalSteps, drupalHeader, drupalEnterpriseTypes } = useSelector(
    (state: any) => state?.drupal
  );
  const [currCheckedType, setCurrCheckedType] = useState<string>(null);

  const isStepMandatory = (step: any) => {
    let exists = false;

    const mandatoryItems =
      Object.keys(step).includes('field_mandatory_for') &&
      Array.from(step.field_mandatory_for).map((elem: any) => elem.id);

    if (mandatoryItems && currCheckedType) {
      exists = mandatoryItems.some(
        (item) =>
          item ==
          drupalEnterpriseTypes.find((elem: any) => elem.id === currCheckedType)
            .id
      );
    }
    return exists;
  };

  useEffect(() => {
    dispatch(fetchAllDrupal(lang));
  }, [lang]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: { lang } }}>
        <html lang={lang} />
        <meta name="sdg-tag" content="sdg" />
        <meta name="DC.ISO3166" content="BE" />
        <meta name="DC.Service" content="information;procedure" />
        <meta name="policy-code" content="B3;J1;N1;N3" />
        <title>Start your business</title>
      </Helmet>
      {drupalSteps && drupalHeader && drupalEnterpriseTypes && (
        <div className="landing-main-container" id="top-nav">
          <div className="nav-to-top">
            <a href="#top-nav" className="anchor-no-styling">
              <img src={arrow_upwards_blue} alt="arrow pointing to top" />
            </a>
          </div>
          <Headerv2 headerTitle={drupalHeader?.field_header_info?.value} />
          <div className="main-container">
            <div className="page-top-container">
              <div className="z-index2">
                <h1>{drupalHeader?.title}</h1>
                <div
                  className="landing__subtitle"
                  dangerouslySetInnerHTML={{
                    __html: drupalHeader?.field_subtitle?.value,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: drupalHeader?.field_question?.value,
                  }}
                />
                <div className="checkbox-container">
                  {drupalEnterpriseTypes.length &&
                    drupalEnterpriseTypes
                      .map((type: any, index: number) => (
                        <CheckboxItemComponent
                          key={index}
                          id={type.id}
                          title={type.title}
                          setCurrCheckedType={setCurrCheckedType}
                          currChecked={currCheckedType}
                        />
                      ))
                      .reverse()}
                </div>

                <div
                  className="text-small"
                  dangerouslySetInnerHTML={{
                    __html: drupalHeader?.field_help?.value,
                  }}
                />
              </div>

              <div>
                <img
                  src={landing_image}
                  alt="persons talking"
                  className="landing__image"
                />
                <div className="blue-circle"></div>
              </div>
            </div>

            {drupalEnterpriseTypes.length && !currCheckedType && (
              <div className="steps-list-information-text md-sm-only">
                <img
                  src={information_icon_blue}
                  alt="information icon"
                  className="blue-information-icon"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: drupalHeader?.field_summary_info?.value,
                  }}
                />
              </div>
            )}
            {drupalEnterpriseTypes.length && drupalSteps.length && (
              <div className="steps-list-container">
                {!currCheckedType && (
                  <div className="steps-list-information-text">
                    <img
                      src={information_icon_blue}
                      alt="information icon"
                      className="blue-information-icon"
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: drupalHeader?.field_summary_info?.value,
                      }}
                    />
                  </div>
                )}
                <ol className="steps-list">
                  {drupalSteps.map((step: any, index: number) => (
                    <li key={index}>
                      <a href={`#${step?.id}`} className="anchor-no-styling">
                        <span>{step?.title}</span>
                        <MandatoryCip
                          hoverEffect={true}
                          showChip={step !== undefined && isStepMandatory(step)}
                        />
                      </a>
                    </li>
                  ))}
                </ol>
              </div>
            )}
            <section className="step-cards__container">
              {drupalSteps.length &&
                drupalSteps.map((step: any, index: number) => (
                  <StepComponent
                    key={index}
                    id={step.id}
                    step={step}
                    stepNumber={index + 1}
                    headerContent={drupalHeader}
                    showChip={isStepMandatory(step)}
                  />
                ))}
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default Landingv2;
