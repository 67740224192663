import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

const initialState = {},
  middleware = [thunk],
  appliedMiddleware = applyMiddleware(...middleware),
  store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(appliedMiddleware)
  );
export default store;
