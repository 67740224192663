/* eslint-disable prettier/prettier */
export default {
  header: {
    title: 'Offizielle Informationen und Dienstleistungen',
  },
  page: {
    title: 'Ihre Reise zur Gründung eines Unternehmens in Belgien',
    subtitle:
      'Gehen Sie die Schritte durch und richten Sie Ihr Unternehmen ein!',
    buttonContainerTitle: 'Welche Art von Unternehmen möchten Sie gründen?',
    buttonNvBcCv: 'NV, BV, CV',
    buttonVofCommV: 'VOF, Comm. V',
    buttonSoleProprietor: 'Einzelunternehmer',
    buttonNonProfit: 'Gemeinnützige Organisationen',
    buttonContainerTextBelowPart1: 'Sie wissen es noch nicht?',
    buttonContainerTextBelowPart2:
      'Erkunden Sie mögliche rechtliche Unternehmensstrukturen',
    listItemTitle1:
      'Überprüfen Sie, ob Sie die Voraussetzungen für die Gründung eines Unternehmens in Belgien erfüllen',
    listItemTitle2:
      'Sobald Sie die Art des Unternehmens festgelegt haben, das Sie gründen möchten, informieren wir Sie über die erforderlichen Schritte',
    itemTitle1:
      'Überprüfen Sie die Voraussetzungen für die Gründung eines Unternehmens in Belgien',
    itemTitle2: 'Bewerten Sie Ihren Ausgangspunkt',
    itemTitle3: 'Wählen Sie eine Rechtsform für Ihr Unternehmen aus',
    itemTitle4: 'Eröffnen Sie ein professionelles Bankkonto',
    itemTitle5: 'Wählen Sie die Adresse Ihres Unternehmens aus',
    itemTitle6: 'Wählen Sie den Namen Ihres Unternehmens aus',
    itemTitle7: 'Erstellen Sie einen Geschäftsplan',
    itemTitle8:
      'Definieren Sie die wirtschaftlichen Aktivitäten Ihres Unternehmens',
    itemTitle9: 'Lassen Sie Ihre berufliche Qualifikation anerkennen',
    itemTitle10:
      'Registrieren Sie Ihr Unternehmen bei der Zentralen Datenbank für Unternehmen',
    itemTitle11: 'Melden Sie Ihr Unternehmen beim Finanzamt an',
    itemTitle12: 'Beantragen Sie Genehmigungen',
    itemTitle13: 'Treten Sie einem Sozialversicherungsfonds bei',
    itemTitle14: 'Registrieren Sie sich als Arbeitgeber',
    buttonReadMore: 'Mehr lesen',
    findMoreServiceText: 'Dienstleister finden',
    registerNameSectionStep1:
      'Entscheiden Sie sich für einen Namen für Ihr Unternehmen',
    registerNameSectionStep2Part1: 'Überprüfen Sie, ob der',
    registerNameSectionStep2Part2: 'Name noch verfügbar ist',
    registerNameSectionStep3: 'Registrieren Sie den Firmennamen',
    toolCardTitle:
      'Tool zur Auswahl der NACE-BEL-Aktivitäten zur Definition Ihres Unternehmens',
    toolCardText:
      'Verwenden Sie dieses Online-Tool, um die richtigen Aktivitäten für Ihr Unternehmen zu entdecken und auszuwählen und herauszufinden, ob Sie Genehmigungen benötigen.',
    toolCardButtonText: 'Tool starten',
    moreInfoBeAssistTitle: 'Weitere Informationen',
    moreInfoBeAssistText:
      'Kontaktieren Sie das Kontaktzentrum der belgischen Regierung für weitere Informationen zur Anerkennung Ihrer beruflichen Qualifikation.',
    moreInfoBeAssistContact: 'Kontakt',
    mandatoryText: 'Pflicht',
    optionalText: 'Optional',
    step1Text1:
      'Um eine selbstständige Tätigkeit auszuüben, müssen Sie bestimmte Voraussetzungen erfüllen, insbesondere in Bezug auf:',
    step1ListItem1: 'Individuelle rechtliche Anforderungen',
    step1ListItem2:
      'Nationalität und Ausübung eines bestimmten Berufs in Belgien',
    step1ListItem3:
      'Die Aktivitäten, die Sie in Ihrem Unternehmen ausführen möchten',
    step1ExternalLinkText:
      'Voraussetzungen für die Ausübung einer selbstständigen Tätigkeit',
    step1ExternalLinkUrl:
      'https://economie.fgov.be/de/themen/unternehmen/ein-geschaefft-eroeffnen/die-bedingungen-fuer-den',
    step1SidePanelTitle:
      'Überprüfen Sie die Voraussetzungen für die Gründung eines Unternehmens in Belgien',
    step1SidePanelText:
      'Bevor Sie etwas einrichten, müssen bestimmte rechtliche Anforderungen erfüllt sein, um eine selbstständige Tätigkeit auszuüben:',
    step1SidePanelDropdown1Title1: 'Bedingungen bezüglich der Person',
    step1SidePanelDropwdown1ListItem1:
      'Sie müssen die Volljährigkeit von 18 Jahren erreicht haben',
    step1SidePanelDropwdown1ListItem2:
      'Sie dürfen nicht für rechtlich unzulänglich erklärt oder unter gerichtliche Aufsicht gestellt worden sein',
    step1SidePanelDropwdown1ListItem3:
      'Sie müssen bürgerliche und politische Rechte genießen',
    step1SidePanelDropwdown1ListItem4:
      'Sie dürfen nicht von der Ausübung von Handlungen im Zusammenhang mit einer selbstständigen beruflichen Tätigkeit ausgeschlossen sein',
    step1SidePanelDropdown2Title:
      'Bedingungen bezüglich der Staatsangehörigkeit und der Ausübung eines bestimmten Berufs in Belgien',
    step1SidePanelDropdown2ListItem1:
      'Wenn Sie ein Fachmann aus einem EU-Mitgliedstaat, dem Europäischen Wirtschaftsraum oder der Schweiz sind, benötigen Sie keine Berufskarte, um Ihre Tätigkeit in Belgien auszuüben. Allerdings müssen Sie, da bestimmte Berufe reguliert sind, zunächst überprüfen, ob Ihre beruflichen Qualifikationen in diesen Berufen anerkannt werden.',
    step1SidePanelDropdown2ListItem2:
      'Wenn Sie nicht die belgische Staatsangehörigkeit, die Staatsangehörigkeit eines Mitgliedstaats des Europäischen Wirtschaftsraums oder die schweizerische Staatsangehörigkeit haben, müssen Sie im Besitz einer Berufskarte sein, um eine selbstständige Tätigkeit in Belgien ausüben zu können (es sei denn, Sie sind davon befreit)',
    step1SidePanelDropdown3Title: 'Bedingungen in Bezug auf die Tätigkeit',
    step1SidePanelDropdown3ListItem1Part1:
      'Nachgewiesene unternehmerische Fähigkeiten (nur in ',
    step1SidePanelDropdown3ListItem1Part2IsUrl: 'Wallonien',
    step1SidePanelDropdown3ListItem1Part2Url:
      'https://www.wallonie.be/de/demarches/ablegung-einer-prufung-vor-einer-jury-um-kenntnisse-verwaltung-und-zugang-zu-einem-reglementierten',
    step1SidePanelDropdown3ListItem1Part3: '& ',
    step1SidePanelDropdown3ListItem1Part4IsUrl: 'Brussels ',
    step1SidePanelDropdown3ListItem1Part4Url:
      'https://1819.brussels/nl/infotheek/bedrijf-starten-formaliteiten/je-basiskennis-bedrijfsbeheer-aantonen-zo-pak-je-het-aan',
    step1SidePanelDropdown3ListItem1Part5: 'region)',
    step1SidePanelDropdown3ListItem2Part1:
      'Bedingungen bezüglich liberaler und ',
    step1SidePanelDropdown3ListItem2Part2IsUrl: 'geistiger Berufe ',
    step1SidePanelDropdown3ListItem2Part2Url:
      'https://economie.fgov.be/de/themen/unternehmen/ein-geschaefft-eroeffnen/die-bedingungen-fuer-den',
    step1SidePanelDropdown3ListItem3Part1: 'Bedingungen in Bezug auf ',
    step1SidePanelDropdown3ListItem3Part2IsUrl:
      'spezifische Lizenzen und Genehmigungen',
    step1SidePanelDropdown3ListItem3Part2Url:
      'https://economie.fgov.be/de/themen/unternehmen/ein-geschaefft-eroeffnen/die-bedingungen-fuer-den',
    step2Text:
      'Um den Weg zur Gründung eines Unternehmens zu beginnen, ist es wichtig, Ihren Ausgangspunkt zu bewerten. Individuelle Situationen können unterschiedliche Herausforderungen und Möglichkeiten mit sich bringen. \n Zum Beispiel die Möglichkeit, als Student ein Unternehmen zu gründen oder Ihre Arbeitslosenunterstützung zu behalten, während Sie Ihre Geschäftsidee entwickeln und Ihr Unternehmen aufbauen.',
    step2ExternalLinkText: 'Der soziale Status der Selbstständigen',
    step2ExternalLinkUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/aansluiting-bij-een-sociaal/het-sociaal-statuut-van-de',
    step2SidePanelTitle: 'Bewerten Sie Ihren Ausgangspunkt',
    step2SidePanelText:
      'Sie möchten möglicherweise Ihr Unternehmen gründen, während Sie noch studieren, während Sie einen Job haben oder während Sie arbeitslos sind. Erfahren Sie mehr über die spezifischen Bedingungen, die auf Ihre Situation zutreffen:',
    step2SidePanelDropdown1Title:
      'Gründung während Sie einen Job haben (Nebentätigkeit)',
    step2SidePanelDropdown1ItemPart1: 'Weitere Informationen dazu, wie Sie ',
    step2SidePanelDropdown1Item1Part2IsUrl:
      'start an enterprise as a secondary occupation ',
    step2SidePanelDropdown1Item1Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/aansluiting-bij-een-sociaal/het-sociaal-statuut-van-de',
    step2SidePanelDropdown1ItemPart3: 'while holding down a job',
    step2SidePanelDropwdown2Title:
      'Gründung während des Bezugs von Arbeitslosengeld',
    step2SidePanelDropdown2ItemPart1: 'Weitere Informationen dazu, wie Sie ',
    step2SidePanelDropdown2ItemPart2IsUrl:
      'ein Unternehmen gründen, während Sie Arbeitslosengeld beziehen',
    step2SidePanelDropdown2Item1Part2Url:
      'https://www.lfa.be/buerger/vollarbeitslosigkeit/durfen-sie-wahrend-ihrer-arbeitslosigkeit-arbeiten/durfen-sie-wahrend-ihrer-arbeitslosigkeit-eine-nebentatigkeit-im-rahmen-des-vorteils-sprungbrett-zur-selbstandigkeit-ausuben',
    step2SidePanelDropdown3Title:
      'Gründung aus einer Invaliditätsrente / Behindertenrente',
    step2SidePanelDropwdown3ListItem1Part1: 'Invaliditätsrente in ',
    step2SidePanelDropwdown3ListItem1Part2IsUrl: 'Flandern',
    step2SidePanelDropwdown3ListItem1Part2Url:
      'https://www.vlaio.be/nl/subsidies-financiering/subsidiedatabank/vlaamse-ondersteuningspremie-vop-voor-zelfstandigen',
    step2SidePanelDropwdown3ListItem2Part1: 'Invaliditätsrente in ',
    step2SidePanelDropwdown3ListItem2Part2IsUrl: 'Brüssel',
    step2SidePanelDropwdown3ListItem2Part2Url:
      'https://1819.brussels/nl/infotheek/bedrijf-starten-formaliteiten/ondernemen-met-een-handicap-welke-hulp-beschikbaar',
    step2SidePanelDropwdown3ListItem3Part1: 'Invaliditätsrente in ',
    step2SidePanelDropwdown3ListItem3Part2IsUrl: 'Wallonien',
    step2SidePanelDropwdown3ListItem3Part2Url:
      'https://www.1890.be/solution/aides-integration-handicap/',
    step2SidePanelDropwdown4Title: 'Ehepartner unterstützen',
    step2SidePanelDropdown4ItemPart1: 'Weitere Informationen dazu, ',
    step2SidePanelDropdown4ItemPart2IsUrl:
      'wie Sie der unterstützende Ehepartner werden',
    step2SidePanelDropdown4Item1Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/aansluiting-bij-een-sociaal/het-sociaal-statuut-van-de',
    step2SidePanelDropdown4ItemPart3: 'eines selbstständigen Unternehmers',
    step2SidePanelDropdown5Title1Part1: 'Gründung als Studentenunternehmer',
    step2SidePanelDropwdown5ListItem1Part1: 'Studentenunternehmer in ',
    step2SidePanelDropwdown5ListItem1Part2IsUrl: 'Flandern',
    step2SidePanelDropwdown5ListItem1Part2Url:
      'https://www.vlaio.be/nl/begeleiding-advies/start/je-statuut-als-zelfstandige/statuut-van-student-zelfstandige',
    step2SidePanelDropwdown5ListItem2Part1: 'Student entrepreneur in ',
    step2SidePanelDropwdown5ListItem2Part2IsUrl: 'Brüssel',
    step2SidePanelDropwdown5ListItem2Part2Url:
      'https://1819.brussels/nl/infotheek/bedrijf-starten-formaliteiten/starten-als-student-ondernemer',
    step2SidePanelDropwdown5ListItem3Part1: 'Student entrepreneur in ',
    step2SidePanelDropwdown5ListItem3Part2IsUrl: 'Wallonien',
    step2SidePanelDropwdown5ListItem3Part2Url:
      'https://www.1890.be/solution/lancer-une-activite-independante-pendant-ses-etudes-est-tout-a-fait-possible/',
    step3Text1:
      'Sie haben sich dazu entschlossen, Ihr eigenes Unternehmen zu gründen, und das nächstwichtigste, was Sie tun müssen, ist die Wahl der für Ihr Unternehmen geeigneten Rechtsform. Jede in Belgien verfügbare Rechtsform hat unterschiedliche Kosten- und Haftungsimplikationen sowie andere Spezifika. ',
    step3Text2:
      'Tatsächlich haben Sie 3 Möglichkeiten, Ihre selbstständige Tätigkeit auszuüben:',
    step3ListItem1: 'Einzelunternehmen (selbstständige Person)',
    step3ListItem2: 'Gesellschaft (mit oder ohne Rechtspersönlichkeit)',
    step3ListItem3: 'Non-Profit-Organisation',
    step3ExternalLinkText:
      'Welche rechtliche Unternehmensstruktur sollte ich wählen?',
    step3ExternalLinkUrl:
      'https://www.belgium.be/de/wirtschaft/unternehmen/gruendung/gesellschaftsformen',
    step3SidePanelTitle: 'Wählen Sie eine Rechtsform für Ihr Unternehmen',
    step3TSidePanelext1:
      'Einzelunternehmen oder Gesellschaft? Jede Formel hat ihre Vor- und Nachteile, und Ihre Wahl wird einen erheblichen Einfluss auf das Leben Ihres Unternehmens haben. Zu den Kriterien gehören unter anderem:',
    step3SidePanelListItem1: 'die Art der geplanten Tätigkeit',
    step3SidePanelListItem2: 'die Anzahl der beteiligten Personen',
    step3SidePanelListItem3: 'das verfügbare Kapital',
    step3SidePanelListItem4: 'der finanzielle Beitrag der Partner',
    step3SidePanelListItem5: 'der finanzielle Beitrag der Partner',
    step3SidePanelListItem6: 'das geeignetste Steuerregime',
    step3SidePanelListItem7:
      'die voraussichtliche Entwicklung der Tätigkeit usw.',
    step3SidePanelText2:
      'Die Wahl einer dieser beiden Optionen erfordert eine gründliche Überlegung und muss an Ihr Geschäftsprojekt angepasst werden. Zögern Sie nicht, sich von einem Notar, Anwalt, Unternehmensberater, Buchhalter oder Steuerexperten beraten zu lassen.',
    step3SidePanelText3Part1:
      'Weitere Informationen zu Rechtsformen finden Sie bei ',
    step3SidePanelText3Part2IsUrl: 'FOD Justiz',
    step3SidePanelText3Part2Url:
      'https://justitie.belgium.be/nl/themas_en_dossiers/vennootschappen_verenigingen_en_stichtingen',
    step3SidePanelDropdown1Title: 'Einzelunternehmen',
    step3SidePanelDropdown1Text:
      'Ein Einzelunternehmen ist der einfachste Weg, eine selbstständige Tätigkeit auszuüben:',
    step3SidePanelDropdown1ListItem1:
      'es erfordert weder die Erstellung von Gesellschaftsverträgen noch ein Mindeststartkapital',
    step3SidePanelDropdown1ListItem2:
      'die Gründungs- und Betriebskosten sind gering',
    step3SidePanelDropdown1ListItem3:
      'Sie können Ihr Unternehmen schnell gründen',
    step3SidePanelDropdown1ListItem4:
      'Sie sind der einzige Verantwortliche und können die für die Ausübung Ihrer Tätigkeit erforderlichen Entscheidungen treffen, ohne andere Partner zu konsultieren',
    step3SidePanelDropdown1ListItem5:
      'Die Buchführung, die geführt werden muss, ist in der Regel vereinfacht.',
    step3SidePanelDropwdon2Title: 'Gesellschaft',
    step3SidePanelDropdown2Text1:
      'Die Gründung einer Gesellschaft erfordert je nach Fall folgendes:',
    step3SidePanelDropdown2ListItem1: 'das Eingreifen eines Notars',
    step3SidePanelDropdown2ListItem2: 'ein Mindestkapital',
    step3SidePanelDropdown2ListItem3: 'einen Finanzplan',
    step3SidePanelDropdown2ListItem4:
      'die Erstellung von Gesellschaftsverträgen',
    step3SidePanelDropdown2ListItem5:
      'einen Prüfungsbericht (z. B. im Falle eines Sach- oder Sacheinlage).',
    step3SidePanelDropdown2ListItem6:
      'Darüber hinaus fallen auch Kosten für die Auflösung und Liquidation einer Gesellschaft an.',
    step3SidePanelDropdown2ListItem7:
      'Eine Gesellschaft kann eine separate Rechtspersönlichkeit haben, die ihr eigene Rechte und Pflichten verleiht.',
    step3Dropdown2Text2: 'Die gängigsten Unternehmensformen sind:',
    step3Dropdown2ListItem8:
      'Aktiengesellschaft (AG): Dies ist die Form für Unternehmen einer bestimmten Größe, bei denen die Kapitalbeschaffung im Vordergrund steht. Es wird nur ein Gründer benötigt, um eine AG zu gründen.',
    step3Dropdown2ListItem9:
      'Gesellschaft mit beschränkter Haftung (GmbH): Eine GmbH ist die "Grundform der Gesellschaft" schlechthin. Da es nur sehr wenige zwingende Vorschriften gibt, bietet eine GmbH den Wirtschaftsteilnehmern viel Spielraum, um ein Unternehmen "maßgeschneidert" zu gründen. Zum Beispiel gibt es in den Gesellschaftsverträgen die Freiheit festzulegen, wie beschränkt die GmbH tatsächlich ist. Es wird nur ein Gründer benötigt, um eine GmbH zu gründen.',
    step3Dropdown2ListItem10:
      'Genossenschaftsgesellschaft (SCOP): Eine Genossenschaftsgesellschaft ist ein Unternehmen, dessen Aktionäre ein genossenschaftliches Ideal verfolgen, was erklärt, warum immer drei Gründer erforderlich sind. Der Hauptzweck der Genossenschaftsgesellschaft besteht darin, die Bedürfnisse ihrer Aktionäre zu erfüllen, und sie kann auch als Instrument dienen, um die wirtschaftlichen oder sozialen Bedürfnisse dieser Aktionäre zu erfüllen.',
    step3Dropdown2ListItem11:
      'Offene Handelsgesellschaft (OHG): Eine OHG ist eine Partnerschaft zwischen gemeinsam und gesamtschuldnerisch haftenden Partnern, die eine unbeschränkte Haftung haben. Alle Entscheidungen müssen einstimmig getroffen werden, es sei denn, der Gesellschaftsvertrag sieht vor, dass Entscheidungen mit Mehrheit getroffen werden.',
    step3Dropdown2ListItem12:
      'Kommanditgesellschaft (KG): Eine KG ist eine Partnerschaft zwischen einem oder mehreren gemeinsam und gesamtschuldnerisch haftenden Gesellschaftern, die eine unbeschränkte Haftung haben (Komplementäre) und einem oder mehreren auf Geld- oder Sachleistungen beschränkten Gesellschaftern, die als stille oder beschränkte Gesellschafter bezeichnet werden. Die Komplementäre sind in das Management eingebunden. Die stillen Gesellschafter sind nicht in das Management eingebunden. Alle Entscheidungen müssen einstimmig getroffen werden, es sei denn, der Gesellschaftsvertrag sieht vor, dass Entscheidungen mit Mehrheit getroffen werden.',
    step3Dropwdon3Title: 'Gemeinnützige Organisation',
    step3Dropdown2ListItem13:
      'Vereinigung ohne Gewinnerzielungsabsicht (VZW): Eine gemeinnützige Vereinigung ist eine Gruppe natürlicher Personen oder juristischer Personen, die ein gemeinnütziges Ziel verfolgen. Die gemeinnützige Vereinigung besteht aus mindestens zwei Personen.',
    step3Dropdown2ListItem14:
      'Internationale Vereinigung ohne Gewinnerzielungsabsicht (IVZW): Eine internationale gemeinnützige Vereinigung ist eine Gruppe natürlicher Personen oder juristischer Personen, die ein gemeinnütziges Ziel von internationalem Nutzen verfolgt.',
    step4Text1:
      'Wenn Sie Ihre selbstständige Tätigkeit starten möchten, sei es als Einzelunternehmer oder als Gesellschaft, müssen Sie ein professionelles Geschäftskonto bei einer Bank oder anderen Finanzinstitution eröffnen.',
    step4Text2:
      'Ein professionelles Bankkonto ist wichtig, da Sie die Nummer dieses Kontos auf allen Ihren Geschäftsdokumenten (Briefen, Rechnungen usw.) neben Ihrer Betriebsnummer, dem Namen Ihres Unternehmens und dem Namen Ihrer Bank angeben müssen.',
    step4ExternalLinkText:
      'Grundlegender Bankdienst für Unternehmen und diplomatische Missionen',
    step4ExternalLinkUrl:
      'https://economie.fgov.be/nl/themas/financiele-diensten/betalingsdiensten/basisbankdienst/basisbankdienst-voor-0',
    step4SidePanelTitle: 'Eröffnen Sie ein professionelles Bankkonto',
    step4SidePanelText1:
      'Für ein Unternehmen muss das Konto auf den Namen des Unternehmens lauten. Für eine Einzelunternehmung wird auch empfohlen, ein neues Geschäftskonto zu eröffnen, um geschäftliche und private Transaktionen getrennt zu halten.',
    step4SidePanelText2:
      'Da Sie oft vor dem offiziellen Start Ihres Unternehmens Ausgaben haben, stellen Sie sicher, dass Sie Ihre Geschäftskonten so früh wie möglich eröffnen.',
    step4SidePanelText3:
      'Denken Sie daran, dass es für in Belgien registrierte Unternehmen verpflichtend ist, ein lokales (belgisches) Geschäftskonto zu haben. Je nach Bank dauert es in der Regel 2 Tage, um das Konto einzurichten, und 7 Tage, um die Karte zu erhalten.',
    step4SidePanelMoreInformation: 'Weitere Informationen',
    step4SidePanelMoreInformationUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/een-zichtrekening-openen',
    step5Text1:
      'Sie müssen eine offizielle Adresse wählen, unter der Sie Ihr Unternehmen registrieren möchten. Diese Adresse wird nach der Registrierung zum "Sitz" des Unternehmens. Je nach gewählter Rechtsform (Einzelunternehmen oder Gesellschaft) gelten unterschiedliche Haftungsregeln.',
    step5ExternalLinkText: 'Arten von Einheiten',
    step5ExternalLinkUrl:
      'https://economie.fgov.be/de/themen/unternehmen/zentrale-datenbank-der/eintragung-die-zdu',
    step5SidePanelTitle: 'Wählen Sie den Sitz Ihres Unternehmens',
    step5SidePanelText1:
      'Die verschiedenen Rechtsformen haben Auswirkungen auf die Verantwortlichkeiten und Vermögenshaftung in Bezug auf die Adresse Ihres Unternehmens und andere materielle Vermögenswerte.',
    step5SidePanelMoreInformation: 'Weitere Informationen',
    step5SidePanelMoreInformationUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/het-juridische-statuut-voor',
    step5SidePanelDropwdon1Title: 'Einzelunternehmen',
    step5SidePanelDropdown1Text1:
      'Bei einem Einzelunternehmen gibt es keine Trennung zwischen dem Vermögen, das der beruflichen Tätigkeit zugeordnet ist, und dem Privatvermögen des Unternehmers. Daher ist die offizielle Unternehmensadresse oder der "Sitz" der offizielle Wohnsitz des Einzelunternehmers.',
    step5SidePanelDropdown1Text2:
      'Der Unternehmer haftet daher mit seinem gesamten Vermögen für die Verpflichtungen seines Unternehmens. Dies ist nicht ohne Risiko, zum Beispiel im Falle des Konkurses eines großen Kunden. Die Schulden können von allen Vermögenswerten des Unternehmers, beweglich oder unbeweglich, gegenwärtig oder zukünftig, eingezogen werden.',
    step5SidePanelDropdown1Text3:
      'Das Vermögen des Ehepartners kann ebenfalls zur Begleichung der Schulden des Unternehmens verwendet werden, sofern nicht eine anderslautende Ehevereinbarung besteht.',
    step5SidePanelDropdown1SubDropdownText1:
      'Schutz der Hauptwohnung des Selbstständigen',
    step5SidePanelDropdown1SubDropdownText2:
      'Das Risiko für die Hauptwohnung des Selbstständigen wurde durch eine Maßnahme etwas reduziert, die es ermöglicht, die Wohnung in bestimmten Fällen für unpfändbar zu erklären. Der Schutz gilt nur für die Hauptwohnung des Selbstständigen, d.h. den Ort, an dem er den Großteil des Jahres lebt.',
    step5SidePanelDropdown1SubDropdownText3:
      'Der Schutz erfolgt nicht automatisch. Der Selbstständige muss eine Erklärung der Unpfändbarkeit vor dem Notar seiner Wahl abgeben.',
    step5SidePanelDropdown1SubDropdownText4Part1:
      'Der Schutz bezieht sich nur auf Schulden im Zusammenhang mit der beruflichen Tätigkeit nach der',
    step5SidePanelDropdown1SubDropdownText4Part2IsUrl:
      'Erklärung der Unpfändbarkeit',
    step5SidePanelDropdown1SubDropdownText4PartsUrl:
      'https://www.notaris.be/ondernemen/de-gevolgen-van-ondernemen-op-mijn-gezin/je-gezinswoning-beschermen-met-een-verklaring-van-onbeslagbaarheid',
    step5SidePanelDropdown1SubDropdownText4Part3:
      '. Diese Regelung gilt daher nicht für private Schulden.',
    step5SidePanelDropdown1SubDropdownText5:
      'Der Selbstständige kann den Schutz natürlich aufheben, indem er eine neue Erklärung abgibt. Dies endet auch, wenn der Selbstständige seinen Status ändert oder stirbt.',
    step5SidePanelDropdown1Text4Part1:
      'Für alle Informationen zu dieser gesetzlichen Bestimmung wenden Sie sich an einen Notar. Sie können das Verzeichnis der Notare auf der Website der',
    step5SidePanelDropdown1Text4Part2IsUrl:
      'Königlichen Föderation der belgischen Notare',
    step5SidePanelDropdown1Text4Part2Url: 'https://notaris.be/',
    step5SidePanelDropdown1Text5:
      'Im Falle eines Einzelunternehmens ist bei einer Adressänderung (dem Sitz) keine Aktion des Unternehmens erforderlich. Die Gemeinde ändert die Adresse im nationalen Register und das CBE wird entsprechend dieser neuen Information angepasst.',
    step5SidePanelDropwdon2Title: 'Gesellschaft',
    step5SidePanelDropdown2Text1:
      'Bei einer Gesellschaft gibt es eine Trennung zwischen dem Vermögen des Unternehmens und dem Vermögen des Unternehmers. Ein Teil des Vermögens kann daher vom unternehmerischen Risiko ausgenommen werden. Daher wird die offizielle Unternehmensadresse von den Eigentümern des Unternehmens vereinbart.',
    step5SidePanelDropdown2ListItem1: 'Beschränkte Haftung',
    step5SidePanelDropdown2ListItem1Text1:
      'Bei Gesellschaften mit beschränkter Haftung (AG, GmbH, SCOP) haftet der Aktionär nur bis zur Höhe seiner Einlage für die Schulden des Unternehmens. Dies bedeutet, dass die Gläubiger des Unternehmens keinen Anspruch auf das Privatvermögen des Unternehmers geltend machen können. Das Privatvermögen des Unternehmers und der Aktionäre ist daher geschützt.',
    step5SidePanelDropdown2ListItem1Text2:
      'In bestimmten Fällen kann die Haftung jedoch geltend gemacht werden. Dies ist insbesondere der Fall, wenn das Unternehmen innerhalb von drei Jahren nach der Gründung für insolvent erklärt wird und wenn das Stammkapital oder die Einlage offensichtlich unzureichend waren, um den normalen Betrieb des Unternehmens für zwei Jahre sicherzustellen.',
    step5SidePanelDropdown2ListItem2: 'Unbeschränkte Haftung',
    step5SidePanelDropdown2ListItem2Text1:
      'Bei Gesellschaften mit unbeschränkter Haftung (Offene Handelsgesellschaft, Kommanditgesellschaft) stellen die Partner (mit Ausnahme der Kommanditisten) ihr eigenes Vermögen als Sicherheit für mögliche Schulden des Unternehmens zur Verfügung. Wenn das Unternehmen seine Schulden nicht begleichen kann, können die Gläubiger ihre Forderungen gegenüber dem privaten Vermögen der Partner geltend machen.',
    step5SidePanelDropdown2ListItem2Text2:
      'Im Falle einer Gesellschaft ist bei einer Adressänderung (dem Sitz) eine Aktion des Unternehmens erforderlich. Das Unternehmen muss die Adressänderung im Belgischen Staatsblatt veröffentlichen, und dann kümmert sich das Unternehmensgericht um die Aktualisierung dieser Informationen im CBE.',
    step6Text1:
      'Die Wahl eines Namens für Ihr Unternehmen mag einfach erscheinen, ist jedoch ein entscheidender Schritt: Sie müssen den idealen Namen finden, der Kunden anzieht und Sie von Ihren Mitbewerbern abhebt.',
    step6Text2:
      'Außerdem müssen Sie verschiedene spezifische Vorschriften beachten, abhängig davon, ob Sie Ihr Geschäft als Einzelunternehmen oder als Gesellschaft gründen.',
    step6Text3:
      'In einigen Fällen wählen Einzelunternehmer möglicherweise ihren eigenen Namen als Firmennamen.',
    step6SidepanelTitle: 'Wählen Sie den Namen Ihres Unternehmens',
    step6SidePanelText1:
      'Der Name Ihres Unternehmens hat weitere Auswirkungen wie die Marke der von Ihnen verkauften Waren und Dienstleistungen, Ihre Website-Domain, Ihre E-Mail-Adresse, die Verpackung Ihrer Produkte usw.',
    step6SidePanelText2Part1:
      'Bevor Sie einen Handels- oder Firmennamen für Ihr Unternehmen wählen, können Sie über die Website des',
    step6SidePanelText2Part2IsUrl: 'Benelux-Büro für geistiges Eigentum (BOIP)',
    step6SidePanelText2Url: 'https://www.boip.int/nl',
    step6SidePanelText2Part3:
      ', das das Benelux-Markenregister verwaltet. In diesem Register erhalten Sie Zugang zu Marken, die auf dem Gebiet des Benelux geschützt sind.',
    step6SidePanelTextPart4:
      'Je nach Art der Rechtsstruktur gibt es verschiedene Möglichkeiten, Ihren Handels- und Firmennamen zu wählen und zu schützen. ',
    step6SidePanelDropdown1Title:
      'Wählen Sie einen Handels- und/oder Firmennamen (Einzelunternehmer benötigen möglicherweise nur einen Handelsnamen)',
    step6SidePanelDropdown1ListItem1:
      'Beachten Sie mögliche negative oder kontroverse Konnotationen des Namens Ihres Unternehmens, bevor Sie eine endgültige Entscheidung treffen. Es wird empfohlen, seine Bedeutung in anderen Sprachen zu überprüfen, insbesondere wenn Sie Ihre Waren und Dienstleistungen außerhalb von Belgien verkaufen möchten.',
    step6SidePanelDropdown1ListItem2Part1:
      'Suchen Sie in der Crossroads Bank for Enterprises nach vorhandenen Unternehmen, um zu ',
    step6SidePanelDropdown1ListItem2Part2IsUrl:
      'überprüfen, ob ein Name bereits existiert',
    step6SidePanelDropdown1ListItem2Part2Url:
      'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?lang=de',
    step6SidePanelDropdown2Title: 'Schützen Sie Ihren Handelsnamen',
    step6SidePanelDropdown1Text1:
      'Das Recht an einem Handelsnamen erfordert keine vorherigen Anmelde- oder Registrierungsformalitäten. Das Recht entsteht durch die erste öffentliche Verwendung des Handelsnamens.',
    step6SidePanelDropdown1Text2:
      'Die Veröffentlichung des Handelsnamens in der Crossroads Bank for Enterprises kann diese erste Verwendung darstellen, aber im Streitfall müssen Beweise vorgelegt werden, was bedeutet, dass die Regeln des Zivilgesetzbuches gelten.',
    step6SidePanelDropdown3Title: 'Schützen Sie Ihren Firmennamen',
    step6SidePanelDropdown2Text1:
      'Das Recht am Firmennamen einer juristischen Person entsteht durch die Eintragung beim Unternehmensgericht, da das Unternehmen an diesem Tag Rechtspersönlichkeit erwirbt.',
    step6SidePanelDropdown4Title: 'Markenzeichen',
    step6DSidePanelropdown4Text2Part1:
      'Während der Handelsname und der Firmenname distinctive Zeichen des Unternehmens selbst sind, ist das Markenzeichen das distinctive Zeichen für die Waren oder Dienstleistungen des Unternehmens. ',
    step6SidePanelDropdown4Text2Part2:
      'Um geschützt zu sein, muss die Marke sorgfältig gemäß einem Verfahren registriert werden. Das ',
    step6SidePanelDropdown4Text2Part3IsUrl:
      'Benelux-Büro für geistiges Eigentum (BOIP) ',
    step6SidePanelDropdown4Text2Part3Url: 'https://www.boip.int/nl',
    step6SidePanelDropdown4Text2Part4:
      'kann Ihnen alle Informationen zu diesem Thema liefern.',
    step7Text1:
      'Die Qualität des Geschäfts- und Finanzplans ist entscheidend, um Ihre zukünftigen Partner von der Ernsthaftigkeit des Projekts, seiner Rentabilität und Machbarkeit zu überzeugen. Sie sind auch notwendig, wenn Sie sich bei einer Kreditinstitution um einen Kredit bewerben möchten.',
    step7Text2:
      'Für die Gründung einer PLC (Public Limited Company), einer LTD (Private Limited Company) oder einer SCOP (Cooperative Society) ist gemäß dem Gesetz die Vorlage eines Geschäfts- und Finanzplans erforderlich, während dies auch für Einzelunternehmer empfohlen wird.',
    step7ExtraExternalLinkText:
      'Schützen Sie Ihre Geschäftsidee mit geistigem Eigentum',
    step7ExtraExternalLinkTextUrl: 'https://www.boip.int/nl',
    step7SidepanelTitle: 'Erstellen Sie einen Geschäftsplan',
    step7SidepanelText1:
      'Der Geschäftsplan ist ein Dokument, in dem Sie Ihre kurz- und langfristigen Ziele und Ihre Strategie zur Erreichung dieser Ziele detaillieren. Der Finanzplan ist der numerische Teil des Geschäftsplans.',
    step7SidepanelText2:
      'Sie können sich zur Unterstützung bei Ihren Plänen an Buchhalter wenden, da sie eventuelle Schwachstellen erkennen und Ihnen Ratschläge geben können. Die verschiedenen Regionen stellen auch kostenlose Vorlagen und nützliche Ressourcen zur Verfügung, um die Erstellung Ihres Geschäftsplans zu erleichtern.',
    step7SidepanelText3Part1:
      'Ressourcen zur Erstellung eines Geschäftsplans (',
    step7SidepanelText3Part2IsUrl: 'Brüssel, ',
    step7SidepanelText3Part2Url:
      'https://1819.brussels/nl/infotheek/alvorens-te-starten/inhoud-van-het-business-plan',
    step7SidepanelText3Part3IsUrl: 'Wallonien, ',
    step7SidepanelText3Part3Url:
      'https://www.1890.be/outil/business-plan/?search&terms=business%20plan',
    step7SidepanelText3Part4IsUrl: 'Flandern)',
    step7SidepanelText3Part4Url:
      'https://www.vlaio.be/nl/begeleiding-advies/groei-innovatie/je-bedrijf-onder-de-loep/ondernemingsplan',
    step7SidepanelMoreInformation: 'Weitere Informationen',
    step7SidepanelMoreInformationUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/het-juridische-statuut-voor',
    step7SidePanelDropdown1Title: 'Geschäftsplan',
    step7SidePanelDropdown1Text1:
      'Der Geschäftsplan ermöglicht es Ihnen, die Qualität Ihrer Geschäftsmöglichkeit darzustellen, Hauptrisiken zu identifizieren, die zukünftige Strategie und Vision zu definieren, Wachstum zu prognostizieren und Ziele festzulegen.',
    step7SidepnaleDropdown1Text2:
      'Als strategisches Instrument für Unternehmer umfasst der Geschäftsplan eine Projektbeschreibung, ein kommerzielles Angebot, eine Marktanalyse, einen Geschäftsplan, das Personalmanagement, die langfristige Vision und den Finanzplan.',
    step7SidePanelDropdown2Title: 'Finanzplan',
    step7SidePanelDropdown2Text1Part1:
      'Der Finanzplan, der die finanziellen Daten Ihres Unternehmens enthält, ist der Teil des Geschäftsplans, der gesetzlich für Unternehmen (NV, BV, CV) vorgeschrieben ist. Er kann verwendet werden, um Ihre Haftung als Gründer ',
    step7SidePanelDropdown2Text1Part2IsUrl: 'festzustellen, ',
    step7SidePanelDropdown2Text1Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/het-juridische-statuut-voor',
    step7SidePanelDropdown2Text1Part3:
      'wenn Ihr Unternehmen innerhalb von drei Jahren für bankrott erklärt wird. Wenn das Anfangsvermögen als offensichtlich unzureichend angesehen wird,können Sie persönlich haftbar gemacht werden.',
    step7SidePanelDropdownText2:
      'Der Finanzplan dokumentiert die kurz- und langfristigen finanziellen Ziele Ihres Unternehmens und enthält eine Strategie zu deren Erreichung. Versuchen Sie, eine realistische, machbare Schätzung für die nächsten Jahre zu erstellen. Als Minimum sollte er Folgendes enthalten: eine Prognose der Ergebnisse nach 1 und 2 Jahren sowie eine geplante Bilanz nach 1 und 2 Jahren.',
    step7SidePanelListItem1: 'einen Überblick über alle Finanzierungsquellen',
    step7SidePanelListItem2: 'eine Eröffnungsbilanz',
    step7SidePanelListItem3:
      'eine prognostizierte Gewinn- und Verlustrechnung nach 12 und 24 Monaten',
    step7SidePanelListItem4:
      'ein Budget für prognostizierte Einnahmen und Ausgaben für einen Zeitraum von mindestens zwei Jahren',
    step7SidePanelListItem5:
      'eine Beschreibung der Annahmen, die bei der Schätzung der prognostizierten Einnahmen und Rentabilität getroffen wurden.',
    step8Text1:
      'Die wirtschaftlichen Tätigkeiten, die Sie in Ihrem Unternehmen ausüben werden, basieren auf einer definierten Liste von Codes, den sogenannten "NACE"-Codes, und sind ein wichtiger Teil der Registrierung Ihres Unternehmens in der Crossroads Bank for Enterprises (CBE).',
    step8Text2:
      'Es ist wichtig, dass die in der CBE registrierten NACE-Codes in Bezug auf die wirtschaftlichen Tätigkeiten, die Sie in Ihrem Unternehmen ausüben, korrekt und genau sind. Wenn Sie andere Aktivitäten ausüben, die nicht in der CBE enthalten sind, können administrative Probleme auftreten.',
    step8ToolTitle: 'Assistent',
    step8ToolText:
      'Ein Online-Tool, das Ihnen dabei hilft, die richtigen NACE-Codes zur Definition der Aktivitäten Ihres Unternehmens, der erforderlichen Genehmigungen und Qualifikationen auszuwählen. Verwenden Sie die Ergebnisse dieses Assistenten für die nächsten Schritte auf Ihrem Weg zur Gründung eines Unternehmens.',
    step8ToolButtonText: 'Assistent starten',
    step8SidePanelTitle:
      'Definieren Sie die wirtschaftlichen Aktivitäten Ihres Unternehmens',
    step8SidePanelText1:
      'Die Navigation durch die NACE-Codes auf eigene Faust kann zeitaufwendig sein, und zudem können einige der wirtschaftlichen Aktivitäten, die Sie ausüben, abhängig von der Region Auswirkungen haben. Dieses Tool hilft Ihnen, all diese Abhängigkeiten zu berücksichtigen, während Sie gleichzeitig die Freiheit haben, Ihr Unternehmen entsprechend Ihrer Idee zu definieren.',
    step8SidePanelDropdown1Title: 'Änderung oder Hinzufügung von NACE-Codes',
    step8SidePanelDropdown1Text1:
      'Nach der Registrierung des Unternehmens in der Crossroads Bank for Enterprises (CBE) können NACE-Codes aus dem Unternehmen entfernt oder hinzugefügt werden. Das Unternehmen ist für die Korrektheit der in der CBE registrierten Daten verantwortlich, nicht nur bei der Gründung des Unternehmens, sondern auch wenn Sie später bestimmte Aktivitäten hinzufügen oder nicht mehr ausüben.',
    step8SidePanelDropdown1Text2Part1: 'Diese Anpassung über eine ',
    step8SidePanelDropdown1Text2Part2IsUrl: 'akkreditierte Geschäftsstelle',
    step8SidePanelDropdown1Text2Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step8SidePanelDropdown1Text2Part3:
      ' muss innerhalb eines Monats nach dem Datum, an dem die neuen Aktivitäten beginnen, erfolgen. Die Anpassung der Aktivitäten des Unternehmens in der CBE kostet 90,50 € pro Betriebseinheit.',
    step8SidePanelDropdown2Title: 'Betriebseinheiten und NACE-Codes',
    step8SidePanelDropdown2Text1:
      "Die Definition einer 'Betriebseinheit' berücksichtigt jeden Ort, an dem das Unternehmen eine Aktivität (repräsentiert durch einen NACE-Code) ausübt, die durch eine offizielle Adresse in Belgien identifiziert werden kann.",
    step8SidePanelDropdown2Text2:
      'Jedes Unternehmen, das eine registrierungspflichtige Merkmal hat, muss mindestens eine aktive Betriebseinheit haben.',
    step8SidePanelDropdown2ListItem1:
      'Im Falle einer Einzelunternehmung ist die Adresse der Hauptbetriebseinheit der Sitz des Unternehmens.',
    step8SidePanelDropdown2ListItem2:
      'Im Falle einer Gesellschaft ist die Hauptbetriebseinheit die Adresse, unter der die juristische Person kontaktiert werden kann.',
    step8SidePanelDropdown2Text3:
      'Wenn sich die Adresse einer bestehenden Betriebseinheit ändert, kann das Unternehmen diese Änderung selbst in der App "My Enterprise" vornehmen oder gegen Gebühr an eine akkreditierte Geschäftsstelle gehen, die diesen Service anbietet.',
    step8SidePanelDropdown2Text4:
      'Die Hinzufügung einer neuen Betriebseinheit zu Ihrem Unternehmen ist immer mit Kosten verbunden und wird von einer akkreditierten Geschäftsstelle durchgeführt.',
    step9text1:
      'Wenn Sie Bürger des Europäischen Wirtschaftsraums (EWR) sind und Ihren Beruf in Belgien ausüben möchten, müssen Sie möglicherweise Ihre Berufsqualifikation anerkennen lassen.',
    step9text2:
      'Einige der wirtschaftlichen Tätigkeiten (NACE-Codes), die Sie im Rahmen Ihres Unternehmens ausüben werden, können reguliert sein und erfordern daher eine bestimmte Berufsqualifikation oder ein Diplom.',
    step9ToolTitle: 'Assistent',
    step9ToolText:
      'Ein Online-Tool, das Ihnen dabei hilft festzustellen, welche NACE-Codes eine spezifische Berufsqualifikation erfordern. Verwenden Sie die Ergebnisse dieses Assistenten für Ihre nächsten Schritte auf dem Weg zur Gründung eines Unternehmens.',
    step9ToolButtonText: 'Assistent starten',
    step9SidePanelTitle: 'Anerkennung Ihrer Berufsqualifikation',
    step9SidePanelText1:
      'Das Online-Tool hilft Ihnen festzustellen, ob eine bestimmte wirtschaftliche Tätigkeit (NACE) eine spezifische Berufsqualifikation oder ein Diplom erfordert.',
    step9SidePanelText2:
      'Es gibt drei Möglichkeiten herauszufinden, ob ein Beruf in Belgien reguliert ist: ',
    step9SidePanelListItem1Part1: 'Durch Konsultation der ',
    step9SidePanelListItem1Part2IsUrl:
      'europäischen Datenbank für regulierte Berufe',
    step9SidePanelListItem1Part2Url:
      'https://ec.europa.eu/growth/tools-databases/regprof/home',
    step9SidePanelListItem2Part1: 'Durch die Nutzung der Hilfe des ',
    step9SidePanelListItem2Part2IsUrl: 'Be-Assist',
    step9SidePanelListItem2Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/toegang-tot-het-beroep/belgisch-assistentiecentrum',
    step9SidePanelListItem2Part3:
      ', des Unterstützungszentrums für die Anerkennung von Berufsqualifikationen.',
    step9SidePanelListItem3Part1:
      'Durch die Inanspruchnahme der Dienstleistungen einer ',
    step9SidePanelListItem3Part2IsUrl: 'akkreditierten Geschäftsstelle',
    step9SidePanelListItem3Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step9SidePanelListItem3Part3:
      ', die diese Dienstleistungen gegen Gebühr anbietet.',
    step9SidePanelText3Part1:
      'Wenn Sie eine Berufsqualifikation anerkennen lassen möchten, können Sie sich auf der ',
    step9SidePanelText3Part2IsUrl: 'Website zur Kompetenzerkennung',
    step9SidePanelText3Part2Url:
      'https://competencerecognition.belgium.be/app/form',
    step9SidePanelText3Part3: ' registrieren.',
    step10Text1:
      'Die Registrierung Ihres Unternehmens in der Crossroads Bank for Enterprises und die Erlangung Ihrer Unternehmensnummer ist einer der wichtigsten Schritte im Prozess.',
    step10TextExternalLinkText: 'Weitere Informationen zur Registrierung',
    step10TextExternalLinkTextUrl:
      'https://economie.fgov.be/de/themen/unternehmen/ein-geschaefft-eroeffnen/schritte-um-ein-unternehmen-zu/beim-unternehmensschalter-zu',
    step10SidePanelTitle:
      'Registrieren Sie Ihr Unternehmen bei der Crossroads Bank for Enterprises',
    step10SidePanelDropdown1Title: 'Einzelunternehmen',
    step10SidePanelDropdown1Text1Part1: 'Gehen Sie zu einer ',
    step10SidePanelDropdown1Text1Part2IsUrl: 'akkreditierten Geschäftsstelle',
    step10SidePanelDropdown1Text1Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step10SidePanelDropdown1Text1Part3:
      ', um sich in der Crossroads Bank for Enterprises zu registrieren. Sie werden nach den NACE-Codes gefragt, die die wirtschaftlichen Aktivitäten Ihres Unternehmens definieren, da sie von der akkreditierten Geschäftsstelle verwendet werden, um Sie als Einzelunternehmer zu registrieren.',
    step10SidePanelDropdown1Text2:
      'Nach der Registrierung Ihres Unternehmens erhalten Sie Ihre 10-stellige Unternehmensnummer.',
    step10SidePanelDropdown2Title: 'Gesellschaft',
    step10SidePanelDropdown2SubDropdown1Title1:
      '1. Bankbescheinigung anfordern',
    step10SidePanelDropdown2SubDropdown1Text1:
      'Sie müssen eine Bankbescheinigung für die NV (Startkapitalanforderung) sowie für die BV und CV (ausreichendes Kapital) anfordern. In dieser Bescheinigung bestätigt das Finanzinstitut, dass Sie den erforderlichen Betrag auf ein Girokonto auf den Namen des Unternehmens eingezahlt haben.',
    step10SidePanelDropdown2SubDropdown2Title: '2. Verfassen der Satzung',
    step10SidePanelDropdown2SubDropdown2Text2: 'Fügen Sie Folgendes hinzu: ',
    step10SidePanelDropdown2SubDropdown2Listitem1: 'Identität der Gründer',
    step10SidePanelDropdown2SubDropdown2Listitem2:
      'Name und Zweck der Gesellschaft',
    step10SidePanelDropdown2SubDropdown2Listitem3:
      'Regelung der Hauptversammlungen',
    step10SidePanelDropdown2SubDropdown2Listitem4:
      'Andere Regeln, die in der Gesellschaft gelten werden',
    step10SidePanelDropdown2SubDropdown3Title:
      '3. Verfassen der Gründungsurkunde',
    step10SidePanelDropdown2SubDropdown3Text1:
      'Die erste Verpflichtung ist die schriftliche Gründungsurkunde. Sie enthält die Satzung der Gesellschaft mit all ihren Merkmalen (Name, Sitz, Zweck, Kapital, falls vorhanden, ...) und ihren Betriebsregeln.',
    step10SidePanelDropdown2SubDropdown3ListItem1:
      'Für Kapitalgesellschaften (NV, BV, CV), die eine öffentliche/notarielle Urkunde erfordern, erfolgt das Verfahren zur Erstellung der öffentlichen Urkunde über einen Zivilnotar. Die Kosten hängen von der Komplexität der Urkunde ab.',
    step10SidePanelDropdown2SubDropdown3ListItem2:
      'Für Personengesellschaften (Offene Handelsgesellschaft, Kommanditgesellschaft), die eine private Urkunde erfordern, handelt es sich bei der privaten Urkunde um eine von den Parteien selbst oder von Dritten erstellte schriftliche Vereinbarung. Diese Vereinbarung muss von den Parteien unterzeichnet werden.',
    step10SidePanelDropdown2SubDropdown4Title:
      '4. Einreichen der Gründungsurkunde',
    step10SidePanelDropdown2SubDropdown4Text1:
      'Der Notar wird die Urkunde beim zuständigen Handelsgericht einreichen.',
    step10SidePanelDropdown2SubDropdown4Text2:
      'Die Gründungsurkunde muss beim Gerichtsschreiber des zuständigen Handelsgerichts hinterlegt werden. Sie haben dafür 30 Tage Zeit.',
    step10SidePanelDropdown2SubDropdown4ListItem1:
      'Für Kapitalgesellschaften (NV, BV, CV) kümmert sich der Zivilnotar um die Einreichung. Die öffentliche Urkunde wird in das Register der juristischen Personen aufgenommen, und es gibt zwei Möglichkeiten, die Urkunde einzureichen:',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1:
      'Physische Registrierung über einen Zivilnotar.',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part1:
      'Online unter ',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part2IsUrl:
      'startmybusiness.be.',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part2Url:
      'https://startmybusiness.be/?lang=de',
    step10SidePanelDropdown2SubDropdown4ListItem2:
      'Für Personengesellschaften (Offene Handelsgesellschaft, Kommanditgesellschaft) muss die private Urkunde von den Partnern auf eine der folgenden drei Arten eingereicht werden:',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part1:
      'Online über ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part2IsUrl:
      'eGRIFFIE',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part2Url:
      'https://www.e-greffe.be/evzw/de/homepage',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part3:
      'Physische Hinterlegung bei einem Gerichtsschreiber des zuständigen Handelsgerichts',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part1:
      'Über einen ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part2IsUrl:
      'akkreditierten Unternehmensschalter.',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step10SidePanelDropdown2SubDropdown4ListItem3:
      'Für Personengesellschaften (Offene Handelsgesellschaft, Kommanditgesellschaft) müssen die Partner dies innerhalb von vier Monaten nach der Partnerschaft mit Rechtspersönlichkeit tun.',
    step10SidePanelDropdown2SubDropdown5Title:
      '5. Registrierung der Gründungsurkunde',
    step10SidePanelDropdown2SubDropdown5Text1:
      'Die Gründungsurkunde einer Gesellschaft muss beim Registeramt des FPS Finanzen (Verwaltung des Katasters, der Registrierung und der Domänen) zur Erhebung einer Registrierungssteuer (fiskalisch) registriert werden. Dafür müssen Sie Registrierungsgebühren entrichten.',
    step10SidePanelDropdown2SubDropdown5ListItem1:
      'Für Kapitalgesellschaften (NV, BV, CV) muss die Registrierung beim FPS Finanzen innerhalb von 15 Tagen durch den Zivilnotar erfolgen.',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem1:
      'Physische Registrierung über einen Zivilnotar',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part1:
      'Online unter ',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part2IsUrl:
      'startmybusiness.be',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part2Url:
      'https://startmybusiness.be/?lang=de',
    step10SidePanelDropdown3Title: 'Non-Profit-Organisation',
    step10SidePanelDropdown3TextPart1: 'Der ',
    step10SidePanelDropdown3TextPart2:
      'Prozess zur Registrierung einer gemeinnützigen Organisation',
    step10SidePanelDropdown3TextPart2Url:
      'https://business.belgium.be/de/ihre_firma_gruenden/gruendung_einer_firma',
    step10SidePanelDropdown3TextPart3:
      ' wie VZW oder IVZW, finden Sie auf der Website der FÖD Justiz.',
    step10SidePanelDropdown4Title: 'Ausländische Unternehmen',
    step10SidePanelDropdown4Text1:
      'Ausländische Unternehmen, die eine Niederlassung in Belgien eröffnen, können dieselben Schritte wie oben beschrieben online oder durch physisches Ausfüllen bestimmter Dokumente bei dem zuständigen Handelsgericht durchführen.',
    step10SidePanelDropdown4Text2Part1: 'Weitere Informationen zur ',
    step10SidePanelDropdown4Text2Part2IsUrl:
      'Gründung eines ausländischen Unternehmens',
    step10SidePanelDropdown4Text2Part2Url:
      'https://business.belgium.be/de/ihre_firma_gruenden/gruendung_einer_firma',
    step10SidePanelDropdown4Text2Part3: ' in Belgien',
    step10SidePanelDropdown1Text3IsUrl: 'Weitere Informationen (FÖD Justiz)',
    step10SidePanelDropdown1Text3Url:
      'https://justitie.belgium.be/nl/themas_en_dossiers/vennootschappen_verenigingen_en_stichtingen',
    step11Text1:
      'Ein in Belgien ansässiges Unternehmen muss sich bei der Steuerverwaltung für die Mehrwertsteuer registrieren, wenn es der Mehrwertsteuer unterliegt. Ein belgisches (oder nicht-belgisches) Unternehmen ist zur Mehrwertsteuer verpflichtet, wenn seine Tätigkeit die Lieferung von Waren oder Dienstleistungen gemäß dem belgischen Mehrwertsteuergesetz umfasst.',
    step11Text2:
      'Wenn die einzigen Aktivitäten des Unternehmens von der Mehrwertsteuer befreit sind (z.B. bestimmte soziokulturelle Aktivitäten, Finanztransaktionen, Transaktionen im medizinischen Sektor) und das Unternehmen daher keinen Vorsteuerabzug geltend machen kann, besteht keine Registrierungspflicht.',
    step11ButtonText:
      'Muss sich mein Unternehmen für die Mehrwertsteuer registrieren?',
    step11ButtonTextUrl:
      'https://finanzen.belgium.be/de/unternehmen/mwst/mehrwertsteuerpflicht',
    step11SidePanelTitle:
      'Registrieren Sie Ihr Unternehmen bei der Steuerverwaltung',
    step11SidePanelText:
      'Wenn Ihr Unternehmen registriert wurde, erhalten Sie Ihre 10-stellige Unternehmensnummer. Sobald Sie beim Steueramt registriert sind, entspricht Ihre Umsatzsteuer-Nummer der gleichen 10-stelligen Nummer wie Ihre Unternehmensnummer.',
    step11SidePanelDropdown1Title: 'Einzelunternehmen',
    step11SidePanelDropdown1TextPart1:
      'Um Ihre Umsatzsteuer-Nummer zu aktivieren, können Sie ein ',
    step11SidePanelDropdown1TextPart2IsUrl: 'Online-Formular',
    step11SidePanelDropdown1TextPart2Url:
      'https://finanzen.belgium.be/de/unternehmen/mwst/erklarung/beginn-anderung-und-einstellung-der-tatigkeit',
    step11SidePanelDropdown1TextPart3:
      ' ausfüllen oder die Dienste eines akkreditierten Unternehmensschalters gegen Gebühr in Anspruch nehmen.',
    step11SidePanelDropdown2Title: 'Gesellschaft',
    step11SidePanelDropdown2Text:
      'Wenn Sie die Dienste eines Zivilnotars in Anspruch genommen haben, kümmert sich diese Person um die Aktivierung Ihrer Umsatzsteuer-Nummer und sendet die Informationen zurück.',
    step12Text1:
      'Bevor Sie wirtschaftliche Tätigkeiten in Ihrem Unternehmen ausüben (NACE-Codes), müssen Sie sicherstellen, dass Sie die erforderlichen Genehmigungen und Zulassungen dafür haben. Einige NACE-Codes erfordern spezifische Genehmigungen, und diese können je nach Region variieren.',
    step12ToolTitle: 'Aggregationsplattform',
    step12ToolText:
      'Verwenden Sie dieses Tool, um Informationen zu Genehmigungen, technischen Produktspezifikationen und beruflichen Qualifikationsanforderungen zu suchen.',
    step12ToolButtonText: 'Tool starten',
    step12SidePanelTitle: 'Beantragen von Genehmigungen',
    step12SidePanelText1:
      'Es kann zeitaufwändig sein herauszufinden, ob die von Ihnen im Unternehmen ausgeführten wirtschaftlichen Aktivitäten (NACE-Codes) spezifische Genehmigungen erfordern. Aus diesem Grund bieten wir Ihnen ein Online-Tool an, mit dem Sie herausfinden können, welche NACE-Codes in der Region, in der Sie Ihr Unternehmen registriert haben, spezifische Genehmigungen erfordern.',
    step12SidePanelText2Part1:
      'Während einige Genehmigungen von der wirtschaftlichen Tätigkeit abhängen, wie z.B. ',
    step12SidePanelText2Part2IsUrl: 'FASFC',
    step12SidePanelText2Part2Url:
      'https://www.fasfc.be/approval-authorisation-and-registration',
    step12SidePanelText2Part3:
      ' (Föderalagentur für die Sicherheit der Lebensmittelkette), gibt es andere zusätzliche Genehmigungen und Zulassungen (wie z.B. ',
    step12SidePanelText2Part4IsUrl: 'SABAM',
    step12SidePanelText2Part4Url: 'https://www.sabam.be/de',
    step12SidePanelText2Part5:
      ' oder umweltbezogene Genehmigungen), die nicht an einen NACE-Code gebunden sein können, aber dennoch in Ihrer Situation erforderlich sein können.',
    step12SidePanelText3Part1:
      'Die Beantragung von Genehmigungen ist immer mit Kosten verbunden, die variieren können. Die Beantragung einer Genehmigung kann durch den Einzelnen in den verschiedenen Gemeinden oder Regionen erfolgen oder durch Inanspruchnahme der Dienste eines ',
    step12SidePanelText3Part2: 'akkreditierten Unternehmensschalters.',
    step12SidePanelText3Part2Url:
      'https://economie.fgov.be/de/themen/unternehmen/ein-geschaefft-eroeffnen/schritte-um-ein-unternehmen-zu/beim-unternehmensschalter-zu',
    step12SidePanelText3: 'Regionalspezifische Genehmigungen:',
    step12SidePanelListItem1IsUrl: 'Flandern',
    step12SidePanelListItem1Url: 'https://www.omgevingsloketvlaanderen.be/',
    step12SidePanelListItem2IsUrl: 'Wallonien',
    step12SidePanelListItem2Url:
      'https://www.wallonie.be/fr/demarches/demander-un-permis-denvironnement-ou-un-permis-unique-pour-un-etablissement-de-classe-1-ou-2',
    step12SidePanelListItem3IsUrl: 'Brüssel',
    step12SidePanelListItem3Url:
      'https://be.brussels/online-diensten/werken-en-ondernemen/ondernemen-in-brussel/vergunningen-licenties-en-toelatingen',
    step13Text1:
      'Jede selbstständige Person, Geschäftsführer, Betriebsleiter (außer bei einem unbezahlten Mandat), Arbeitnehmer im Unternehmen usw., die nicht an einen Arbeitsvertrag gebunden ist, muss sich einer Sozialversicherungskasse anschließen.',
    step13Text2:
      'Dies gewährleistet bestimmte soziale Sicherheitsrechte und -pflichten wie Familienleistungen, Krankheits- und Invaliditätsleistungen, Mutterschaftsversicherung, Ihre Rente und Insolvenzversicherung.',
    step13ExternalLinkText: 'Liste der Sozialversicherungskassen in Belgien',
    step13ExternalLinkUrl:
      'https://www.lisvs.be/de/sozialversicherungskassen?_ga=2.84272153.1048966331.1512132709-1783043858.1512132709',
    step13SidePanelTitle: 'Mitgliedschaft in einer Sozialversicherungskasse',
    step13SidePanelText1Part1:
      'Informationen zu den Möglichkeiten der Sozialversicherungskassen in Belgien, zum richtigen Zeitpunkt und zu Einzelheiten über den Beitrag finden Sie ',
    step13SidePanelTextPart2IsUrl: 'hier.',
    step13SidePanelTextPart2Url:
      'https://sichinbelgienniederlassen.be/de/geschafte-machen/einstieg-als-selbststandiger',
    step13SidePanelText2Part1:
      'Beachten Sie außerdem, dass Sie als Selbstständiger verpflichtet sind, einer ',
    step13SidePanelText2Part2IsUrl: 'Krankenversicherungskasse',
    step13SidePanelText2Part2Url:
      'https://www.inami.fgov.be/fr/professionnels/autres/mutualites/Pages/contactez-mutualites.aspx',
    step13SidePanelText2Part3: ' beizutreten.',
    step13SidePanelText3Part1:
      'Die Mitgliedschaft in einer Sozialversicherungskasse und einer Krankenversicherungskasse kann eigenständig erfolgen. ',
    step13SidePanelText3Part2IsUrl: 'Akkreditierte Unternehmensschalter',
    step13SidePanelText3Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step13SidePanelText3Part3:
      ' bieten diese Dienstleistungen ebenfalls gegen Gebühr an.',
    step14text:
      'Jedes Unternehmen, das Mitarbeiter in Belgien beschäftigen möchte, muss sich als Arbeitgeber beim Nationalen Sozialversicherungsbüro (NSSO) registrieren lassen.',
    step14SidePanelText:
      'Jedes Unternehmen, das Mitarbeiter in Belgien beschäftigen möchte, muss sich als Arbeitgeber beim Nationalen Sozialversicherungsbüro (NSSO) registrieren lassen.',
    step14SidePanelExternalLink: 'Nationales Sozialversicherungsbüro',
    step14SidePanelExternalLinkUrl:
      'https://www.socialsecurity.be/site_de/general/first_visit/todo/first-worker.htm',
    step14SidePanelTitle: 'Als Arbeitgeber registrieren',
    step14SidePanelText1:
      'Wenn Sie zum ersten Mal einen oder mehrere Mitarbeiter einstellen, sind dies die Schritte, die beim NSSO durchgeführt werden müssen:',
    step14SidePanelListItem1Part1: 'Melden Sie sich als Arbeitgeber auf der ',
    step14SidePanelListItem1Part2IsUrl: 'Online-Dienst WIDE',
    step14SidePanelListItem1Part2Url:
      'https://www.socialsecurity.be/site_de/employer/applics/empdir/index.htm',
    step14SidePanelListItem1Part3:
      ' an: Sie erhalten die NSSO-Nummer, die Sie als Arbeitgeber identifiziert.',
    step14SidePanelListItem2Part1:
      'Geben Sie eine unmittelbare Beschäftigungserklärung auf der ',
    step14SidePanelListItem2Part2IsUrl: 'Online-Dienst DIMONA',
    step14SidePanelListItem2Part2Url:
      'https://www.socialsecurity.be/site_de/employer/applics/dimona/index.htm',
    step14SidePanelListItem2Part3:
      ' ab: wenn Sie Ihren ersten Mitarbeiter einstellen',
    step14SidePanelListItem3Part1:
      'Geben Sie eine vierteljährliche multifunktionale Erklärung mit dem ',
    step14SidePanelListItem3Part2IsUrl: 'Online-Dienst DmfA',
    step14SidePanelListItem3Part2Url:
      'https://www.socialsecurity.be/site_de/employer/applics/dmfa/index.htm',
    step14SidePanelListItem3Part3:
      ' ab: Darüber hinaus müssen Sie dem NSSO eine vierteljährliche multifunktionale Erklärung vorlegen, die die Arbeit und Vergütung aller Ihrer Mitarbeiter angibt.',
    step14SidePanelText2Part1:
      'Diese Formalitäten sind kostenlos, wenn Sie sie selbst erledigen. Es wird eine Gebühr erhoben, wenn Sie dies über ',
    step14SidePanelText2Part2IsUrl: 'akkreditierte Unternehmensschalter',
    step14SidePanelText2Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step14SidePanelText3:
      'Weitere Informationen zur Registrierung als Arbeitgeber in Belgien.',
  },
};
