import {
  DELETE_FEEDBACK,
  FEEDBACK_LOADING,
  GET_ERRORS,
  POST_FEEDBACK,
  PUT_FEEDBACK,
} from '../../constants/types';
import { Action } from '../../constants/interfaces';

const initialState = {
  loading: false,
  history: {},
};

export default function (state = initialState, action: Action) {
  const { payload, type } = action;

  switch (type) {
    case FEEDBACK_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FEEDBACK:
    case PUT_FEEDBACK:
    case POST_FEEDBACK:
      // eslint-disable-next-line no-case-declarations
      const base = {
        ...state,
        loading: false,
      };
      return payload?.id
        ? {
            ...base,
            history: {
              ...state.history,
              [payload.id]: payload,
            },
          }
        : base;
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
