import React from 'react';
import { MandatoryCip } from './mandatory-chip';
import information_icon_black from '../../assets/images/information-icon-black.svg';
import external_link_icon from '../../assets/images/external_link_blue.svg';
import light_bulb from '../../assets/images/light-bulb-icon.svg';
import { RootState } from '../../store/reducers';
import { useSelector } from 'react-redux';

interface CardComponentProps {
  id: string;
  icon: any;
  stepNumber: number;
  cardTitle: string;
  cardText: string;
  altImage?: string;
  cardListOfText?: string[];
  externalLinkUrl: string;
  externalLinkText: string;
  titleWithImage?: boolean;
  hasMandatoryChip?: boolean;
  showChip?: boolean;
  toolButtonText?: string;
  hasToolSection?: boolean;
  toolTitle?: string;
  toolText?: string;
  onButtonClick: () => any;
  toolButtonUrl?: string;
  buttonText: string;
  hasLink?: boolean;
}

const CardComponent: React.FC<CardComponentProps> = (
  props: CardComponentProps
) => {

  const lang = useSelector((state: RootState) => state?.lang);

  const {
    id,
    icon,
    stepNumber,
    cardTitle,
    cardText,
    altImage = '',
    cardListOfText = undefined,
    externalLinkUrl,
    externalLinkText,
    titleWithImage = false,
    hasMandatoryChip = true,
    showChip = false,
    toolButtonText,
    hasToolSection = false,
    toolTitle,
    toolText = '',
    onButtonClick,
    toolButtonUrl,
    buttonText,
    hasLink = true,
  } = props;

  const { baseUrl } = useSelector((state: RootState) => state?.drupal);
  const BASE_URL = baseUrl
    ? `${baseUrl}/themes/custom/sdg/src/assets/icons/step/`
    : '';

  const navigateToTool = () => {
    window.open(toolButtonUrl?.split('/')[1] + '/' + '?lang=' + lang, '_blank');
  };

  return (
    <div id={id}>
      <div className="step-card__step-count">
        <span className="primary-color-text">/</span>step {stepNumber}
      </div>
      <div className="step-card__container">
        <div className="step-card__step-icon">
          <img src={`${BASE_URL}/${icon}.svg`} alt="icon of the step" />
        </div>
        <div className="step-card__content">
          <img
            src={`${BASE_URL}/${icon}.svg`}
            alt={altImage}
            className="step-icon-sm"
          />
          {hasMandatoryChip && (
            <div>
              <MandatoryCip showChip={showChip} />
            </div>
          )}
          {titleWithImage ? (
            <div className="step-title-container">
              <div className="step-card__title">{cardTitle}</div>
              <img
                src={`${BASE_URL}/${icon}.svg`}
                alt={altImage}
                className="step-icon-sm"
              />
            </div>
          ) : (
            <div className="step-card__title">{cardTitle}</div>
          )}
          {cardListOfText?.length
            ? cardListOfText.map((item, key) => (
              <div key={key} className="step-card__text">
                <div dangerouslySetInnerHTML={{ __html: item }} />
              </div>
            ))
            : cardText && (
              <div className="step-card__text">
                <div dangerouslySetInnerHTML={{ __html: cardText }} />
              </div>
            )}
          <div className="step-card__button-container">
            <button className="step-card__button" onClick={onButtonClick}>
              {buttonText}
              <img src={information_icon_black} alt="information icon" />
            </button>
            {hasLink && externalLinkUrl && (
              <a href={externalLinkUrl} target="_blank" rel="noreferrer">
                {externalLinkText}
                <img src={external_link_icon} alt="external link icon" />
              </a>
            )}
          </div>
        </div>
        {hasToolSection && (
          <section className="tool-section">
            <img src={light_bulb} alt="light bulb icon" />
            <div className="font-weight-bold">{toolTitle}</div>
            {toolText && (
              <div className="tool-section-text">
                {<div dangerouslySetInnerHTML={{ __html: toolText }} />}
              </div>
            )}
            <button onClick={navigateToTool}>{toolButtonText}</button>
          </section>
        )}
      </div>
    </div>
  );
};

export default CardComponent;
