/* eslint-disable prettier/prettier */
export default {
  header: {
    title: 'Officiële informatie en diensten',
  },
  page: {
    title: 'Je reis om een bedrijf te starten in België',
    subtitle: 'Doorloop de stappen en richt je bedrijf op!',
    buttonContainerTitle: 'Welk type onderneming wilt u starten?',
    buttonNvBcCv: 'NV, BV, CV',
    buttonVofCommV: 'VOF, Comm. V',
    buttonSoleProprietor: 'Eenmanszaak',
    buttonNonProfit: 'Non-profitorganisaties',
    buttonContainerTextBelowPart1: 'Weet je het nog niet?',
    buttonContainerTextBelowPart2:
      'Ontdek mogelijke juridische bedrijfsstructuren',
    listItemTitle1:
      'Controleer of je voldoet aan de voorwaarden om een bedrijf in België te starten',
    listItemTitle2:
      'Zodra je het type bedrijf hebt bepaald dat je wilt starten, laten we je de verplichte stappen weten',
    itemTitle1:
      'Controleer de voorwaarden om een onderneming in België te starten',
    itemTitle2: 'Evalueer je startpunt',
    itemTitle3: 'Kies een juridische structuur voor je onderneming',
    itemTitle4: 'Open een professionele bankrekening',
    itemTitle5: 'Kies het adres van je onderneming',
    itemTitle6: 'Kies de naam van je onderneming',
    itemTitle7: 'Maak een businessplan',
    itemTitle8: 'Definieer de economische activiteiten van je onderneming',
    itemTitle9: 'Laat je professionele kwalificatie erkennen',
    itemTitle10:
      'Registreer je onderneming bij de Kruispuntbank van Ondernemingen',
    itemTitle11: 'Registreer je onderneming bij de Belastingdienst',
    itemTitle12: 'Vraag vergunningen aan',
    itemTitle13: 'Sluit je aan bij een sociaal verzekeringsfonds',
    itemTitle14: 'Registreer je als werkgever',
    buttonReadMore: 'Lees meer',
    findMoreServiceText: 'Vind dienstverleners',
    registerNameSectionStep1: 'Beslis een naam voor je bedrijf',
    registerNameSectionStep2Part1: 'Controleer of de',
    registerNameSectionStep2Part2: 'naam nog beschikbaar is',
    registerNameSectionStep3: 'Registreer de bedrijfsnaam',
    toolCardTitle:
      'Hulpmiddel om de NACE-BEL-activiteiten te kiezen om je bedrijf te definiëren',
    toolCardText:
      'Gebruik deze online tool om de juiste activiteiten voor je bedrijf te ontdekken en te selecteren en om te zien of je vergunningen nodig hebt.',
    toolCardButtonText: 'Start tool',
    moreInfoBeAssistTitle: 'Meer informatie',
    moreInfoBeAssistText:
      'Neem contact op met het contactcenter van de Belgische overheid voor meer informatie over het erkennen van je professionele kwalificatie',
    moreInfoBeAssistContact: 'Contact',
    mandatoryText: 'Verplicht',
    optionalText: 'Optioneel',
    step1Text1:
      'Om een zelfstandige activiteit uit te oefenen, moet je voldoen aan bepaalde voorwaarden met betrekking tot:',
    step1ListItem1: 'Individuele wettelijke vereisten',
    step1ListItem2:
      'Nationaliteit en het uitoefenen van een specifiek beroep in België',
    step1ListItem3: 'De activiteiten die je wilt uitvoeren in je onderneming',
    step1ExternalLinkText:
      'Voorwaarden voor het uitoefenen van een zelfstandige activiteit',
    step1ExternalLinkUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/toegang-tot-het-beroep',
    step1SidePanelTitle:
      'Controleer de voorwaarden om een onderneming in België te starten',
    step1SidePanelText:
      'Voordat je iets opzet, zijn er bepaalde wettelijke vereisten om een zelfstandige activiteit uit te oefenen:',
    step1SidePanelDropdown1Title1: 'Voorwaarden met betrekking tot de persoon',
    step1SidePanelDropwdown1ListItem1:
      'je moet de wettelijke meerderjarigheid van 18 jaar hebben',
    step1SidePanelDropwdown1ListItem2:
      'je mag niet juridisch onbekwaam worden verklaard of onder gerechtelijk toezicht geplaatst zijn',
    step1SidePanelDropwdown1ListItem3:
      'je moet genieten van burgerlijke en politieke rechten',
    step1SidePanelDropwdown1ListItem4:
      'je mag niet verboden zijn om handelingen uit te voeren met betrekking tot een zelfstandige beroepsactiviteit.',
    step1SidePanelDropdown2Title:
      'Voorwaarden met betrekking tot nationaliteit en het uitoefenen van een specifiek beroep in België',
    step1SidePanelDropdown2ListItem1:
      'Als je een professional bent uit een EU-lidstaat, de Europese Economische Ruimte of Zwitserland, heb je geen beroepskaart nodig om je activiteit in België uit te oefenen. Echter, aangezien bepaalde beroepen gereglementeerd zijn, moet je eerst controleren of je professionele kwalificaties worden erkend in die beroepen.',
    step1SidePanelDropdown2ListItem2:
      'Als je geen Belgische nationaliteit hebt, de nationaliteit van een van de lidstaten van de Europese Economische Ruimte of de Zwitserse nationaliteit, moet je in het bezit zijn van een beroepskaart om een zelfstandige activiteit in België te kunnen uitoefenen (tenzij je vrijgesteld bent).',
    step1SidePanelDropdown3Title:
      'Voorwaarden met betrekking tot de activiteit',
    step1SidePanelDropdown3ListItem1Part1:
      'Aangetoonde ondernemersvaardigheden (Alleen vereist in Wallonië ',
    step1SidePanelDropdown3ListItem1Part2IsUrl: 'Wallonië ',
    step1SidePanelDropdown3ListItem1Part2Url:
      'https://www.wallonie.be/en/demarches/sitting-exam-central-jury-obtain-management-knowledge-and-access-regulated-profession',
    step1SidePanelDropdown3ListItem1Part3: '& ',
    step1SidePanelDropdown3ListItem1Part4IsUrl: 'Brussel ',
    step1SidePanelDropdown3ListItem1Part4Url:
      'https://1819.brussels/nl/infotheek/bedrijf-starten-formaliteiten/je-basiskennis-bedrijfsbeheer-aantonen-zo-pak-je-het-aan',
    step1SidePanelDropdown3ListItem1Part5: 'regio)',
    step1SidePanelDropdown3ListItem2Part1:
      'Voorwaarden met betrekking tot liberale en ',
    step1SidePanelDropdown3ListItem2Part2IsUrl: 'intellectuele beroepen ',
    step1SidePanelDropdown3ListItem2Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/toegang-tot-het-beroep',
    step1SidePanelDropdown3ListItem3Part1: 'Voorwaarden met betrekking tot ',
    step1SidePanelDropdown3ListItem3Part2IsUrl:
      'specifieke vergunningen en machtigingen',
    step1SidePanelDropdown3ListItem3Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/toegang-tot-het-beroep',
    step2Text:
      'Om je ondernemingsreis te starten, is het belangrijk om te evalueren waar je begint. Individuele situaties kunnen verschillende uitdagingen en kansen met zich meebrengen. Bijvoorbeeld, het kunnen starten van een onderneming als student, of het behouden van je werkloosheidsuitkering terwijl je je bedrijfsidee ontwikkelt en je onderneming opzet.',
    step2ExternalLinkText: 'De sociale status van zelfstandigen',
    step2ExternalLinkUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/aansluiting-bij-een-sociaal/het-sociaal-statuut-van-de',
    step2SidePanelTitle: 'Evalueer je startpunt',
    step2SidePanelText:
      'Je kunt je onderneming starten terwijl je nog studeert, een baan hebt of werkloos bent. Ontdek de specifieke voorwaarden die van toepassing zijn op jouw situatie:',
    step2SidePanelDropdown1Title:
      'Starten terwijl je een baan hebt (nevenactiviteit)',
    step2SidePanelDropdown1ItemPart1: 'Meer informatie over hoe ',
    step2SidePanelDropdown1Item1Part2IsUrl:
      'en onderneming starten terwijl u een werkloosheidsuitkering ontvangt',
    step2SidePanelDropdown1Item1Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/aansluiting-bij-een-sociaal/het-sociaal-statuut-van-de',
    step2SidePanelDropwdown2Title:
      'Starten terwijl je een werkloosheidsuitkering ontvangt',
    step2SidePanelDropdown2ItemPart1: 'Meer informatie over hoe ',
    step2SidePanelDropdown2ItemPart2IsUrl: 'een onderneming starten ',
    step2SidePanelDropdown2Item1Part2Url:
      'https://www.rva.be/burgers/volledige-werkloosheid/mag-u-werken-tijdens-uw-werkloosheid/mag-u-tijdens-uw-werkloosheid-een-nevenactiviteit-als-zelfstandige-in-het-kader-van-het-voordeel-springplank-naar-zelfstandige-uitoefenen',
    step2SidePanelDropdown2ItemPart3:
      'terwijl u een werkloosheidsuitkering ontvangt',
    step2SidePanelDropdown3Title:
      'Starten vanuit een uitkering voor arbeidsongeschiktheid',
    step2SidePanelDropwdown3ListItem1Part1:
      'Uitkering voor arbeidsongeschiktheid in ',
    step2SidePanelDropwdown3ListItem1Part2IsUrl: 'Vlaanderen',
    step2SidePanelDropwdown3ListItem1Part2Url:
      'https://www.vlaio.be/nl/subsidies-financiering/subsidiedatabank/vlaamse-ondersteuningspremie-vop-voor-zelfstandigen',
    step2SidePanelDropwdown3ListItem2Part1:
      'Uitkering voor arbeidsongeschiktheid in ',
    step2SidePanelDropwdown3ListItem2Part2IsUrl: 'Brussel',
    step2SidePanelDropwdown3ListItem2Part2Url:
      'https://1819.brussels/nl/infotheek/bedrijf-starten-formaliteiten/ondernemen-met-een-handicap-welke-hulp-beschikbaar',
    step2SidePanelDropwdown3ListItem3Part1:
      'Uitkering voor arbeidsongeschiktheid in ',
    step2SidePanelDropwdown3ListItem3Part2IsUrl: 'Wallonië',
    step2SidePanelDropwdown3ListItem3Part2Url:
      'https://www.1890.be/solution/aides-integration-handicap/',
    step2SidePanelDropwdown4Title: 'Helpende echtgenoot',
    step2SidePanelDropdown4ItemPart1: 'Meer informatie over ',
    step2SidePanelDropdown4ItemPart2IsUrl: ' hoe de helpende echtgenoot te worden ',
    step2SidePanelDropdown4Item1Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/aansluiting-bij-een-sociaal/het-sociaal-statuut-van-de',
    step2SidePanelDropdown4ItemPart3: ' van een zelfstandige persoon',
    step2SidePanelDropdown5Title1Part1: 'Starten als student-ondernemer',
    step2SidePanelDropwdown5ListItem1Part1: 'Student-ondernemer in ',
    step2SidePanelDropwdown5ListItem1Part2IsUrl: 'Vlaanderen',
    step2SidePanelDropwdown5ListItem1Part2Url:
      'https://www.vlaio.be/nl/begeleiding-advies/start/je-statuut-als-zelfstandige/statuut-van-student-zelfstandige',
    step2SidePanelDropwdown5ListItem2Part1: 'Student-ondernemer in ',
    step2SidePanelDropwdown5ListItem2Part2IsUrl: 'Brussel',
    step2SidePanelDropwdown5ListItem2Part2Url:
      'https://www.vlaio.be/nl/begeleiding-advies/start/je-statuut-als-zelfstandige/statuut-van-student-zelfstandige',
    step2SidePanelDropwdown5ListItem3Part1: 'Student-ondernemer in ',
    step2SidePanelDropwdown5ListItem3Part2IsUrl: 'Wallonië',
    step2SidePanelDropwdown5ListItem3Part2Url:
      'https://www.1890.be/solution/lancer-une-activite-independante-pendant-ses-etudes-est-tout-a-fait-possible/',
    step3Text1:
      'You have decided to start your own company, and the next most important thing you need to do is choose the most suitable legal structure for your business. Each legal structure available in Belgium has different cost and liability implications, among other specificities.',
    step3Text2:
      'In effect, you have 3 options for exercising your self-employed activity:',
    step3ListItem1: 'Sole proprietorship (self-employed individual)',
    step3ListItem2: 'Company (with or without legal personality)',
    step3ListItem3: 'Non-profit organization',
    step3ExternalLinkText: 'What legal business structure should I choose?',
    step3ExternalLinkUrl:
      'https://www.belgium.be/nl/economie/onderneming/oprichting/vennootschapsvormen',
    step3SidePanelTitle: 'Choose a legal structure for your enterprise',
    step3TSidePanelext1:
      'Sole proprietorship or company? Each formula has its advantages and disadvantages, and your choice will have a significant impact on the life of your business. Among the criteria at stake are the following:',
    step3SidePanelListItem1: 'The type of activity envisaged',
    step3SidePanelListItem2: 'The number of people who will participate',
    step3SidePanelListItem3: 'The available capital',
    step3SidePanelListItem4: 'The financial contribution of the partners',
    step3SidePanelListItem5: 'The most appropriate tax regime',
    step3SidePanelListItem6:
      'The foreseeable development of the activity, etc.',
    step3SidePanelText2:
      "Choosing either of these two options requires in-depth reflection, and it must be adapted to your business project. Don't hesitate to ask for advice from a notary, lawyer, business consultant, accountant, or tax expert.",
    step3SidePanelText3Part1:
      'More information about legal structures can be found on the .',
    step3SidePanelText3Part2IsUrl: 'FOD Justice website',
    step3SidePanelText3Part2Url: '',
    step3SidePanelDropdown1Title: 'Sole proprietorship',
    step3SidePanelDropdown1Text:
      'A sole proprietorship is the simplest way to perform a self-employed activity:',
    step3SidePanelDropdown1ListItem1:
      'It does not require the drafting of articles of association, nor a minimum starting capital.',
    step3SidePanelDropdown1ListItem2:
      'The costs of incorporation and operation are low.',
    step3SidePanelDropdown1ListItem3: 'You can start your business quickly.',
    step3SidePanelDropdown1ListItem4:
      'You are the only one in charge and can make the decisions necessary for the exercise of your activity without having to consult other partners.',
    step3SidePanelDropdown1ListItem5:
      'The accounting to be kept is, as a general rule, simplified.',
    step3SidePanelDropwdon2Title: 'Company',
    step3SidePanelDropdown2Text1:
      'The incorporation of a company requires the following, depending on the case:',
    step3SidePanelDropdown2ListItem1: 'The intervention of a notary.',
    step3Dropdown2ListItem2: 'Minimum capital.',
    step3Dropdown2ListItem3: 'A financial plan.',
    step3Dropdown2ListItem4: 'The drafting of articles of association.',
    step3Dropdown2ListItem5:
      "An auditor's report (e.g., in the case of a contribution in kind).",
    step3Dropdown2ListItem6:
      'In addition, there are also costs associated with the termination and liquidation of a company.',
    step3Dropdown2ListItem7:
      'A company may have a separate legal personality which confers on it its own rights as well as obligations.',
    step3Dropdown2Text2: 'Most common company forms:',
    step3Dropdown2ListItem8:
      'Public Limited Company (PLC): this is the form for companies of a certain size, where raising capital is the main focus. Only one founder is needed to set up an NV.',
    step3Dropdown2ListItem9:
      "Private Limited Liability Company (LTD): a limited company is the 'basic company form' par excellence. Because there are very few mandatory regulations, a BV gives economic operators a lot of room to manoeuvre in forming a company that is 'made to measure'. For example, there is freedom in the articles of association to determine how limited the BV actually is. Only one founder is needed to set up a BV.",
    step3Dropdown2ListItem10:
      'Cooperative Society (SCOP): a cooperative society is a company whose shareholders pursue a cooperative ideal, which explains why it always requires three founders. The main purpose of the cooperative society is to meet the needs of its shareholders and it may also serve as a tool to meet the economic or social needs of those shareholders.',
    step3Dropdown2ListItem11:
      'General partnership: a general partnership is a partnership entered into between jointly and severally liable partners who have unlimited liability. All decisions must be taken unanimously, unless the partnership agreement provides for decisions to be taken by a majority.',
    step3Dropdown2ListItem12:
      'Limited Partnership (LP): a limited partnership is a partnership entered into between one or more jointly and severally liable partners who have unlimited liability, referred to as managing or limited partners, and one or more partners limited to contributions in cash or in kind, referred to as silent or limited partners. The managing partners are involved in the management. The silent partners are not involved in the management. All decisions must be taken unanimously, unless the partnership agreement provides for decisions to be taken by a majority.',
    step3Dropwdon3Title: 'Non-profit organization',
    step3Dropdown2ListItem13:
      'Association sans but lucratif (ASBL): een non-profitvereniging is een groep natuurlijke personen of rechtspersonen die een liefdadig doel nastreeft. De non-profitvereniging bestaat uit minstens twee personen.',
    step3Dropdown2ListItem14:
      'Association internationale sans but lucratif (AISBL): een internationale non-profitvereniging is een groep natuurlijke personen of rechtspersonen die een liefdadig doel van internationaal belang nastreeft.',
    step4Text1:
      'Als u uw eigen zelfstandige activiteit wilt starten, of het nu gaat om een eenmanszaak of een bedrijf, moet u een professionele zichtrekening openen bij een bank of andere financiële instelling.',
    step4Text2:
      'Het hebben van een professionele bankrekening is belangrijk omdat u het nummer van deze rekening op al uw zakelijke documenten (brieven, facturen, enz.) moet vermelden naast uw ondernemingsnummer, de naam van uw bedrijf en de naam van uw financiële instelling.',
    step4ExternalLinkText:
      'Basisbankdienstverlening voor bedrijven en diplomatieke missies',
    step4ExternalLinkUrl:
      'https://economie.fgov.be/nl/themas/financiele-diensten/betalingsdiensten/basisbankdienst/basisbankdienst-voor-0',
    step4SidePanelTitle: 'Open een professionele bankrekening',
    step4SidePanelText1:
      'Voor een bedrijf moet de rekening op naam van het bedrijf staan. Voor een eenmanszaak is het ook raadzaam om een nieuwe professionele rekening te openen, zodat commerciële en privétransacties gescheiden blijven.',
    step4SidePanelText2:
      'Aangezien u vaak kosten maakt voordat uw bedrijf officieel van start gaat, moet u ervoor zorgen dat u zo snel mogelijk uw professionele rekeningen aanmaakt.',
    step4SidePanelText3:
      'Vergeet niet dat het verplicht is om een lokale (Belgische) professionele bankrekening te hebben voor in België geregistreerde bedrijven. Afhankelijk van de bank duurt het meestal 2 dagen om de rekening op te zetten en 7 dagen om de kaart te ontvangen.',
    step4SidePanelMoreInformation: 'Meer informatie',
    step4SidePanelMoreInformationUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/een-zichtrekening-openen',
    step5Text1:
      'U moet een officieel adres kiezen waar u uw onderneming registreert. Dit adres wordt na registratie de "zetel" van de onderneming genoemd. Afhankelijk van de juridische structuur die u kiest (eenmanszaak of bedrijf), zijn er verschillende aansprakelijkheden van toepassing.',
    step5ExternalLinkText: 'Soorten vestigingseenheden',
    step5ExternalLinkUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/kruispuntbank-van/inschrijving-de-kruispuntbank',
    step5SidePanelTitle: 'Kies het adres van uw onderneming',
    step5SidePanelText1:
      'De verschillende juridische structuren hebben gevolgen met betrekking tot verantwoordelijkheden en aansprakelijkheid van activa. Dit hangt ook samen met het adres van uw onderneming en andere fysieke activa.',
    step5SidePanelMoreInformation: 'Meer informatie',
    step5SidePanelMoreInformationUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/het-juridische-statuut-voor',
    step5SidePanelDropwdon1Title: 'Eenmanszaak',
    step5SidePanelDropdown1Text1:
      'Bij een eenmanszaak is er geen scheiding tussen de activa die zijn toegewezen aan de professionele activiteit en de privéactiva van de ondernemer. Daarom is het officiële adres van de onderneming of "zetel" het officiële woonadres van de eenmanszaak.',
    step5SidePanelDropdown1Text2:
      'De ondernemer is dus, met al zijn activa, verantwoordelijk voor de verplichtingen van zijn onderneming. Dit is niet zonder risico, bijvoorbeeld in geval van faillissement van een belangrijke klant. Hun schulden kunnen worden verhaald op alle activa van de ondernemer, of ze nu roerend of onroerend zijn, aanwezig of toekomstig.',
    step5SidePanelDropdown1Text3:
      'Ook de activa van de echtgenoot kunnen worden gebruikt om de schulden van het bedrijf te betalen, tenzij een huwelijkscontract anders bepaalt.',
    step5SidePanelDropdown1SubDropdownText1:
      'Bescherming van de hoofdverblijfplaats van de zelfstandige:',
    step5SidePanelDropdown1SubDropdownText2:
      'Het risico dat boven het huis van de zelfstandige hangt, is enigszins verminderd door een maatregel die hen in staat stelt hun huis te beschermen door het vrij te stellen van beslaglegging in bepaalde omstandigheden. De bescherming geldt alleen voor de hoofdverblijfplaats van de zelfstandige, dat wil zeggen de plaats waar zij het grootste deel van het jaar wonen.',
    step5SidePanelDropdown1SubDropdownText3:
      'De bescherming is niet automatisch, de zelfstandige moet een verklaring van onbeslagbaarheid afleggen voor de notaris van hun keuze.',
    step5SidePanelDropdown1SubDropdownText4Part1:
      'De bescherming heeft alleen betrekking op schulden die verband houden met de beroepsactiviteit na de',
    step5SidePanelDropdown1SubDropdownText4Part2IsUrl:
      'verklaring van onbeslagbaarheid',
    step5SidePanelDropdown1SubDropdownText4PartsUrl:
      'https://www.notaris.be/ondernemen/de-gevolgen-van-ondernemen-op-mijn-gezin/je-gezinswoning-beschermen-met-een-verklaring-van-onbeslagbaarheid',
    step5SidePanelDropdown1SubDropdownText4Part3:
      '. Het is dus niet van toepassing op privéschulden.',
    step5SidePanelDropdown1SubDropdownText5:
      'De zelfstandige kan uiteraard afstand doen van de bescherming door een nieuwe verklaring af te leggen. Dit eindigt ook als de zelfstandige van status verandert of overlijdt',
    step5SidePanelDropdown1Text4Part1:
      'Voor alle informatie over deze wettelijke bepaling kunt u contact opnemen met een notaris. U kunt de notarisgids raadplegen op de website van de ',
    step5SidePanelDropdown1Text4Part2IsUrl:
      'Koninklijke Federatie van het Belgisch Notariaat.',
    step5SidePanelDropdown1Text4Part2Url: 'https://notaris.be/',
    step5SidePanelDropdown1Text5:
      'In het geval van een eenmanszaak is er geen actie vereist vanuit de onderneming bij een adreswijziging (de zetel). De gemeente zal het adres wijzigen in het nationaal register en de KBO zal worden aangepast op basis van deze nieuwe informatie.',
    step5SidePanelDropwdon2Title: 'Bedrijf',
    step5SidePanelDropdown2Text1:
      'Bij een bedrijf is er een scheiding tussen de activa van het bedrijf en de activa van de ondernemer. Een deel van de activa kan dus worden vrijgesteld van het ondernemersrisico. Daarom wordt het officiële adres van het bedrijf overeengekomen door de eigenaar(s) van de onderneming.',
    step5SidePanelDropdown2ListItem1: 'Beperkte aansprakelijkheid',
    step5SidePanelDropdown2ListItem1Text1:
      'Bij besloten vennootschappen met beperkte aansprakelijkheid (BV, NV, CVBA) is de aandeelhouder alleen aansprakelijk voor de schulden van het bedrijf tot het bedrag van zijn inbreng. Dit betekent dat de crediteuren van het bedrijf geen aanspraak kunnen maken op de persoonlijke activa van de ondernemer. De persoonlijke activa van de ondernemer en de aandeelhouders zijn dus beschermd.',
    step5SidePanelDropdown2ListItem1Text2:
      'In bepaalde gevallen kan echter aansprakelijkheid worden ingeroepen. Dit is met name het geval als het bedrijf binnen drie jaar na oprichting failliet wordt verklaard en als het aanvankelijke kapitaal of de kapitaalinbreng duidelijk ontoereikend was om de normale werking van het bedrijf gedurende twee jaar te waarborgen.',
    step5SidePanelDropdown2ListItem2: 'Onbeperkte aansprakelijkheid',
    step5SidePanelDropdown2ListItem2Text1:
      'Bij personenvennootschappen met onbeperkte aansprakelijkheid (VOF, CommV) stellen de vennoten (met uitzondering van de stille vennoten) hun eigen activa als waarborg voor de mogelijke schulden van het bedrijf. Als het bedrijf zijn schulden niet kan voldoen, kunnen de crediteuren hun vordering verhalen op de privéactiva van de vennoten.',
    step5SidePanelDropdown2ListItem2Text2:
      'In het geval van een bedrijf is er bij een adreswijziging (de zetel) actie vereist van de onderneming. De onderneming moet de adreswijziging bekendmaken in het Belgisch Staatsblad en vervolgens zorgt de Ondernemingsrechtbank voor het bijwerken van deze informatie in de KBO.',
    step6Text1:
      'Het kiezen van een naam voor uw onderneming lijkt misschien eenvoudig, maar het is een cruciale stap: u moet de ideale naam vinden die klanten aantrekt en u onderscheidt van uw concurrenten.',
    step6Text2:
      'Bovendien moet u voldoen aan verschillende specifieke bepalingen, afhankelijk van of u uw bedrijf start als een eenmanszaak of een bedrijf.',
    step6Text3:
      'In sommige gevallen kunnen eenmanszaken ervoor kiezen om hun bedrijf onder hun eigen naam te runnen.',
    step6SidepanelTitle: 'Kies de naam van uw onderneming',
    step6SidePanelText1:
      'De naam van uw onderneming heeft verdere implicaties, zoals het merk van de goederen en diensten die u verkoopt, uw website domein, e-mailadres, verpakking van uw producten, enzovoort.',
    step6SidePanelText2Part1:
      'Voordat u een handels- of bedrijfsnaam voor uw onderneming kiest, wilt u mogelijk controleren of er een vergelijkbaar of soortgelijk eerder merk bestaat voor vergelijkbare activiteiten en gebieden. U kunt een zoekopdracht uitvoeren via de website van het ',
    step6SidePanelText2Part2IsUrl:
      'Benelux-Bureau voor Intellectuele Eigendom (BOIP)',
    step6SidePanelText2Url: 'https://www.boip.int/nl',
    step6SidePanelText2Part3:
      ', dat het Benelux-merkenregister beheert. Dit register biedt toegang tot merken die beschermd zijn op het Benelux-gebied.',
    step6SidePanelTextPart4:
      'Er zijn verschillende opties voor het kiezen en beschermen van uw handels- en bedrijfsnaam, afhankelijk van het type juridische structuur. ',
    step6SidePanelDropdown1Title:
      'Kies een handels- en/of bedrijfsnaam (Eenmanszaken hebben mogelijk alleen een handelsnaam nodig)',
    step6SidePanelDropdown1ListItem1:
      'Houd rekening met mogelijke negatieve of controversiële connotaties van de naam van uw onderneming voordat u een definitieve beslissing neemt. Het is raadzaam om de betekenis ervan in andere talen te controleren, vooral als u uw goederen en diensten buiten België gaat verkopen.',
    step6SidePanelDropdown1ListItem2Part1:
      'Zoek in de Kruispuntbank van Ondernemingen naar bestaande ondernemingen om te ',
    step6SidePanelDropdown1ListItem2Part2IsUrl:
      ' controleren of een naam al bestaat',
    step6SidePanelDropdown1ListItem2Part2Url:
      'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?lang=nl',
    step6SidePanelDropdown2Title: 'Bescherm uw handelsnaam',
    step6SidePanelDropdown1Text1:
      'Het recht op een handelsnaam vereist geen voorafgaande indiening of registratieformaliteiten. Het recht ontstaat uit het eerste openbare gebruik van de handelsnaam.',
    step6SidePanelDropdown1Text2:
      'Het publiceren van de handelsnaam in de Kruispuntbank van Ondernemingen kan dit eerste gebruik vormen, maar bij een geschil moet bewijs worden geleverd, wat betekent dat de regels van het Burgerlijk Wetboek van toepassing zijn.',
    step6SidePanelDropdown3Title: 'Bescherm uw handelsnaam',
    step6SidePanelDropdown2Text1:
      'Het recht op de bedrijfsnaam van een rechtspersoon wordt verworven bij registratie bij de Ondernemingsrechtbank, aangezien het bedrijf op die datum rechtspersoonlijkheid verwerft.',
    step6SidePanelDropdown4Title: 'Handelsmerk',
    step6DSidePanelropdown4Text2Part1:
      'Terwijl de handelsnaam en bedrijfsnaam onderscheidende tekens zijn van het bedrijf zelf, is het handelsmerk het onderscheidende teken van de goederen of diensten van het bedrijf.',
    step6SidePanelDropdown4Text2Part2:
      'Om beschermd te worden, moet het handelsmerk nauwgezet worden geregistreerd volgens een procedure. Het ',
    step6SidePanelDropdown4Text2Part3IsUrl:
      'Benelux-Bureau voor Intellectuele Eigendom (BOIP) ',
    step6SidePanelDropdown4Text2Part3Url: 'https://www.boip.int/nl',
    step6SidePanelDropdown4Text2Part4:
      'kan u alle informatie verstrekken die u nodig heeft over dit onderwerp.',
    step7Text1:
      'De kwaliteit van de zakelijke en financiële plannen is cruciaal om uw toekomstige partners te overtuigen van de ernst van het project, de winstgevendheid en levensvatbaarheid ervan. Ze zijn ook nodig wanneer u een lening wilt aanvragen bij een kredietverstrekker.',
    step7Text2:
      'Voor de oprichting van een NV (Naamloze Vennootschap), een BV (Besloten Vennootschap) of een SCOP (Coöperatieve Vennootschap) is het indienen van een zakelijk en financieel plan wettelijk verplicht, terwijl het ook wordt aanbevolen voor eenmanszaken.',
    step7ExtraExternalLinkText:
      'Bescherm uw zakelijk idee met Intellectueel Eigendom.',
    step7ExtraExternalLinkTextUrl: 'https://www.boip.int/nl',
    step7SidepanelTitle: 'Maak een businessplan',
    step7SidepanelText1:
      'Het businessplan is een document waarin u uw korte- en langetermijndoelen en uw strategie om die doelen te bereiken gedetailleerd beschrijft. Het financieel plan is het numerieke deel van het businessplan.',
    step7SidepanelText2:
      "U kunt een beroep doen op accountants om u te helpen bij uw plannen, omdat zij eventuele zwakke punten kunnen opsporen en advies kunnen geven. De verschillende regio's bieden ook gratis sjablonen en handige bronnen aan om de creatie van uw businessplan te vergemakkelijken.",
    step7SidepanelText3Part1: 'Bronnen voor het maken van een businessplan (',
    step7SidepanelText3Part2IsUrl: 'Brussel, ',
    step7SidepanelText3Part2Url:
      'https://1819.brussels/nl/infotheek/alvorens-te-starten/inhoud-van-het-business-plan',
    step7SidepanelText3Part3IsUrl: 'Wallonië, ',
    step7SidepanelText3Part3Url:
      'https://www.1890.be/outil/business-plan/?search&terms=business%20plan',
    step7SidepanelText3Part4IsUrl: 'Vlaanderen)',
    step7SidepanelText3Part4Url:
      'https://www.vlaio.be/nl/begeleiding-advies/groei-innovatie/je-bedrijf-onder-de-loep/ondernemingsplan',
    step7SidepanelMoreInformation: 'Meer informatie',
    step7SidepanelMoreInformationUrl:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/het-juridische-statuut-voor',
    step7SidePanelDropdown1Title: 'Businessplan',
    step7SidePanelDropdown1Text1:
      "Het businessplan stelt u in staat om de kwaliteit van uw zakelijke kans te demonstreren, belangrijkste risico's te identificeren, de toekomstige strategie en visie te definiëren, groei te projecteren en doelen vast te stellen.",
    step7SidepnaleDropdown1Text2:
      'Als een strategisch instrument voor ondernemers omvat het businessplan een projectbeschrijving, een commercieel aanbod, een marktanalyse, een commercieel plan, de human resources, de langetermijnvisie en het financieel plan.',
    step7SidePanelDropdown2Title: 'Financieel plan',
    step7SidePanelDropdown2Text1Part1:
      'Het financieel plan, dat de financiële gegevens over uw bedrijf bevat, is het gedeelte van het businessplan dat wettelijk verplicht is voor vennootschappen (NV, BV, CV). Het kan worden gebruikt om uw aansprakelijkheid als oprichter te beoordelen ',
    step7SidePanelDropdown2Text1Part2IsUrl:
      'als uw bedrijf binnen drie jaar failliet wordt verklaard. ',
    step7SidePanelDropdown2Text1Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/het-juridische-statuut-voor',
    step7SidePanelDropdown2Text1Part3:
      'Als de aanvankelijke activa als kennelijk ontoereikend worden beoordeeld, kunt u persoonlijk aansprakelijk worden gesteld.',
    step7SidePanelDropdownText2:
      'Het financieel plan documenteert de korte- en langetermijnfinanciële doelen van uw bedrijf en omvat een strategie om deze te bereiken. Probeer een realistische, haalbare schatting te maken voor een paar jaar vooruit. Als minimum moet het omvatten: een prognose van de resultaten na 1 en 2 jaar en een geprojecteerde balans na 1 en 2 jaar.',
    step7SidePanelListItem1: 'een overzicht van alle financieringsbronnen',
    step7SidePanelListItem2: 'een openingsbalans',
    step7SidePanelListItem3:
      'een geprojecteerde winst- en verliesrekening na 12 en 24 maanden',
    step7SidePanelListItem4:
      'een begroting van geprojecteerde inkomsten en uitgaven voor een periode van minstens twee jaar',
    step7SidePanelListItem5:
      'een beschrijving van de aannames die zijn gedaan bij het schatten van geprojecteerde inkomsten en winstgevendheid.',
    step8Text1:
      "De economische activiteiten die u in uw onderneming zult uitvoeren, zijn gebaseerd op een gedefinieerde lijst van codes, genaamd 'NACE'-codes, en maken een belangrijk onderdeel uit van de registratie van uw onderneming in de Kruispuntbank van Ondernemingen (KBO).",
    step8Text2:
      'Het is belangrijk dat de NACE-codes die geregistreerd staan in de KBO correct en accuraat zijn in relatie tot de economische activiteiten die u in uw onderneming uitvoert. Als u andere activiteiten uitvoert dan die in de KBO zijn opgenomen, kunt u te maken krijgen met administratieve problemen.',
    step8ToolTitle: 'Wizard',
    step8ToolText:
      'Een online tool die u helpt bij het kiezen van de juiste NACE-codes om de activiteiten van uw onderneming, de benodigde vergunningen en vereiste kwalificaties te definiëren. Gebruik de uitvoer van deze wizard als basis voor de volgende stappen in uw reis om een bedrijf te starten.',
    step8ToolButtonText: 'Start de tool',
    step8SidePanelTitle:
      'Definieer de economische activiteiten van uw onderneming',
    step8SidePanelText1:
      'Het zelf navigeren door de NACE-codes kan tijdrovend zijn en bovendien kunnen sommige economische activiteiten die u zult uitvoeren, gevolgen hebben afhankelijk van de regio. Deze tool helpt u bij het navigeren door al die afhankelijkheden terwijl u de vrijheid behoudt om uw bedrijf te definiëren volgens uw idee.',
    step8SidePanelDropdown1Title: 'Het wijzigen of toevoegen van NACE-codes',
    step8SidePanelDropdown1Text1:
      'Nadat de onderneming is geregistreerd in de Kruispuntbank van Ondernemingen (KBO), kunnen NACE-codes worden verwijderd of toegevoegd aan de onderneming in de KBO. De onderneming is verantwoordelijk voor de correctheid van de gegevens die in de KBO zijn geregistreerd, niet alleen bij het starten van uw onderneming, maar ook bij het toevoegen of niet langer uitvoeren van bepaalde activiteiten nadien.',
    step8SidePanelDropdown1Text2Part1: 'Deze aanpassing via een ',
    step8SidePanelDropdown1Text2Part2IsUrl: 'erkend ondernemingsloket',
    step8SidePanelDropdown1Text2Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step8SidePanelDropdown1Text2Part3:
      ' moet worden gedaan binnen één maand na de datum waarop de nieuwe activiteiten beginnen. Een aanpassing van de activiteiten van de onderneming in de KBO kost € 90,50 per vestigingseenheid.',
    step8SidePanelDropdown2Title: 'Vestigingseenheden en NACE-codes',
    step8SidePanelDropdown2Text1:
      "De definitie van een 'Vestigingseenheid' houdt rekening met elke plaats waar de onderneming een activiteit heeft (vertegenwoordigd door een NACE-code) die kan worden geïdentificeerd met een officieel adres in België.",
    step8SidePanelDropdown2Text2:
      'Elke onderneming die een kenmerk heeft dat verplicht is voor registratie, moet ten minste één actieve vestigingseenheid hebben.',
    step8SidePanelDropdown2ListItem1:
      'Bij een eenmanszaak is het adres van de hoofdvestigingseenheid de zetel van de onderneming.',
    step8SidePanelDropdown2ListItem2:
      'In het geval van een bedrijf is de hoofdvestigingseenheid het adres waar de rechtspersoon kan worden gecontacteerd.',
    step8SidePanelDropdown2Text3:
      'Wanneer het adres van een bestaande vestigingseenheid verandert, kan de onderneming deze wijziging zelf aanbrengen in de app Mijn Onderneming of naar een erkend ondernemingsloket gaan dat deze dienst tegen betaling aanbiedt.',
    step8SidePanelDropdown2Text4:
      'Het toevoegen van een nieuwe vestigingseenheid aan uw onderneming heeft altijd een kost en wordt uitgevoerd door een erkend ondernemingsloket.',
    step9text1:
      'Als u een burger bent van de Europese Economische Ruimte (EER) en uw beroep in België wilt uitoefenen, moet u mogelijk uw professionele kwalificatie laten erkennen.',
    step9text2:
      'Sommige economische activiteiten (NACE-codes) die u als onderdeel van uw onderneming zult uitvoeren, kunnen gereguleerd zijn en daarom vereisen dat u een bepaalde professionele kwalificatie of diploma hebt.',
    step9ToolTitle: 'Wizard',
    step9ToolText:
      'Een online tool die u helpt te bepalen welke NACE-codes vereisen dat u over een specifieke professionele kwalificatie beschikt. Gebruik de resultaten van deze wizard bij uw volgende stappen in de reis naar het starten van een bedrijf',
    step9ToolButtonText: 'Start de tool',
    step9SidePanelTitle: 'Laat uw professionele kwalificatie erkennen',
    step9SidePanelText1:
      'De online tool helpt u te bepalen of een bepaalde economische activiteit (NACE) vereist dat u over een specifieke professionele kwalificatie of diploma beschikt.',
    step9SidePanelText2:
      'Er zijn 3 manieren om te achterhalen of een beroep gereglementeerd is in België:',
    step9SidePanelListItem1Part1: 'Door de ',
    step9SidePanelListItem1Part2IsUrl:
      'Europese database van gereglementeerde beroepen',
    step9SidePanelListItem1Part2Url:
      'https://ec.europa.eu/growth/tools-databases/regprof/home',
    step9SidePanelListItem2Part1: 'Door gebruik te maken van de hulp van ',
    step9SidePanelListItem2Part2IsUrl: 'Be-Assist',
    step9SidePanelListItem2Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/toegang-tot-het-beroep/belgisch-assistentiecentrum',
    step9SidePanelListItem2Part3:
      ', het ondersteuningscentrum voor de erkenning van professionele kwalificaties.',
    step9SidePanelListItem3Part1:
      'Door gebruik te maken van de diensten van een ',
    step9SidePanelListItem3Part2IsUrl: 'erkend ondernemingsloket',
    step9SidePanelListItem3Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step9SidePanelListItem3Part3: ' die deze diensten tegen betaling aanbiedt.',
    step9SidePanelText3Part1:
      'Als u een professionele kwalificatie erkend wilt krijgen, kunt u zich registreren op de ',
    step9SidePanelText3Part2IsUrl: 'Competentieherkenning',
    step9SidePanelText3Part2Url:
      'https://competencerecognition.belgium.be/app/form',
    step9SidePanelText3Part3: ' website.',
    step10Text1:
      'Het registreren van uw onderneming bij de Kruispuntbank van Ondernemingen en het verkrijgen van uw ondernemingsnummer is een van de belangrijkste stappen in het proces.',
    step10TextExternalLinkText: 'Meer informatie over registratie',
    step10TextExternalLinkTextUrl:
      'https://financien.belgium.be/nl/ondernemingen/btw/aangifte/aanvang-wijziging-einde-activiteit',
    step10SidePanelTitle:
      'Registreer uw onderneming bij de Kruispuntbank van Ondernemingen',
    step10SidePanelDropdown1Title: 'Eenmanszaak',
    step10SidePanelDropdown1Text1Part1: 'Ga naar een ',
    step10SidePanelDropdown1Text1Part2IsUrl: 'erkend ondernemingsloket',
    step10SidePanelDropdown1Text1Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step10SidePanelDropdown1Text1Part3:
      ' voor uw registratie bij de Kruispuntbank van Ondernemingen. Zij zullen u vragen om de NACE-codes te verstrekken die de economische activiteiten van uw bedrijf definiëren, aangezien deze door het erkende ondernemingsloket worden gebruikt om u als eenmanszaak te registreren.',
    step10SidePanelDropdown1Text2:
      'Zodra uw onderneming is geregistreerd, ontvangt u uw 10-cijferig ondernemingsnummer.',
    step10SidePanelDropdown2Title: 'Vennootschap',
    step10SidePanelDropdown2SubDropdown1Title1: '1. Bankattest aanvragen',
    step10SidePanelDropdown2SubDropdown1Text1:
      'U moet een bankattest aanvragen voor de NV (startkapitaalvereiste) en voor de BV en CV (voldoende kapitaal). In dit attest bevestigt de financiële instelling dat u het vereiste bedrag heeft gestort op een rekening op naam van het bedrijf.',
    step10SidePanelDropdown2SubDropdown2Title: '2. Opstellen van de statuten',
    step10SidePanelDropdown2SubDropdown2Text2: 'Inclusief:',
    step10SidePanelDropdown2SubDropdown2Listitem1:
      'Identiteit van de oprichters',
    step10SidePanelDropdown2SubDropdown2Listitem2:
      'Naam en doel van het bedrijf',
    step10SidePanelDropdown2SubDropdown2Listitem3:
      'Regeling van de algemene vergaderingen',
    step10SidePanelDropdown2SubDropdown2Listitem4:
      'Andere regels die van toepassing zullen zijn in het bedrijf',
    step10SidePanelDropdown2SubDropdown3Title:
      '3. Opstellen van de akte van oprichting',
    step10SidePanelDropdown2SubDropdown3Text1:
      'De eerste verplichting is de schriftelijke akte van oprichting. Het bevat de statuten van de vennootschap, met al haar kenmerken (naam, maatschappelijke zetel, doel, kapitaal, indien van toepassing, ...) en haar werkingsregels.',
    step10SidePanelDropdown2SubDropdown3ListItem1:
      'Voor naamloze vennootschappen (NV) en besloten vennootschappen met beperkte aansprakelijkheid (BV) die een authentieke/notariële akte vereisen, verloopt de procedure voor het opstellen van de authentieke akte van oprichting via een notaris. De kosten hangen af van de complexiteit van de akte.',
    step10SidePanelDropdown2SubDropdown3ListItem2:
      'Voor vennootschappen onder firma (VOF) en commanditaire vennootschappen op aandelen (CVA), die een onderhandse akte vereisen, is de onderhandse akte een schriftelijke overeenkomst die door de partijen zelf of door derden wordt opgesteld. Deze overeenkomst moet door de partijen worden ondertekend.',
    step10SidePanelDropdown2SubDropdown4Title:
      '4. Indienen van de akte van oprichting',
    step10SidePanelDropdown2SubDropdown4Text1:
      'De notaris zal de akte indienen bij de bevoegde Ondernemingsrechtbank.',
    step10SidePanelDropdown2SubDropdown4Text2:
      'De oprichtingsakte moet worden gedeponeerd bij de griffie van de bevoegde Ondernemingsrechtbank. U heeft hiervoor 30 dagen de tijd.',
    step10SidePanelDropdown2SubDropdown4ListItem1:
      'Voor besloten vennootschappen met beperkte aansprakelijkheid (BV), de notaris verzorgt de indiening. De authentieke akte wordt opgenomen in het register van rechtspersonen en er zijn twee manieren om de akte in te dienen:',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1:
      'Fysieke registratie via een notaris.',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part1:
      'Online op ',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part2IsUrl:
      'startmybusiness.be.',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part2Url:
      'https://startmybusiness.be/',
    step10SidePanelDropdown2SubDropdown4ListItem2:
      'Voor vennootschappen onder firma (VOF) en commanditaire vennootschappen op aandelen (CVA) moet de onderhandse akte door de vennoten op een van de volgende manieren worden ingediend:',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part1:
      'Online op ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part2IsUrl:
      'eGRIFFIE',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part2Url:
      'https://www.e-greffe.be/evzw/nl_BE/homepage',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part3:
      'Fysieke indiening bij een griffier van de bevoegde Ondernemingsrechtbank',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part1:
      'Via een ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part2IsUrl:
      'erkend ondernemingsloket.',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step10SidePanelDropdown2SubDropdown4ListItem3:
      'Voor vennootschappen onder firma (VOF) en commanditaire vennootschappen op aandelen (CVA) moet dit gebeuren binnen vier maanden na de oprichting van de vennootschap met rechtspersoonlijkheid.',
    step10SidePanelDropdown2SubDropdown5Title:
      '5. Registreer de akte van oprichting',
    step10SidePanelDropdown2SubDropdown5Text1:
      'De akte van oprichting van een vennootschap moet worden geregistreerd bij het registratiekantoor van de FOD Financiën (Administratie van het Kadaster, Registratie en Domeinen) om een registratiebelasting (fiscaal) te innen. Hiervoor moet u registratierechten betalen.',
    step10SidePanelDropdown2SubDropdown5ListItem1:
      'Voor vennootschappen onder firma (VOF) en commanditaire vennootschappen op aandelen (CVA) moet de registratie bij de FOD Financiën binnen 15 dagen worden gedaan door de notaris.',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem1:
      'Fysieke registratie via een notaris.',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part1:
      'Online op ',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part2IsUrl:
      'startmybusiness.be',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part2Url:
      'https://startmybusiness.be/',
    step10SidePanelDropdown3Title: 'Non-profit organization',
    step10SidePanelDropdown3TextPart1: 'Het ',
    step10SidePanelDropdown3TextPart2: 'proces om een non-profitorganisatie',
    step10SidePanelDropdown3TextPart2Url:
      'https://business.belgium.be/nl/uw_bedrijf_oprichten/oprichting_van_een_vennootschap',
    step10SidePanelDropdown3TextPart3:
      ' zoals VZW of IVZW te registreren, is te vinden op de website van de FOD Justitie.',
    step10SidePanelDropdown4Title: 'Buitenlandse bedrijven',
    step10SidePanelDropdown4Text1:
      'Een buitenlands bedrijf dat een filiaal in België opent, kan dezelfde stappen online uitvoeren of bepaalde documenten fysiek indienen bij de bevoegde Ondernemingsrechtbank.',
    step10SidePanelDropdown4Text2Part1: 'Meer informatie over het ',
    step10SidePanelDropdown4Text2Part2IsUrl:
      'oprichten van een buitenlands bedrijf',
    step10SidePanelDropdown4Text2Part2Url:
      'https://business.belgium.be/nl/uw_bedrijf_oprichten/oprichting_van_een_vennootschap',
    step10SidePanelDropdown4Text2Part3: ' in België',
    step10SidePanelDropdown1Text3IsUrl: 'Meer informatie (FOD Justitie)',
    step10SidePanelDropdown1Text3Url:
      'https://justitie.belgium.be/nl/themas_en_dossiers/vennootschappen_verenigingen_en_stichtingen',
    step11Text1:
      'Een in België gevestigd bedrijf moet zich registreren voor btw bij het Administratiekantoor van de Belastingen als het onderworpen is aan btw. Een Belgisch (of niet-Belgisch) bedrijf is btw-plichtig als zijn activiteit bestaat uit de levering van goederen of diensten zoals uiteengezet in het Belgische btw-wetboek.',
    step11Text2:
      'Als de enige activiteiten van het bedrijf vrijgesteld zijn van btw (bijv. bepaalde sociaal-culturele activiteiten, financiële transacties, transacties in de medische sector) en het bedrijf daarom geen recht op aftrek heeft, is het bedrijf niet verplicht om zich te registreren.',
    step11ButtonText: 'Moet mijn bedrijf zich registreren voor btw?',
    step11ButtonTextUrl:
      'https://financien.belgium.be/nl/ondernemingen/btw/btw-plicht',
    step11SidePanelTitle:
      'Registreer uw onderneming bij het Administratiekantoor van de Belastingen',
    step11SidePanelText:
      'Wanneer uw onderneming is geregistreerd, ontvangt u uw 10-cijferig ondernemingsnummer. Nadat u bent geregistreerd bij het Administratiekantoor van de Belastingen, is het btw-nummer van uw onderneming hetzelfde 10-cijferige nummer als uw ondernemingsnummer.',
    step11SidePanelDropdown1Title: 'Eenmanszaak',
    step11SidePanelDropdown1TextPart1:
      'Om uw btw-nummer te activeren, kunt u een ',
    step11SidePanelDropdown1TextPart2IsUrl: 'online formulier',
    step11SidePanelDropdown1TextPart2Url:
      'https://financien.belgium.be/nl/ondernemingen/btw/aangifte/aanvang-wijziging-einde-activiteit',
    step11SidePanelDropdown1TextPart3:
      ' invullen of u kunt tegen betaling gebruikmaken van de diensten van een erkend ondernemingsloket.',
    step11SidePanelDropdown2Title: 'Vennootschap',
    step11SidePanelDropdown2Text:
      'Als u de diensten van een notaris hebt ingeschakeld, zal deze persoon zorgen voor het activeren van uw btw-nummer en de informatie terugsturen.',
    step12Text1:
      'Voordat u economische activiteiten van uw onderneming (NACE-codes) uitoefent, moet u ervoor zorgen dat u de vereiste vergunningen en machtigingen heeft. Sommige NACE-codes vereisen specifieke vergunningen, en deze kunnen verschillen van regio tot regio.',
    step12ToolTitle: 'Aggregation platform',
    step12ToolText:
      'Een online tool die u zal helpen bij het bepalen welke NACE-codes de economische activiteiten van uw onderneming het beste definiëren, en aangeeft welke van die activiteiten een vergunning of een professionele kwalificatie vereisen. Gebruik de resultaten van deze wizard voor de volgende stappen in uw traject om een onderneming te starten.',
    step12ToolButtonText: 'Start de tool',
    step12SidePanelTitle: 'Vraag vergunningen aan',
    step12SidePanelText1:
      'Gebruik deze tool om details te zoeken over vergunningen, technische productspecificaties en professionele kwalificatievereisten.',
    step12SidePanelText2Part1:
      'Terwijl sommige vergunningen afhankelijk zijn van de economische activiteit, zoals de ',
    step12SidePanelText2Part2IsUrl: 'FASFC',
    step12SidePanelText2Part2Url:
      'https://www.fasfc.be/approval-authorisation-and-registration',
    step12SidePanelText2Part3:
      '(Federaal Agentschap voor de veiligheid van de voedselketen), zijn er ook andere vergunningen en machtigingen vereist, zoals ',
    step12SidePanelText2Part4IsUrl: 'SABAM',
    step12SidePanelText2Part4Url: 'https://www.sabam.be/nl',
    step12SidePanelText2Part5:
      'of milieuvergunningen) die mogelijk niet gekoppeld zijn aan een NACE-code, maar die toch nodig kunnen zijn in uw situatie.',
    step12SidePanelText3Part1:
      "Het aanvragen van vergunningen gaat altijd gepaard met kosten die kunnen variëren. Het aanvragen van een vergunning kan worden gedaan door particulieren bij de verschillende gemeenten of regio's, of door gebruik te maken van de diensten van een ",
    step12SidePanelText3Part2: 'erkend ondernemingsloket.',
    step12SidePanelText3Part2Url:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-business-counter',
    step12SidePanelText3: 'Regiospecifieke vergunningen:',
    step12SidePanelListItem1IsUrl: 'Vlaanderen',
    step12SidePanelListItem1Url: 'https://www.omgevingsloketvlaanderen.be/',
    step12SidePanelListItem2IsUrl: 'Wallonië',
    step12SidePanelListItem2Url:
      'https://www.wallonie.be/fr/demarches/demander-un-permis-denvironnement-ou-un-permis-unique-pour-un-etablissement-de-classe-1-ou-2',
    step12SidePanelListItem3IsUrl: 'Brussel',
    step12SidePanelListItem3Url:
      'https://be.brussels/online-diensten/werken-en-ondernemen/ondernemen-in-brussel/vergunningen-licenties-en-toelatingen',
    step13Text1:
      'Elke zelfstandige, zaakvoerder, bestuurder (behalve bij een onbezoldigd mandaat), werkend vennoot, vennootschap, enz. die niet gebonden is door een arbeidsovereenkomst, moet zich aansluiten bij een sociaal verzekeringsfonds.',
    step13Text2:
      'Dit waarborgt bepaalde socialezekerheidsrechten en -verplichtingen, zoals gezinsbijslagen, ziekte- en invaliditeitsuitkeringen, moederschapsverzekering, uw pensioen en faillissementsverzekering.',
    step13ExternalLinkText: 'Lijst van sociale verzekeringsfondsen in België',
    step13ExternalLinkUrl:
      'https://www.rsvz.be/nl/socialeverzekeringsfondsen?_ga=2.84272153.1048966331.1512132709-1783043858.1512132709',
    step13SidePanelTitle: 'Sluit u aan bij een sociaal verzekeringsfonds',
    step13SidePanelText1Part1:
      'Informatie over de mogelijkheden van sociale verzekeringsfondsen in België, het juiste moment om dit te doen en details over de bijdrage kunt u ',
    step13SidePanelTextPart2IsUrl: 'hier',
    step13SidePanelTextPart2Url:
      'https://zichvestigeninbelgie.be/nl/zakendoen/starten-als-zelfstandige',
    step13SidePanelText2Part1:
      'Houd er ook rekening mee dat u als zelfstandige, wanneer u zich aansluit bij een sociaal verzekeringsfonds, verplicht bent om u aan te sluiten bij een ',
    step13SidePanelText2Part2IsUrl: 'ziekenfonds',
    step13SidePanelText2Part2Url:
      'https://www.inami.fgov.be/nl/professionals/andere-professionals/ziekenfonds/Paginas/contacteer-ziekenfondsen.aspx',
    step13SidePanelText2Part3: '.',
    step13SidePanelText3Part1:
      'U kunt zich aansluiten bij een sociaal verzekeringsfonds en een ziekenfonds op eigen initiatief.',
    step13SidePanelText3Part2IsUrl: 'Erkende ondernemingsloketten',
    step13SidePanelText3Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step13SidePanelText3Part3: ' bieden deze diensten ook aan tegen betaling.',
    step14text:
      'Elke onderneming die personeel in België in dienst wil nemen, moet zich registreren als werkgever bij de Rijksdienst voor Sociale Zekerheid (RSZ).',
    step14SidePanelText:
      'Elke onderneming die personeel in België in dienst wil nemen, moet zich registreren als werkgever bij de Rijksdienst voor Sociale Zekerheid (RSZ).',
    step14SidePanelExternalLink: 'Rijksdienst voor Sociale Zekerheid (RSZ)',
    step14SidePanelExternalLinkUrl:
      'https://www.socialsecurity.be/site_nl/general/first_visit/todo/first-worker.htm',
    step14SidePanelTitle: 'Registreer als werkgever',
    step14SidePanelText1:
      'Als u voor het eerst één of meerdere werknemers in dienst neemt, zijn dit de stappen die u moet nemen bij de RSZ:',
    step14SidePanelListItem1Part1: 'Identificeer uzelf als werkgever op de ',
    step14SidePanelListItem1Part2IsUrl: 'online dienst WIDE',
    step14SidePanelListItem1Part2Url:
      'https://www.socialsecurity.be/site_nl/employer/applics/empdir/index.htm',
    step14SidePanelListItem1Part3:
      ': u ontvangt het RSZ-nummer dat u als werkgever identificeert',
    step14SidePanelListItem2Part1:
      'Doe een onmiddellijke aangifte van tewerkstelling op de ',
    step14SidePanelListItem2Part2IsUrl: 'online dienst DIMONA',
    step14SidePanelListItem2Part2Url:
      'https://www.socialsecurity.be/site_nl/employer/applics/dimona/index.htm',
    step14SidePanelListItem2Part3:
      ': bij het in dienst nemen van uw eerste werknemer',
    step14SidePanelListItem3Part1: 'Doe een kwartaalaangifte met de ',
    step14SidePanelListItem3Part2IsUrl: 'online dienst DmfA',
    step14SidePanelListItem3Part2Url:
      'https://www.socialsecurity.be/site_nl/employer/applics/dmfa/index.htm',
    step14SidePanelListItem3Part3:
      ': Daarnaast moet u een kwartaalaangifte indienen bij de RSZ, waarin het werk en de bezoldiging van al uw werknemers worden gespecificeerd.',
    step14SidePanelText2Part1:
      'Deze formaliteiten zijn kosteloos als u ze zelf invult. Er worden kosten in rekening gebracht als u dit via ',
    step14SidePanelText2Part2IsUrl: 'erkende ondernemingsloketten',
    step14SidePanelText2Part2Url:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een/de-erkende',
    step14SidePanelText3:
      'Meer informatie over hoe u zich kunt registreren als werkgever in België.',
  },
};
