export enum Header {
  DETAIL,
  LANDING,
  SEARCH,
}

export enum Lang {
  DE = 'de',
  EN = 'en',
  FR = 'fr',
  NL = 'nl',
}

export enum Thumb {
  DOWN,
  NEUTRAL,
  UP,
}

export enum EntityType {
  JOB_NAME = 'job_name',
}
