/* eslint-disable prettier/prettier */

import React from 'react';
import { Link } from 'react-router-dom';

export default {
  footer: {
    service: 'Belgischer Föderaler öffentlicher Dienst ',
    lastUpdated: 'Zuletzt aktualisiert am: ',
    contactButtonTextImprove: 'Helfen Sie uns, zu verbessern',
    contactButtonTextReport: 'Ein Hindernis melden',
    contactButtonTextContact: 'Kontaktieren Sie den Unterstützungsdienst',
    contactButtonTitle: 'Möchten Sie uns kontaktieren?',
  },
  header: {
    example:
      'Zum Beispiel: berufliche Anerkennung, Erlaubnissen, technische Vorschriften, ...',
    language: 'Sprache',
    placeholder: 'Ich suche…',
    search: 'Suche',
    title: 'Wie kann ich Ihnen helfen ?',
  },
  landing: {
    asterix:
      '* Ein Antrag auf Anerkennung über das zentrale Anfrageformular ist noch nicht für alle reglementierten Berufe möglich. Derzeit ist das Formular nur über eID oder itsme® zugänglich. Zugang über eIDAS wird von der nächsten Phase möglich sein.',
    buttons: [
      'Erfahren Sie hier mehr',
      'Mehr Informationen',
      'Start Bewerbungsverfahren*',
      'Erfahren Sie hier mehr',
      'Erfahren Sie hier mehr',
      'Wallonien',
      'Flandern',
      'Brüssel',
      'Erfahren Sie hier mehr',
    ],
    colStepsEndText: 'Sie sind bereit, Geschäfte zu machen!',
    intro: {
      step: {
        descriptions: [
          'Als das Herz Europas ist Belgien der ideale Standort für Ihr Unternehmen.',
          'Nach der Erfüllung einiger spezifischer Verpflichtungen, können Sie mit den Gründungsformalitäten beginnen.',
          'Starten Sie Ihr Unternehmen in Belgien mit minimalen Kosten.',
        ],
        question: 'Wie fängt man an?',
      },
      title: 'Ihr Unternehmen in Belgien',
    },
    links: [
      'https://business.belgium.be/de/ihre_firma_gruenden/gruendung_einer_firma',
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/affinez-votre-projet',
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/la-constitution-dune-societe',
      'https://www.wallonie.be/fr/demarches/demander-un-permis-denvironnement-ou-un-permis-unique-pour-un-etablissement-de-classe-1-ou-2',
      'https://www.vlaanderen.be/omgevingsvergunning',
      'https://urbanisme.irisnet.be/lepermisdurbanisme/autres-premis-et-certificats/le-permis-unique?set_language=fr',
      'https://business.belgium.be/de/ihre_firma_gruenden/mitgliedschaft_bei_einer_sozialversicherungskasse',
      '/search?q=berufliche%20anerkennung&lang=de',
      'https://competencerecognition.belgium.be/app/',
    ],
    texts: [
      <>
        Bevor Sie ein Unternehmen gründen, müssen Sie einige Vorbereitungen
        treffen. So können Sie sich nach der Gründung voll auf die Entwicklung
        Ihres Unternehmens konzentrieren. Diese Vorbereitungen beinhalten:
        <br />
        <br />
        <ul>
          <li>
            Eröffnung eines Geschäftskontos bei einem anerkannten
            Finanzinstitut.
          </li>
          <br />
          <li>
            Auswahl eines Standorts und einer Immobilie für Ihr Unternehmen.
          </li>
          <br />
          <li>Entscheiden Sie sich für den Handelsnamen Ihres Unternehmens.</li>
          <br />
          <li>
            Unter welchem Status möchten Sie Ihren Hauptberuf ausüben? Als
            Selbständiger, als Nebentätigkeit oder als Person, die als
            Nebenberufler behandelt wird?
          </li>
          <br />
          <li>
            Wählen Sie die für Ihr Projekt am besten geeignete Rechtsform:
            Einzelunternehmen (Selbstständig) oder Unternehmen (juristische
            Person).
          </li>
          <br />
          <li>
            Wenn Sie sich für die Gründung einer Gesellschaft entschieden haben,
            müssen Sie noch wählen, welche Art von Gesellschaft Sie gründen
            möchten. Sie müssen auch die Vorregistrierungsverfahren durchführen,
            d.h. einen Finanzplan erstellen und die Satzung Ihres Unternehmens
            entwerfen.
          </li>
        </ul>
      </>,
      <>
        Einige Berufe sind reglementiert. Das bedeutet, dass nur diejenigen, die
        bestimmte Bedingungen erfüllen, eine Anerkennung zur Ausübung dieser
        Berufe erhalten können.
        <br />
        <br />
        Eine solche Anerkennung Ihrer Qualifikationen muss im Voraus beantragt
        werden. Die Regeln dafür sind grundsätzlich unterschiedlich, je nachdem,
        ob Sie sich in Belgien niederlassen oder ob Sie diesen Beruf
        vorübergehend oder gelegentlich in Belgien ausüben möchten. Sie können
        die Anerkennung in Belgien mit einem speziellen Antragsformular
        beantragen.
      </>,
      <>
        In diesem Schritt erledigen Sie die restlichen Startup-Formalitäten. Ihr
        Unternehmen muss bei bestimmten Behörden registriert werden. Darüber
        hinaus ist es wichtig, die gesetzlichen Erlaubnisse oder Genehmigungen
        zu erhalten, die Sie für die Durchführung all Ihrer Aktivitäten
        benötigen.
        <br />
        <br />
        Die Startformalitäten betreffen folgende Elemente:
        <br />
        <br />
        <div className="ContentLinks ContentLinks--col">
          <a
            href="https://economie.fgov.be/de/themen/unternehmen/ein-geschaefft-eroeffnen/schritte-um-ein-unternehmen-zu/beim-unternehmensschalter-zu"
            target="_blank"
            className="Link Links--small"
            rel="noreferrer"
          >
            Ihre Anmeldung bei der Crossroads Bank for Enterprises
          </a>
          <a
            href="https://finanzen.belgium.be/de/unternehmen/mwst/erklarung/beginn-anderung-und-einstellung-der-tatigkeit"
            target="_blank"
            className="Link Links--small"
            rel="noreferrer"
          >
            Ihre Registrierung bei der Umsatzsteuerverwaltung
          </a>
          <a
            href="https://sichinbelgienniederlassen.be/de/geschafte-machen/als-arbeitgeber-in-belgien-registrieren"
            target="_blank"
            className="Link Links--small Links--externalLink"
            rel="noreferrer"
          >
            Ihre eventuelle Registrierung als Arbeitgeber
          </a>
          <Link
            to="/home?lang=de"
            target="_blank"
            className="Link Links--small"
          >
            Ihr Antrag auf relevante Branchen- und Tätigkeitsgenehmigungen
          </Link>
        </div>
      </>,
      <>
        Bevor Sie ein Unternehmen gründen können, müssen Sie zunächst einige
        Dinge in Ordnung bringen. Dies sowohl auf persönlicher Ebene (Studium,
        Ausbildung, ...) als auch für Ihr Unternehmen (Marktforschung,
        Finanzierung, Businessplan, ...):
        <br />
        <br />
        <ul>
          <li>
            Persönliche Voraussetzungen, die erfüllt sein müssen, wie z. B.
            Geschäftsfähigkeit, Alter, Abschluss oder Kenntnis der Materie.
          </li>
          <br />
          <li>
            Zeigen Sie, dass Sie die notwendigen Recherchen durchgeführt haben,
            um die Durchführbarkeit Ihres Projekts nachzuweisen. Ihr
            Businessplan umfasst eine Projektpräsentation, eine Umweltanalyse,
            einen Geschäftsplan und einen Finanzplan.
          </li>
        </ul>
      </>,
      <>
        Wenn Sie die Entscheidung getroffen haben, Ihre geschäftlichen
        Aktivitäten über eine Gesellschaft durchzuführen, müssen Sie eine
        juristische Person gründen.
        <br />
        <br />
        Nach der Durchführung der Vorregistrierungshandlungen müssen Sie dann
        die Gründungsurkunde der juristischen Person aufsetzen. Wenn Sie sich
        für eine N.V., B.V. oder C.V. entscheiden, sind Sie verpflichtet, die
        Dienste eines Notars in Anspruch zu nehmen.
        <br />
        <br />
        Um die Gründung Ihrer juristischen Person abzuschließen, muss die
        Gründungsurkunde bei der Geschäftsstelle des Amtsgerichts des Sitzes
        Ihrer juristischen Person eingereicht werden. Anschließend erhalten Sie
        die Firmennummer Ihrer juristischen Person.
      </>,
      <>
        Je nach den konkreten Aktivitäten und Merkmalen Ihres Unternehmens
        müssen Sie möglicherweise einige Genehmigungen beantragen:
        <br />
        <br />
        <ul>
          <li>
            Sie Können alle Genehmigungen und Anträge im Rahmen von Umwelt-,
            Bodenordnungs- und Raumordnungsverfahren bei den zuständigen
            regionalen Dienststellen beantragen.
          </li>
          <br />
          <li>
            Darüber hinaus benötigen Sie möglicherweise eine Genehmigung, um
            bestimmte Tätigkeiten auszuführen.
          </li>
        </ul>
        <br />
        Finden Sie unten heraus, welche Genehmigung(en) Sie pro Region
        benötigen, um Ihr Unternehmen zu gründen.
      </>,
      <>
        Als Selbständiger, Helfer oder mithelfender Ehepartner sind Sie für Ihre
        soziale Absicherung verantwortlich. Sie müssen sich daher vor Aufnahme
        Ihrer selbständigen Tätigkeit anmelden.
        <br />
        <br />
        Sie zahlen Sozialversicherungsbeiträge an Ihre Krankenkasse, um Anspruch
        auf Familienleistungen, Leistungen bei Krankheit und Invalidität,
        Mutterschaftsversicherung, Ihre Rente und Konkursversicherung zu haben.
        <br />
        <br />
        Darüber hinaus müssen Sie auch eine Krankenversicherung abschließen und
        einer Familienzulagenkasse beitreten.
      </>,
    ],
    text2:
      'Diese reglementierten Berufe und weitere Informationen zum Antrag auf Anerkennung von Qualifikationen finden Sie im Katalog.',
    titles: [
      'Unternehmen Sie die notwendigen vorbereitenden Maßnahmen',
      'Lassen Sie Ihre berufliche Qualifikation anerkennen',
      'Formalitäten bei der Inbetriebnahme',
      'Ordnen Sie Ihre privaten Verpflichtungen',
      'Registrierung der juristischen Person',
      'Einholen der erforderlichen umweltrechtlichen, ökologischen oder städtebaulichen Genehmigungen',
      'Zugehörigkeit zu einer Sozialversicherungskasse',
    ],
  },
  procedure: {
    available: 'Momentan nur für Belgier zugänglich',
    body: 'Möchten Sie den Prozess der Beantragung der Anerkennung Ihrer beruflichen Qualifikation einleiten? Gerne verweise ich Sie auf diesen.',
    buttonStart: 'Einleitung eines Verfahrens',
    disclamer:
      'Um darauf zugreifen zu können, müssen Sie sich identifizieren. Bereiten Sie also Ihren elektronischen Personalausweis oder Ihr Smartphone vor.',
    notFinding: 'Sie konnten nicht finden, wonach Sie gesucht haben?',
    buttonContact: 'Be-Assist kontaktieren',
    beAssistURL:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/conditions-entering/belgian-support-centre',
    contact:
      'Für weitere Informationen können Sie sich jederzeit an die belgischen Hilfsdienste für die Anerkennung von Berufsqualifikationen wenden.',
  },
  result: {
    action: 'Aktion',
    average: 'Durchschnittliche Verarbeitungszeit',
    back: 'Zurück zu den Ergebnissen',
    competent: 'Zuständige Behörde',
    contact: 'Kontaktinformation',
    cost: 'Kost',
    description: 'Beschreibung',
    eligibility: 'Erforderlichen Kriterien',
    fiche: 'Fiche information',
    last: 'Letzte Aktualisierung',
    public: 'Öffentliche Organisation',
    procedure: 'Procedure',
    required: 'Erforderlichen Unterlagen',
    result: 'Ergebnis',
    website: 'Website',
  },
  search: {
    buttonContact: 'Kontaktieren Sie Hilfsdienste',
    all: 'Alle Ergebnisse',
    brussel: 'Brüssel',
    federal: 'Föderal',
    flemish: 'Flandern',
    noResult: {
      button: 'Kontaktieren Sie die belgische Beratungszentren',
      desc1: 'Ich habe keine Ergebnisse gefunden für diese Suche.',
      desc2:
        'Für mehr Informationen, können Sie immer die belgische Beratungszentren kontaktieren.',
      link: 'https://www.belgium.be/de/belgische_hilfs_und_problemloesungsdienste',
      title: 'Keine Ergebnisse',
    },
    title: 'suchergebnisse',
    walloon: 'Wallonien',
  },
  tab: {
    card: {
      address: {
        country: '1000 Brüssel',
        name: 'Belgisches Hilfszentrum',
        road: 'City Atrium, Vooruitgangstraat 50',
      },
      email: {
        email: 'BE_assist@economie.fgov.be',
        label: 'Email',
      },
      fax: {
        label: 'Fax',
        mention: '(gebührenfreie Nummer)',
      },
      online: 'Online form (FR, NL, DE, EN)',
      phone: {
        label: 'Phone',
        mention: '(gebührenfreie Nummer)',
      },
    },
    feedback: {
      buttons: [
        'Helfen Sie uns zu verbessern',
        'Ein Hindernis melden',
        'Kontaktieren Sie Hilfsdienste',
      ],
      link: [
        'https://foq.youreurope.europa.eu/info-service-ft?lang=de',
        'https://fosmo.youreurope.europa.eu/',
        'https://www.belgium.be/de/belgische_hilfs_und_problemloesungsdienste',
      ]
    },
    resultCount: ['Anzeige von ', ' von ', ' Ergebnissen'],
  },
  thumbs: {
    popup:
      'Vielen Dank für Ihr Feedback! Ich lerne jeden Tag dazu, um Ihnen in Zukunft relevantere Artikel zu zeigen und Ihnen beim nächsten Mal besser helfen zu können!',
  },
};
