export default {
    main: {
        buttonStartAgain: 'Commencer à nouveau',
        buttonNext: 'Suivant',
        buttontSkip: 'Passer',
        buttonPrevious: 'Précédent',
        stepperStep1Text: 'Emplacement de l\'entreprise',
        stepperStep2Text: 'Codes NACE',
        stepperStep3Text: 'Autres suggestions',
        wizardTableTitle: 'Titre',
        wizardTableTooltipPermit: 'Permis requis',
        wizardTableTooltipQualification: 'Qualification professionnelle requise',
        wizardTableTooltipAssociation: 'Affiliation requise',
    },
    lucien: {
        step1Title: 'Pourquoi l\'emplacement est- il important?',
        step1Text1: 'Selon l\'endroit où vous enregistrez votre entreprise, les permis peuvent s\'appliquer différemment et des qualifications professionnelles peuvent être requises.',
        step1Text2: 'Par exemple, un boucher en Wallonie doit certifier ses compétences, tandis que ce n\'est pas nécessaire en Flandre.',
        step2Title: 'Quelles sont les implications des codes NACE?',
        step2Text1: 'Une fois enregistrés, les codes NACE définissent les activités économiques que votre entreprise est officiellement autorisée à exercer.',
        step2Text2: 'Ici, vous pouvez les utiliser et voir les implications supplémentaires de chaque code NACE, telles que la nécessité d\'un permis requis ou d\'une qualification professionnelle.',
        step3LoadingTitle: 'Il y a plus d\'un million d\'entreprises enregistrées en Belgique',
        step3LoadingText1Part1: 'En Belgique, les entreprises doivent être officiellement enregistrées dans ',
        step3LoadingText1Part2: 'la Banque-Carrefour des Entreprises',
        step3LoadingText1Part3: ', la principale base de données où vous trouverez toutes les entreprises existantes.',
        step3Title: 'Soyez précis dans vos choix',
        step3Text1: 'Une fois l\'enregistrement officiel effectué, nous vous conseillons de choisir uniquement les codes NACE qui représentent véritablement ce que vous ferez dans votre entreprise au cours des prochaines années.',
        step3Text2: 'Si votre entreprise change plus tard, vous pourrez toujours ajouter ou supprimer des codes NACE gratuitement.',
    },
    step1: {
        title: 'Indiquez l\'emplacement de votre entreprise',
        question1Title: 'Où sera basée votre entreprise?',
        question1Flanders: 'Flandre',
        question1Brussels: 'Bruxelles',
        question1Wallonia: 'Wallonie',
        question2Title: 'Dans quelle commune?',
        question2Placeholder: 'Sélectionnez une commune'
    },
    step2: {
        title: 'Trouvez les codes NACE qui correspondent le mieux aux activités de votre entreprise',
        errorMessageText: 'Nous sommes désolés, nous n\'avons trouvé aucun résultat pour ',
        errorMessageListItem1: 'Vérifiez votre recherche pour toute faute de frappe ou erreur d\'orthographe',
        errorMessageListItem2: 'Essayez d\'utiliser des mots différents',
    },
    step3: {
        title: 'Inspirez-vous d\'autres entreprises existantes',
        text: 'Voici des codes NACE suggérés provenant d\'entreprises similaires existantes qui pourraient s\'appliquer à la vôtre',
        loadingTitle

            : 'Juste un instant...',
        loadingText: 'Je parcours toutes les entreprises belges pour vous suggérer d\'autres codes NACE qui pourraient vous intéresser...',
    },
    step4: {
        title: 'Super! Sur la base de vos choix, voici ce que vous devriez faire:',
        stepperTitleStep1: 'Code NACE',
        stepperTitleStep2: 'Permis',
        step1Title: 'Enregistrez ces codes NACE pour votre entreprise',
        step1Text1Part1: "Lorsque vous devez signer votre acte de constitution chez le notaire (SA, SPRL et SCRL), vous devez ",
        step1Text1Part2: 'demander au notaire d\'inclure ces activités',
        step1Text1Part3: 'dans la définition officielle de votre entreprise.',
        step1Text2Part1: "Si vous devenez indépendant (personne physique), vous devrez ",
        step1Text2Part2: 'déclarer ces activités',
        step1Text2Part3: 'à votre guichet d\'entreprise agréé.',
        step1NaceOverviewTitle: 'Vos codes NACE pour l\'enregistrement',
        step1NaceOverviewButtonText: 'Voir la liste complète',
        step1SidePanelTitle: 'Vos codes NACE pour l\'enregistrement',
        step2Title: 'Demandez les permis nécessaires',
        step2Text1: 'Certains des codes NACE sélectionnés ont des implications. Par exemple, les permis que vous devrez demander et autres exigences.',
        step2Text2: 'Consultez les instructions détaillées sur la marche à suivre.',
        step2UsefulInfoButton: 'Informations',
        saveMyResult: 'Enregistrer mon résultat',
        buttonEmailResult: 'Envoyer le résultat par e-mail',
        buttonDownloadResult: 'Télécharger le résultat',
        step2NoPermits: 'Aucun permis requis pour ces activités.'
    },
    modal: {
        startAgain: 'Recommencer',
        title: 'Recommencer le questionnaire de l\'assistant?',
        text: 'Votre progression ne sera pas enregistrée et vos réponses seront perdues.',
        cancel: 'Annuler',
    }
};
