import React, { useState } from 'react';
import { Accordion } from '../common/accordion/accordion';
import { SidePanel } from '../common/side-panel/side-panel';
import CardComponent from './cardComponent';

interface StepComponentProps {
  id: string;
  step: any;
  stepNumber: number;
  headerContent: any;
  showChip: boolean;
}

const StepComponent: React.FC<StepComponentProps> = (
  props: StepComponentProps
) => {
  const { step, stepNumber, id, headerContent, showChip } = props;

  const [isSidePanelStep, setIsSidePanelStep] = useState<boolean>(false);

  const closeSidePanelOfGivenStep = (
    setSidePanelToCloseState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setSidePanelToCloseState(false);
  };

  const parsedHtml = (htmlString: string) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');
    const parsedHtml = html.body.innerHTML;

    if (parsedHtml.startsWith('<dl')) {
      const parser2 = new DOMParser();
      const html2 = parser2.parseFromString(htmlString, 'text/html');
      const parsedHtml2 = html2.body.innerHTML;

      const dtPattern = /<dt>(.*?)<\/dt>/g;
      const ddPattern = /<dd>(.*?)<\/dd>/g;

      // Extract the titles and contents from <dt> and <dd> tags
      const dtMatches = parsedHtml2.match(dtPattern);
      const ddMatches = parsedHtml2.match(ddPattern);

      // Generate the Accordion components with their respective titles and contents
      const components =
        dtMatches &&
        dtMatches.map((dtMatch, index) => {
          const title = dtMatch.replace(dtPattern, '$1');
          const content =
            (ddMatches && ddMatches[index].replace(ddPattern, '$1')) || '';

          return (
            <div key={index} className="sub-accordeon-container">
              <Accordion key={index} title={title}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </Accordion>
            </div>
          );
        });
      return components;
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: parsedHtml,
          }}
        />
      );
    }
  };

  const removeWizardForProduction = (): boolean => {
    const wizardStepsToRemove = ['f3a291fc-98bb-4475-9b42-9f316bd1819c'];
    return wizardStepsToRemove.some(item => item === id) ? false : true;
  };

  return (
    <>
      <CardComponent
        id={id}
        stepNumber={stepNumber}
        icon={step?.field_icon_select}
        showChip={showChip}
        cardTitle={step !== undefined && step?.title}
        altImage={''}
        externalLinkText={step !== undefined && step?.field_link[0]?.title}
        externalLinkUrl={step !== undefined && step?.field_link[0]?.uri}
        cardText={step !== undefined && step?.field_intro?.value}
        onButtonClick={() =>
          setIsSidePanelStep((isSidePanelStep) => !isSidePanelStep)
        }
        buttonText={headerContent?.field_read_more_label}
        hasToolSection={
          step !== undefined &&
          ((step?.field_tool?.field_tool_name ||
            step?.field_tool?.field_tool_presentation?.value) &&
            removeWizardForProduction()
          )
        }
        toolTitle={step !== undefined && step?.field_tool?.field_tool_name}
        toolText={
          step !== undefined && step?.field_tool?.field_tool_presentation?.value
        }
        toolButtonText={
          step !== undefined && step?.field_tool?.field_tool_link?.title
        }
        toolButtonUrl={step?.field_tool?.field_tool_link?.uri}
        hasLink={step !== undefined && step?.field_tool?.field_tool_link}
      />
      <SidePanel
        title={`step ${stepNumber}`}
        isOpen={isSidePanelStep}
        closeSidePanel={closeSidePanelOfGivenStep}
        setState={setIsSidePanelStep}
      >
        <>
          <div className="side-panel__title">
            {step !== undefined && step?.title}
          </div>
          <div className="side-panel__content">
            <div
              className="side-panel__text"
              dangerouslySetInnerHTML={{
                __html:
                  step !== undefined &&
                  step?.field_step_details?.field_details_section_intro?.value,
              }}
            />
            <div className="side-panel__accordeon-container">
              {step !== undefined &&
                step?.field_step_details?.field_details_section_tabs.length &&
                step.field_step_details.field_details_section_tabs.map(
                  (tab: any, key: number) => (
                    <Accordion key={key} title={tab?.field_detail_title}>
                      {tab?.field_detail_body?.value &&
                        parsedHtml(tab.field_detail_body.value)}
                    </Accordion>
                  )
                )}
              {step !== undefined &&
                step?.field_step_details?.field_details_section_link !==
                null && (
                  <>
                    <br />
                    <p>
                      <a
                        href={
                          step?.field_step_details
                            ?.field_details_section_link[0]?.uri
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {
                          step?.field_step_details
                            ?.field_details_section_link[0]?.title
                        }
                      </a>
                    </p>
                  </>
                )}
            </div>
          </div>
        </>
      </SidePanel>
    </>
  );
};

export default StepComponent;
