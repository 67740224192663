import {
  GET_ERRORS,
  GET_RESULTS,
  RESULTS_LOADING,
} from '../../constants/types';
import { Action } from '../../constants/interfaces';

const initialState = {
  data: [],
  loading: false,
  matchingResults: 0,
};

export default function (state = initialState, action: Action) {
  const { payload, type } = action;

  switch (type) {
    case GET_RESULTS:
      return {
        ...state,
        data: payload?.results ? payload?.results : initialState?.data,
        loading: false,
        matchingResults: payload?.matching_results,
      };
    case RESULTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
