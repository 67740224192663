import React, { useEffect, useState } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

interface CheckboxItemComponentProps {
  id: string;
  title: string;
  setCurrCheckedType: any;
  currChecked: string;
}

const CheckboxItemComponent: React.FC<CheckboxItemComponentProps> = (
  props: CheckboxItemComponentProps
) => {
  const { id, title, setCurrCheckedType, currChecked } = props;

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const isLabelActive = (isActive: boolean): string => {
    return isActive ? 'label-active' : '';
  };

  const onCheckedChange = () => {
    if (!isChecked) {
      setCurrCheckedType(id);
      setIsChecked(true);
    } else {
      setCurrCheckedType(null);
      setIsChecked(false);
    }
  };

  useEffect(() => {
    if (currChecked !== id) {
      setIsChecked(false);
    }
  }, [currChecked]);

  return (
    <>
      <label className={`${isLabelActive(isChecked)} `} htmlFor={id}>
        <Checkbox.Root
          checked={isChecked}
          className="checkbox-root"
          id={id}
          onCheckedChange={() => onCheckedChange()}
        >
          <Checkbox.Indicator className="checkbox-indicator">
            <CheckIcon />
          </Checkbox.Indicator>
        </Checkbox.Root>
        {title}
      </label>
    </>
  );
};

export default CheckboxItemComponent;
