import React, { useEffect } from 'react';

import icon_cross from '../../../assets/images/Icon=cross.png';

import './side-panel.scss';
import '../side-panel/side-panel.scss';

interface sidePanelProps {
  children: React.ReactChild;
  isOpen: boolean;
  title: string;
  isSuperiorSidePanel?: boolean;
  closeSidePanel: (
    setState: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SidePanel = ({
  children,
  isOpen,
  title,
  closeSidePanel,
  setState,
  isSuperiorSidePanel,
}: sidePanelProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('scroll-disabled');
    } else {
      document.body.classList.remove('scroll-disabled');
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className={`backdrop ${isSuperiorSidePanel ? 'backdrop-superior' : ''
            }`}
          onClick={() => closeSidePanel(setState)}
        ></div>
      )}
      <div
        className={`side-panel ${isOpen ? 'side-panel-move-in' : ''} ${isSuperiorSidePanel ? 'side-panel-superior' : ''
          }`}
      >
        <div className="side-panel__header">
          {title !== '' ? (
            <span className="secondary-color text-uppercase">
              <span className="primary-color font-weight-900">/ </span>
              {title}
            </span>
          ) : (
            <span></span>
          )}
          <img
            onClick={() => closeSidePanel(setState)}
            src={icon_cross}
            alt="icon cross"
          />
        </div>
        {children}
      </div>
    </>
  );
};
