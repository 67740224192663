export default {
    main: {
        buttonStartAgain: 'Neustarten',
        buttonNext: 'Weiter',
        buttontSkip: 'Überspringen',
        buttonPrevious: 'Zurück',
        stepperStep1Text: 'Standort des Unternehmens',
        stepperStep2Text: 'NACE-Codes',
        stepperStep3Text: 'Andere Vorschläge',
        wizardTableTitle: 'Titel',
        wizardTableTooltipPermit: 'Erforderliche Genehmigung',
        wizardTableTooltipQualification: 'Berufsqualifikation erforderlich',
        wizardTableTooltipAssociation: 'Erforderliche Zugehörigkeit',
    },
    lucien: {
        step1Title: 'Warum ist der Standort wichtig?',
        step1Text1: 'Je nachdem, wo Sie Ihr Unternehmen registrieren, können unterschiedliche Genehmigungen gelten und möglicherweise professionelle Qualifikationen erforderlich sein.',
        step1Text2: 'Beispielsweise muss ein Metzger in Wallonien seine Fähigkeiten zertifizieren lassen, während dies in Flandern nicht erforderlich ist.',
        step2Title: 'Welche Auswirkungen haben NACE-Codes?',
        step2Text1: 'Nach der Registrierung legen NACE-Codes die wirtschaftlichen Aktivitäten fest, die Ihr Unternehmen offiziell ausüben darf.',
        step2Text2: 'Hier können Sie damit experimentieren und weitere Auswirkungen jeder NACE-Code sehen, wie zum Beispiel die Notwendigkeit einer erforderlichen Genehmigung oder beruflichen Qualifikation.',
        step3LoadingTitle: 'In Belgien sind mehr als 1 Million Unternehmen registriert',
        step3LoadingText1Part1: 'In Belgien müssen Unternehmen offiziell bei der ',
        step3LoadingText1Part2: 'Kreuzpunktbank der Unternehmen',
        step3LoadingText1Part3: ' registriert sein, der wichtigsten Datenbank, in der Sie alle bestehenden Unternehmen finden.',
        step3Title: 'Seien Sie genau bei Ihren Entscheidungen',
        step3Text1: 'Nach der offiziellen Registrierung empfehlen wir Ihnen, nur die NACE-Codes auszuwählen, die tatsächlich repräsentieren, was Sie in den kommenden Jahren in Ihrem Unternehmen tun werden.',
        step3Text2: 'Wenn sich Ihr Unternehmen später ändert, können Sie weiterhin kostenlos NACE-Codes hinzufügen oder entfernen.',
    },
    step1: {
        title: 'Geben Sie den Standort Ihres Unternehmens an',
        question1Title: 'Wo wird Ihr Unternehmen ansässig sein?',
        question1Flanders: 'Flandern',
        question1Brussels: 'Brüssel',
        question1Wallonia: 'Wallonien',
        question2Title: 'In welcher Gemeinde?',
        question2Placeholder: 'Gemeinde auswählen'
    },
    step2: {
        title: 'Finden Sie die NACE-Codes, die eng mit den Aktivitäten Ihres Unternehmens übereinstimmen',
        errorMessageText: 'Entschuldigung, wir konnten keine Ergebnisse finden für ',
        errorMessageListItem1: 'Überprüfen Sie Ihre Suche auf Tippfehler oder Rechtschreibfehler',
        errorMessageListItem2: 'Versuchen Sie, andere Wörter zu verwenden',
    },
    step3: {
        title: 'Lassen Sie sich von anderen bestehenden Unternehmen inspirieren',
        text: 'Nachfolgend werden vorgeschlagene NACE-Codes von ähnlichen bestehenden Unternehmen aufgeführt, die für Ihr Unternehmen relevant sein könnten',
        loadingTitle: 'Einen Moment bitte...',
        loadingText: 'Ich durchsuche alle belgischen Unternehmen, um weitere NACE-Codes vorzuschlagen, die für Sie interessant sein könnten...',
    },
    step4: {
        title: 'Großartig! Basierend auf Ihren Entscheidungen, hier ist, was Sie tun sollten: ',
        stepperTitleStep1: 'NACE-Code',
        stepperTitleStep2: 'Genehmigung',
        step1Title: 'Registrieren Sie diese NACE-Codes für Ihr Unternehmen',
        step1Text1Part1: 'Wenn Sie Ihre Gründungsurkunde beim Notar unterzeichnen (BV, NV und CV), müssen Sie ',
        step1Text1Part2: 'den Notar bitten, diese Tätigkeiten',
        step1Text1Part3: ' in die offizielle Definition Ihres Unternehmens aufzunehmen.',
        step1Text2Part1: 'Wenn Sie selbstständig werden (Einzelunternehmen), müssen Sie ',
        step1Text2Part2: 'diese Aktivitäten melden',
        step1Text2Part3: 'bei Ihrem anerkannten Unternehmensschalter.',
        step1NaceOverviewTitle: 'Ihre NACE-Codes zur Registrierung',
        step1NaceOverviewButtonText: 'Vollständige Liste anzeigen',
        step1SidePanelTitle: 'Ihre NACE-Codes zur Registrierung',
        step2Title: 'Beantragen Sie die erforderlichen Genehmigungen',
        step2Text1: 'Einige der ausgewählten NACE-Codes haben Auswirkungen, wie zum Beispiel die erforderlichen Genehmigungen und andere Anforderungen.',
        step2Text2: 'Lesen Sie weiter Anweisungen, wie Sie vorgehen sollen.',
        step2UsefulInfoButton: 'Informationen',
        saveMyResult: 'Ergebnis speichern',
        buttonEmailResult: 'Ergebnis per E-Mail senden',
        buttonDownloadResult: 'Ergebnis herunterladen',
        step2NoPermits: 'Keine Genehmigungen für diese Aktivitäten erforderlich.'
    },
    modal: {
        startAgain: 'Neustart',
        title: 'Den Assistenten-Fragebogen erneut starten?',
        text: 'Ihr Fortschritt wird nicht gespeichert und Ihre Antworten gehen verloren.',
        cancel: 'Abbrechen',
    }
};
