import {
    CLOSE_SIDE_PANEL,
    OPEN_SIDE_PANEL,
} from '../../constants/types';
import { wizardDataModelDTO } from '../../models/wizardDataModelDTO';

const initialState: payloadType = {
    isSidePanelOverviewOpen: false,
    sidePanelOverviewData: null,
};
type payloadType = {
    isSidePanelOverviewOpen: boolean,
    sidePanelOverviewData: wizardDataModelDTO | null
}

type SidePanelDispatchType = {
    payload: payloadType;
    type: string;
};

export default (
    state: payloadType = initialState,
    action: SidePanelDispatchType
): payloadType => {
    const { payload, type } = action;

    switch (type) {
        case CLOSE_SIDE_PANEL:
            return {
                sidePanelOverviewData: null,
                isSidePanelOverviewOpen: false,
            };
        case OPEN_SIDE_PANEL:
            return {
                sidePanelOverviewData: payload.sidePanelOverviewData,
                isSidePanelOverviewOpen: true,
            };
        default:
            return state;
    }
};
