/* eslint-disable prettier/prettier */

import React from 'react';
import { Link } from 'react-router-dom';

export default {
  footer: {
    service: 'Belgische Federale overheidsdienst ',
    lastUpdated: 'Laatst bijgewerkt op: ',
    contactButtonTextImprove: 'Help ons verbeteren',
    contactButtonTextReport: 'Meld een obstakel',
    contactButtonTextContact: 'Neem contact op met de ondersteunende diensten',
    contactButtonTitle: 'Wilt u contact met ons opnemen?',
  },
  header: {
    example:
      'Bijvoorbeeld: beroepskwalificatie, vergunning, technische voorschriften, ...',
    language: 'Taal',
    placeholder: 'Ik ben op zoek naar…',
    search: 'Zoeken',
    title: 'Hoe kan ik u helpen ?',
  },
  landing: {
    asterix:
      '* Een aanvraag tot erkenning via het centrale aanvraagformulier is nog niet mogelijk voor alle gereglementeerde beroepen. Het formulier is voorlopig ook enkel toegankelijk via eID of itsme®. Toegang via eIDAS zal vanaf een volgende fase mogelijk zijn.',
    buttons: [
      'Ontdek hier verder',
      'Meer informatie',
      'Start aanvraagprocedure*',
      'Ontdek hier verder',
      'Ontdek hier verder',
      'Wallonië',
      'Vlaanderen',
      'Brussel',
      'Ontdek hier verder',
    ],
    colStepsEndText: 'Je bent klaar om te ondernemen!',
    intro: {
      step: {
        descriptions: [
          'België is als hart van Europa de ideale ligging voor jouw onderneming.',
          'Na aan enkele specifieke verplichtingen te hebben voldaan, maken we het starten makkelijk met een aantal startersformaliteiten.',
          'Je bedrijf in België opstarten met minimale kosten.',
        ],
        question: 'Hoe begin je eraan?',
      },
      title: 'Jouw onderneming starten in België',
    },
    links: [
      'https://business.belgium.be/nl/uw_bedrijf_oprichten/oprichting_van_een_vennootschap',
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/werk-uw-ondernemingsproject',
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/een-vennootschap-met',
      'https://www.wallonie.be/fr/demarches/demander-un-permis-denvironnement-ou-un-permis-unique-pour-un-etablissement-de-classe-1-ou-2',
      'https://www.vlaanderen.be/omgevingsvergunning',
      'https://stedenbouw.irisnet.be/vergunning/andere-vergunningen-en-attesten/unieke-vergunning?set_language=nl',
      'https://business.belgium.be/nl/uw_bedrijf_oprichten/aansluiting_bij_een_sociale_verzekeringskas',
      '/search?q=beroepskwalificatie%20als&lang=nl',
      'https://competencerecognition.belgium.be/app/',
    ],
    texts: [
      <>
        Vooraleer je een onderneming opricht, moet je enkele voorbereidingen
        treffen. Hierdoor kan je je na de oprichting volledig op de uitbouw van
        uw onderneming concentreren. Deze voorbereidingen omvatten onder meer:
        <br />
        <br />
        <ul>
          <li>
            Openen van een professionele bankrekening bij een erkende financiële
            instelling.
          </li>
          <br />
          <li>Kiezen van locatie en pand voor jouw onderneming.</li>
          <br />
          <li>Beslissen over de handelsnaam van jouw onderneming.</li>
          <br />
          <li>
            Onder welk statuut? Wil je werken als zelfstandige in hoofdberoep,
            in bijberoep of als gelijkgesteld met bijberoep?
          </li>
          <br />
          <li>Kiezen voor een vennootschap of een éénmanszaak?</li>
          <br />
          <li>
            Indien je kiest voor een vennootschap zal je daarnaast ook nog de
            nodige pre-registratiehandelingen moeten verrichten. Dit omvat onder
            meer het opstellen van een financieel plan en ontwerpstatuten.
          </li>
        </ul>
      </>,
      <>
        Sommige beroepen zijn gereglementeerd. Dit betekent dat enkel wie aan
        bepaalde voorwaarden voldoet, een erkenning kan krijgen om die beroepen
        uit te oefenen.
        <br />
        <br />
        Een dergelijke erkenning van jouw kwalificaties moet vooraf worden
        aangevraagd. De regels hiervoor verschillen in principe naargelang je je
        in België vestigt of je dit beroep tijdelijk of incidenteel in België
        wilt uitoefenen. De aanvraag tot erkenning kan in België via een uniek
        initiërend formulier.
      </>,
      <>
        In deze stap werk je de resterende startersformaliteiten af. Je
        onderneming zal moet worden geregistreerd bij bepaalde instanties.
        Daarnaast is het van belang om de wettelijke vergunningen of
        autorisaties te verkrijgen die nodig zijn om al jouw activiteiten uit te
        oefenen.
        <br />
        <br />
        De startersformaliteiten betreffen de volgende elementen:
        <br />
        <br />
        <div className="ContentLinks ContentLinks--col">
          <a
            href="https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/belangrijkste-stappen-om-een/te-ondernemen-stappen-bij-een"
            target="_blank"
            className="Link Links--small"
            rel="noreferrer"
          >
            Je inschrijving bij de Kruispuntbank voor Ondernemingen
          </a>
          <a
            href="https://financien.belgium.be/nl/ondernemingen/btw/aangifte/aanvang-wijziging-einde-activiteit"
            target="_blank"
            className="Link Links--small"
            rel="noreferrer"
          >
            Je inschrijving bij de BTW administratie
          </a>
          <a
            href="https://zichvestigeninbelgie.be/nl/zakendoen/registreren-als-werkgever"
            target="_blank"
            className="Link Links--small Links--externalLink"
            rel="noreferrer"
          >
            Je eventuele inschrijving als werkgever
          </a>
          <Link
            to="/home?lang=nl"
            target="_blank"
            className="Link Links--small"
          >
            Je aanvraag van relevante sectoriële en activiteitsvergunningen
          </Link>
        </div>
      </>,
      <>
        Vooraleer je een bedrijf kan opstarten moet je eerst enkele zaken in
        orde brengen. Dit zowel op persoonlijk vlak (studie, vorming, ...) als
        voor jouw onderneming (marktonderzoek, financiering, businessplan,...):
        <br />
        <br />
        <ul>
          <li>
            Persoonsgebonden voorwaarden die vervuld moeten zijn, zoals
            handelingsbekwaam zijn, leeftijd, behaald diploma of kennis van
            zake.
          </li>
          <br />
          <li>
            Aantonen dat je het nodige onderzoek hebt gedaan waaruit de
            haalbaarheid van je project blijkt. Jouw ondernemingsplan bestaat
            onder meer uit een projectvoorstelling, een omgevingsanalyse,
            commercieel plan en financieel plan.
          </li>
        </ul>
      </>,
      <>
        Wanneer je de beslissing hebt genomen om je ondernemingsactiviteiten via
        een vennootschap uit te voeren, dien je een rechtspersoon op te richten.
        <br />
        <br />
        Na het uitvoeren van de pre-registratiehandelingen zal je vervolgens de
        oprichtingsakte van de rechtspersoon moeten opstellen. Indien je kiest
        voor een N.V., B.V. of C.V. moet je hiervoor verplicht een notaris
        inschakelen.
        <br />
        <br />
        Om de creatie van jouw rechtspersoon te voltooien, moet de
        oprichtingsakte worden neergelegd bij de griffie van de
        ondernemingsrechtbank van de zetel van jouw rechtspersoon. Nadien zal je
        het ondernemingsnummer van jouw rechtspersoon ontvangen.
      </>,
      <>
        Naargelang de concrete activiteiten en kenmerken van jouw onderneming
        kan je verplicht zijn enkele vergunningen aan te vragen:
        <br />
        <br />
        <ul>
          <li>
            Alle vergunningen en aanvragen in het kader van milieu-,
            verkavelings- en ruimtelijke ordeningsprocedures zal je kunnen
            aanvragen bij de bevoegde regionale diensten.
          </li>
          <br />
          <li>
            Daarnaast kan je ook een vergunning nodig hebben om bepaalde
            activiteiten uit te oefenen.
          </li>
        </ul>
        <br />
        Ontdek hieronder welke vergunning(en) per Gewest dat je moet hebben voor
        het starten van jouw onderneming.
      </>,
      <>
        Als zelfstandige, helper of meewerkende echtgeno(o)t(e) ben je zelf
        verantwoordelijk om jouw sociale dekking in orde te brengen. Je dient je
        dan ook aan te sluiten voor de start van jouw zelfstandige activiteit.
        <br />
        <br />
        Je betaalt sociale bijdragen aan jouw verzekeringsfonds om recht te
        krijgen op gezinsbijslagen, ziekte- en invaliditeits-uitkeringen, de
        moederschaps-verzekering, jouw pensioen en de faillissementsverzekering.
        <br />
        <br />
        Daarnaast zal je ook een ziekteverzekering moeten afsluiten en je moeten
        aansluiten bij een kinderbijslagfonds.
      </>,
    ],
    text2:
      'Deze gereglementeerde beroepen en meer informatie over de aanvraag tot de erkenning van kwalificaties vind je in de catalogus.',
    titles: [
      'Tref de nodige voorbereidende handelingen',
      'Laat je beroepskwalificatie erkennen',
      'Startersformaliteiten',
      'Regel je privé verplichtingen',
      'Registratie van de rechtspersoon',
      'Bekom de nodige milieu-, omgevings- of stedenbouwkundige vergunningen',
      'Aansluiting bij een sociaal verzekeringsfonds',
    ],
  },
  procedure: {
    available: 'Voorlopig enkel toegankelijk voor Belgen',
    body: 'Ben je eerder op zoek naar hoe je de aanvraagprocedure voor jouw beroepskwalificatie in gang zet? Ik stuur je met plezier door naar de aanvraagprocedure.',
    buttonStart: 'Start aanvraagprocedure',
    disclamer:
      'Om toegang te krijgen, zal je je moeten aanmelden. Hou alvast je eID of smartphone bij de hand.',
    notFinding: 'Je kon niet vinden wat je zocht?',
    buttonContact: 'Contacteer Be-Assist',
    beAssistURL:
      'https://economie.fgov.be/nl/themas/ondernemingen/een-onderneming-oprichten/toegang-tot-het-beroep/belgisch-assistentiecentrum',
    contact:
      'U kunt altijd contact opnemen met de Belgische hulpdiensten voor de erkenning van beroepskwalificaties voor meer informatie.',
  },
  result: {
    action: 'Actie',
    average: 'Gemiddelde verwerkingstijd',
    back: 'Ga terug naar resultaten',
    competent: 'Bevoegde autoriteit',
    contact: 'Contactinformatie',
    cost: 'Kost',
    description: 'Beschrijving',
    eligibility: 'Vereiste criteria',
    fiche: 'Fiche informatie',
    last: 'Laatst bijgewerkt',
    public: 'Publieke organisatie',
    procedure: 'Procedure',
    required: 'Benodigde documenten',
    result: 'Resultaat',
    website: 'Website',
  },
  search: {
    buttonContact: 'Contacteer assistentie services',
    all: 'Alle resultaten',
    brussel: 'Brussel',
    federal: 'Federaal',
    flemish: 'Vlaanderen',
    noResult: {
      button: 'Contacteer de Belgische assistentie diensten',
      desc1: 'Ik heb geen resultaten gevonden voor deze zoekopdracht.',
      desc2:
        'Je kan voor meer informatie terecht bij de Belgische assistentie diensten.',
      link: 'https://www.belgium.be/nl/diensten_voor_ondersteuning_en_probleemoplossing',
      title: 'Geen zoekresultaat',
    },
    title: 'zoekresultaten',
    walloon: 'Wallonië',
  },
  tab: {
    card: {
      address: {
        country: '1000 Brussel',
        name: 'Belgisch bijstandscentrum',
        road: 'City Atrium, Vooruitgangstraat 50',
      },
      email: {
        email: 'BE_assist@economie.fgov.be',
        label: 'Email',
      },
      fax: {
        label: 'Fax',
        mention: '(gratis telefoonnummer)',
      },
      online: 'Online formulier (FR, NL, DE, EN)',
      phone: {
        label: 'Phone',
        mention: '(gratis telefoonnummer)',
      },
    },
    feedback: {
      buttons: [
        'Help ons te verbeteren',
        'Een obstakel melden',
        'Contacteer assistentie services',
      ],
      link: [
        'https://foq.youreurope.europa.eu/info-service-ft?lang=nl',
        'https://fosmo.youreurope.europa.eu/',
        'https://www.belgium.be/nl/diensten_voor_ondersteuning_en_probleemoplossing',
      ]
    },
    resultCount: ['Weergave van ', ' resultaat op ', ''],
  },
  thumbs: {
    popup:
      'Bedankt voor uw feedback! Ik leer elke dag bij, om u in de toekomst relevantere artikelen te tonen en u de volgende keer beter te helpen!',
  },
};
