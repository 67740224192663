/* eslint-disable */

export default {
  header: {
    title: 'Informations officielles et services',
  },
  page: {
    title: 'Votre parcours pour créer une entreprise en Belgique',
    subtitle: 'Suivez les étapes et créez votre entreprise !',
    buttonContainerTitle: "Quel type d'entreprise allez-vous créer ?",
    buttonNvBcCv: 'SA, SPRL, SCRL',
    buttonVofCommV: 'SNC, SC',
    buttonSoleProprietor: 'Entreprise individuelle',
    buttonNonProfit: 'Organisations à but non lucratif',
    buttonContainerTextBelowPart1: 'Vous ne savez pas encore ?',
    buttonContainerTextBelowPart2:
      'Explorez les différentes structures juridiques possibles',
    listItemTitle1:
      'Vérifiez si vous remplissez les conditions pour créer une entreprise en Belgique',
    listItemTitle2:
      "Une fois que vous avez défini le type d'entreprise que vous souhaitez créer, nous vous indiquerons les étapes obligatoires",
    itemTitle1: 'Vérifiez les conditions pour créer une entreprise en Belgique',
    itemTitle2: 'Évaluez votre point de départ',
    itemTitle3: 'Choisissez une structure juridique pour votre entreprise',
    itemTitle4: 'Ouvrez un compte bancaire professionnel',
    itemTitle5: "Choisissez l'adresse de votre entreprise",
    itemTitle6: 'Choisissez le nom de votre entreprise',
    itemTitle7: "Élaborez un plan d'affaires",
    itemTitle8: 'Définissez les activités économiques de votre entreprise',
    itemTitle9: 'Faites reconnaître votre qualification professionnelle',
    itemTitle10:
      'Inscrivez votre entreprise à la Banque-Carrefour des Entreprises',
    itemTitle11:
      "Inscrivez votre entreprise au bureau de l'administration fiscale",
    itemTitle12: 'Demandez les autorisations nécessaires',
    itemTitle13: "Affiliez-vous à un fonds d'assurance sociale",
    itemTitle14: "Inscrivez-vous en tant qu'employeur",
    buttonReadMore: 'Lire la suite',
    findMoreServiceText: 'Trouver des prestataires de services',
    registerNameSectionStep1: 'Choisissez un nom pour votre entreprise',
    registerNameSectionStep2Part1: 'Vérifiez si le',
    registerNameSectionStep2Part2: 'nom est encore disponible',
    registerNameSectionStep3: "Enregistrez le nom de l'entreprise",
    toolCardTitle:
      'Outil de choix des activités NACE-BEL pour définir votre entreprise',
    toolCardText:
      "Utilisez cet outil en ligne pour découvrir et sélectionner les bonnes activités pour votre entreprise et découvrir si vous avez besoin d'autorisations.",
    toolCardButtonText: "Lancer l'outil",
    moreInfoBeAssistTitle: "Plus d'informations",
    moreInfoBeAssistText:
      "Contactez le centre de contact du gouvernement belge pour obtenir plus d'informations sur la reconnaissance de votre qualification professionnelle",
    moreInfoBeAssistContact: 'Contact',
    mandatoryText: 'Obligatoire',
    optionalText: 'Optionnel',
    step1Text1:
      'Pour exercer une activité indépendante, vous devez remplir certaines conditions préalables concernant :',
    step1ListItem1: 'Les exigences légales individuelles',
    step1ListItem2:
      "La nationalité et l'exercice d'une profession spécifique en Belgique",
    step1ListItem3:
      'Les activités que vous souhaitez exercer dans votre entreprise',
    step1ExternalLinkText: 'Conditions pour exercer une activité indépendante',
    step1ExternalLinkUrl:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/les-conditions-dacces-la',
    step1SidePanelTitle:
      'Vérifiez les conditions pour créer une entreprise en Belgique',
    step1SidePanelText:
      'Avant de tout mettre en place, il existe certaines exigences légales pour exercer une activité indépendante :',
    step1SidePanelDropdown1Title1: "Conditions relatives à l'individu",
    step1SidePanelDropwdown1ListItem1:
      'vous devez avoir la majorité légale de 18 ans ',
    step1SidePanelDropwdown1ListItem2:
      'vous ne devez pas être déclaré légalement incapable ou placé sous tutelle judiciaire ',
    step1SidePanelDropwdown1ListItem3:
      'vous devez jouir de droits civils et politiques ',
    step1SidePanelDropwdown1ListItem4:
      'vous ne devez pas avoir été interdit de poser des actes relatifs à une activité professionnelle indépendante.',
    step1SidePanelDropdown2Title:
      "Conditions relatives à la nationalité et à l'exercice d'une profession spécifique en Belgique",
    step1SidePanelDropdown2ListItem1:
      "Si vous êtes un professionnel d'un État membre de l'Union européenne, de l'Espace économique européen ou de la Suisse, vous n'avez pas besoin d'une carte professionnelle pour exercer votre activité en Belgique. Cependant, étant donné que certaines professions sont réglementées, vous devrez d'abord vérifier si vos qualifications professionnelles sont reconnues dans ces professions.",
    step1SidePanelDropdown2ListItem2:
      "Si vous n'avez pas la nationalité belge, la nationalité de l'un des États membres de l'Espace économique européen ou la nationalité suisse, vous devez être en possession d'une carte professionnelle pour pouvoir exercer une activité indépendante en Belgique (sauf si vous êtes exempté).",
    step1SidePanelDropdown3Title: "Conditions relatives à l'activité",
    step1SidePanelDropdown3ListItem1Part1:
      'Capacités entrepreneuriales prouvées (Uniquement nécessaires en Wallonie ',
    step1SidePanelDropdown3ListItem1Part2IsUrl: 'Wallonie ',
    step1SidePanelDropdown3ListItem1Part2Url:
      'https://www.wallonie.be/fr/demarches/passer-un-examen-devant-le-jury-central-pour-obtenir-les-connaissances-de-gestion-et-lacces-une',
    step1SidePanelDropdown3ListItem1Part3: '& ',
    step1SidePanelDropdown3ListItem1Part4IsUrl: 'Bruxelles ',
    step1SidePanelDropdown3ListItem1Part4Url:
      'https://1819.brussels/infotheque/demarrer-une-entreprise-formalites/comment-demontrer-vos-connaissances-de-base-en',
    step1SidePanelDropdown3ListItem1Part5: 'région)',
    step1SidePanelDropdown3ListItem2Part1:
      'Conditions relatives aux professions libérales et ',
    step1SidePanelDropdown3ListItem2Part2IsUrl: 'intellectuelles ',
    step1SidePanelDropdown3ListItem2Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/les-conditions-dacces-la',
    step1SidePanelDropdown3ListItem3Part1: 'Conditions relatives aux ',
    step1SidePanelDropdown3ListItem3Part2IsUrl:
      'licences et autorisations spécifiques',
    step1SidePanelDropdown3ListItem3Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/les-conditions-dacces-la',
    step2Text:
      "Pour entamer le parcours de création d'une entreprise, il est important d'évaluer votre point de départ. Les situations individuelles peuvent présenter des défis et des opportunités différents. Par exemple, être en mesure de créer une entreprise en tant qu'étudiant ou de conserver vos avantages de chômage tout en développant votre idée commerciale et en lançant votre entreprise.",
    step2ExternalLinkText: 'Le statut social des travailleurs indépendants',
    step2ExternalLinkUrl:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/affiliation-une-caisse/le-statut-social-des',
    step2SidePanelTitle: 'Évaluez votre point de départ ',
    step2SidePanelText:
      "Vous pouvez démarrer votre entreprise tout en étant étudiant, en occupant un emploi ou en étant au chômage. Découvrez les conditions spécifiques qui s'appliquent à votre situation : ",
    step2SidePanelDropdown1Title:
      'Démarrer tout en occupant un emploi (occupation secondaire)',
    step2SidePanelDropdown1ItemPart1: "Plus d'informations sur comment ",
    step2SidePanelDropdown1Item1Part2IsUrl:
      "créer une entreprise tout en bénéficiant de l'allocation chômage",
    step2SidePanelDropdown1Item1Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/affiliation-une-caisse/le-statut-social-des',
    step2SidePanelDropwdown2Title: 'Démarrer tout en bénéficiant du chômage',
    step2SidePanelDropdown2ItemPart1: "Plus d'informations sur comment ",
    step2SidePanelDropdown2ItemPart2IsUrl:
      "créer une entreprise tout en bénéficiant de l'allocation chômage",
    step2SidePanelDropdown2Item1Part2Url:
      'https://www.onem.be/citoyens/chomage-complet/pouvez-vous-travailler-pendant-le-chomage-/pouvez-vous-exercer-une-activite-independante-a-titre-accessoire-pendant-votre-chomage-dans-le-cadre-de-lavantage-tremplin-independants-',
    step2SidePanelDropdown2ItemPart3: '',
    step2SidePanelDropdown3Title:
      "Démarrer à partir d'une prestation d'incapacité / d'invalidité",
    step2SidePanelDropwdown3ListItem1Part1: "Prestation d'invalidité en ",
    step2SidePanelDropwdown3ListItem1Part2IsUrl: 'Flandre',
    step2SidePanelDropwdown3ListItem1Part2Url:
      'https://www.vlaio.be/nl/subsidies-financiering/subsidiedatabank/vlaamse-ondersteuningspremie-vop-voor-zelfstandigen',
    step2SidePanelDropwdown3ListItem2Part1: "Prestation d'invalidité à ",
    step2SidePanelDropwdown3ListItem2Part2IsUrl: 'Bruxelles',
    step2SidePanelDropwdown3ListItem2Part2Url:
      'https://1819.brussels/infotheque/demarrer-une-entreprise-formalites/lancer-une-activite-en-tant-que-personne-handicapee',
    step2SidePanelDropwdown3ListItem3Part1: "Prestation d'invalidité en ",
    step2SidePanelDropwdown3ListItem3Part2IsUrl: 'Wallonie',
    step2SidePanelDropwdown4Title: 'Conjoint aidant',
    step2SidePanelDropdown4ItemPart1: 'Plus d\'informations sur ',
    step2SidePanelDropdown4ItemPart2IsUrl: ' comment devenir le conjoint aidant ',
    step2SidePanelDropdown4Item1Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/affiliation-une-caisse/le-statut-social-des',
    step2SidePanelDropdown4ItemPart3: ' d\'une personne indépendante',
    step2SidePanelDropdown5Title1Part1:
      "Démarrer en tant qu'étudiant entrepreneur",
    step2SidePanelDropwdown5ListItem1Part1: 'Étudiant entrepreneur en ',
    step2SidePanelDropwdown5ListItem1Part2IsUrl: 'Flandre',
    step2SidePanelDropwdown5ListItem1Part2Url:
      'https://www.vlaio.be/nl/begeleiding-advies/start/je-statuut-als-zelfstandige/statuut-van-student-zelfstandige',
    step2SidePanelDropwdown5ListItem2Part1: 'Étudiant entrepreneur à ',
    step2SidePanelDropwdown5ListItem2Part2IsUrl: 'Bruxelles',
    step2SidePanelDropwdown5ListItem2Part2Url:
      'https://1819.brussels/infotheque/demarrer-une-entreprise-formalites/devenir-etudiant-entrepreneur',
    step2SidePanelDropwdown5ListItem3Part1: 'Étudiant entrepreneur en ',
    step2SidePanelDropwdown5ListItem3Part2IsUrl: 'Wallonie',
    step2SidePanelDropwdown5ListItem3Part2Url:
      'https://www.1890.be/solution/lancer-une-activite-independante-pendant-ses-etudes-est-tout-a-fait-possible/',
    step3Text1:
      'Vous avez décidé de créer votre propre entreprise et la prochaine chose la plus importante que vous devez faire est de choisir la structure juridique la plus adaptée pour votre entreprise. Chaque structure juridique disponible en Belgique a des implications en termes de coûts, de responsabilités, entre autres spécificités.',
    step3Text2:
      "En effet, vous avez 3 options pour exercer votre activité en tant qu'indépendant :",
    step3ListItem1: 'Entreprise individuelle (indépendant)',
    step3ListItem2: 'Société (avec ou sans personnalité juridique)',
    step3ListItem3: 'Organisme à but non lucratif',
    step3ExternalLinkText:
      "Quelle structure juridique d'entreprise devrais-je choisir ?",
    step3ExternalLinkUrl:
      'https://www.belgium.be/fr/economie/entreprise/creation/types_de_societe',
    step3SidePanelTitle:
      'Choisissez une structure juridique pour votre entreprise',
    step3TSidePanelext1:
      'Entreprise individuelle ou société ? Chacune de ces formules a ses avantages et ses inconvénients, et votre choix aura un impact significatif sur la vie de votre entreprise. Parmi les critères en jeu, on retrouve :',
    step3SidePanelListItem1: "le type d'activité envisagée ",
    step3SidePanelListItem2: 'le nombre de personnes qui participeront ',
    step3SidePanelListItem3: 'le capital disponible ',
    step3SidePanelListItem4: 'la contribution financière des associés ',
    step3SidePanelListItem5: 'la contribution financière des partenaires ',
    step3SidePanelListItem6: 'le régime fiscal le plus approprié ',
    step3SidePanelListItem7: "le développement prévisible de l'activité, etc.",
    step3SidePanelText2:
      "Le choix entre ces deux options nécessite une réflexion approfondie et doit être adapté à votre projet d'entreprise. N'hésitez pas à demander conseil à un notaire, avocat, consultant d'affaires, comptable ou expert fiscal.",
    step3SidePanelText3Part1:
      "Plus d'informations sur les structures juridiques peuvent être trouvées auprès du ",
    step3SidePanelText3Part2IsUrl: 'SPF Justice',
    step3SidePanelText3Part2Url:
      'https://justice.belgium.be/fr/themes_et_dossiers/societes_associations_et_fondations',
    step3SidePanelDropdown1Title: 'Entreprise individuelle',
    step3SidePanelDropdown1Text:
      "Une entreprise individuelle est le moyen le plus simple d'exercer une activité en tant qu'indépendant :",
    step3SidePanelDropdown1ListItem1:
      "elle ne nécessite pas la rédaction de statuts ni d'un capital de départ minimum ",
    step3SidePanelDropdown1ListItem2:
      'les coûts de constitution et de fonctionnement sont faibles ',
    step3SidePanelDropdown1ListItem3:
      'vous pouvez démarrer rapidement votre entreprise ',
    step3SidePanelDropdown1ListItem4:
      "vous êtes le seul responsable et vous pouvez prendre les décisions nécessaires pour l'exercice de votre activité sans avoir à consulter d'autres associés ",
    step3SidePanelDropdown1ListItem5:
      'la comptabilité à tenir est, en règle générale, simplifiée.',
    step3SidePanelDropwdon2Title: 'Société',
    step3SidePanelDropdown2Text1:
      "La constitution d'une société requiert les éléments suivants, selon le cas :",
    step3SidePanelDropdown2ListItem1: "l'intervention d'un notaire ",
    step3Dropdown2ListItem2: 'un capital minimum ',
    step3Dropdown2ListItem3: 'un plan financier ',
    step3Dropdown2ListItem4: 'la rédaction des statuts ',
    step3Dropdown2ListItem5:
      "un rapport de l'auditeur (par exemple, en cas d'apport en nature).",
    step3Dropdown2ListItem6:
      "De plus, il y a également des coûts associés à la dissolution et à la liquidation d'une société.",
    step3Dropdown2ListItem7:
      'Une société peut avoir une personnalité juridique distincte qui lui confère ses propres droits ainsi que des obligations.',
    step3Dropdown2Text2: 'Formes de sociétés les plus courantes :',
    step3Dropdown2ListItem8:
      "Société Anonyme (SA) : il s'agit de la forme pour les entreprises d'une certaine taille, où la levée de capitaux est l'objectif principal. Un seul fondateur est nécessaire pour créer une SA.",
    step3Dropdown2ListItem9:
      'Société à Responsabilité Limitée (SRL) : une société à responsabilité limitée est la forme de société "de base" par excellence. Étant donné qu\'il existe très peu de réglementations obligatoires, une SRL offre aux opérateurs économiques une grande marge de manœuvre pour former une entreprise "sur mesure". Par exemple, il y a une liberté dans les statuts pour déterminer à quel point la SRL est réellement limitée. Un seul fondateur est nécessaire pour créer une SRL.',
    step3Dropdown2ListItem10:
      "Société Coopérative (SCOP) : une société coopérative est une entreprise dont les actionnaires poursuivent un idéal coopératif, ce qui explique pourquoi elle nécessite toujours trois fondateurs. L'objectif principal de la société coopérative est de répondre aux besoins de ses actionnaires et elle peut également servir d'outil pour répondre aux besoins économiques ou sociaux de ces actionnaires.",
    step3Dropdown2ListItem11:
      "Société en Nom Collectif : une société en nom collectif est une société conclue entre des associés responsables solidairement et indéfiniment qui ont une responsabilité illimitée. Toutes les décisions doivent être prises à l'unanimité, sauf si les statuts de la société prévoient que les décisions peuvent être prises à la majorité.",
    step3Dropdown2ListItem12:
      "Société en Commandite (SC) : une société en commandite est une société conclue entre un ou plusieurs associés responsables solidairement et indéfiniment qui ont une responsabilité illimitée, appelés associés commandités ou gérants, et un ou plusieurs associés limités aux apports en espèces ou en nature, appelés associés silencieux ou commanditaires. Les associés gérants sont impliqués dans la gestion. Les associés silencieux ne sont pas impliqués dans la gestion. Toutes les décisions doivent être prises à l'unanimité, sauf si les statuts de la société prévoient que les décisions peuvent être prises à la majorité.",
    step3Dropwdon3Title: 'Organisme à but non lucratif',
    step3Dropdown2ListItem13:
      "Association sans but lucratif (ASBL) : une association sans but lucratif est un groupe de personnes physiques ou de personnes morales poursuivant un objectif caritatif. L'association sans but lucratif est composée d'au moins deux personnes.",
    step3Dropdown2ListItem14:
      "Association internationale sans but lucratif (AISBL) : une association internationale sans but lucratif est un groupe de personnes physiques ou morales poursuivant un objectif caritatif d'intérêt international.",
    step4Text1:
      "Si vous souhaitez démarrer votre propre activité en tant qu'indépendant, que ce soit une entreprise individuelle ou une société, vous devez ouvrir un compte courant professionnel auprès d'une banque ou d'une autre institution financière.",
    step4Text2:
      "Avoir un compte bancaire professionnel est important car vous devez indiquer le numéro de ce compte sur tous vos documents commerciaux (lettres, factures, etc.) à côté de votre numéro d'entreprise, du nom de votre entreprise et du nom de votre institution financière.",
    step4ExternalLinkText:
      'Service bancaire de base pour les entreprises et les missions diplomatiques',
    step4ExternalLinkUrl:
      'https://economie.fgov.be/fr/themes/services-financiers/services-de-paiement/service-bancaire-de-base/service-bancaire-de-base-pour-0',
    step4SidePanelTitle: 'Ouvrez un compte bancaire professionnel',
    step4SidePanelText1:
      "Pour une société, le compte doit être au nom de la société. Pour une entreprise individuelle, il est également conseillé d'ouvrir un nouveau compte professionnel car de cette façon, les transactions commerciales et privées restent séparées.",
    step4SidePanelText2:
      'Comme vous avez souvent des dépenses avant le lancement officiel de votre entreprise, assurez-vous de créer vos comptes professionnels dès que possible.',
    step4SidePanelText3:
      "N'oubliez pas qu'il est obligatoire d'avoir un compte bancaire professionnel local (belge) pour les sociétés immatriculées en Belgique. Selon la banque, il faut généralement 2 jours pour mettre en place le compte et 7 jours pour recevoir la carte.",
    step4SidePanelMoreInformation: "Plus d'informations",
    step4SidePanelMoreInformationUrl:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/louverture-dun-compte-vue',
    step5Text1:
      "Vous devez choisir une adresse officielle pour enregistrer votre entreprise. Cette adresse deviendra ce qu'on appelle le \"siège\" de l'entreprise après l'enregistrement. Selon la structure juridique que vous choisissez (entreprise individuelle ou société), différentes responsabilités s'appliqueront.",
    step5ExternalLinkText: "Types d'unités d'établissement",
    step5ExternalLinkUrl:
      'https://economie.fgov.be/fr/themes/entreprises/banque-carrefour-des/inscription-la-banque',
    step5SidePanelTitle: "Choisissez l'adresse de votre entreprise",
    step5SidePanelText1:
      "Les différentes structures juridiques ont des implications en termes de responsabilités et de responsabilité du patrimoine. Cela est également lié à l'adresse de votre entreprise et à d'autres actifs physiques.",
    step5SidePanelMoreInformation: "Plus d'informations",
    step5SidePanelMoreInformationUrl:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/choisir-le-statut-juridique',
    step5SidePanelDropwdon1Title: 'Entreprise individuelle',
    step5SidePanelDropdown1Text1:
      "Dans une entreprise individuelle, il n'y a pas de séparation entre les actifs alloués à l'activité professionnelle et les actifs privés de l'entrepreneur. Par conséquent, l'adresse officielle de l'entreprise ou le \"siège\" officiel est le domicile officiel de l'entrepreneur.",
    step5SidePanelDropdown1Text2:
      "L'entrepreneur est donc, avec tous ses actifs, responsable des engagements de son entreprise. Ceci n'est pas sans risque, par exemple en cas de faillite d'un client important. Leurs dettes peuvent être récupérées sur tous les actifs de l'entrepreneur, qu'ils soient meubles ou immeubles, présents ou futurs.",
    step5SidePanelDropdown1Text3:
      "Les actifs du conjoint peuvent également être utilisés pour payer les dettes de l'entreprise, sauf si un contrat de mariage prévoit le contraire.",
    step5SidePanelDropdown1SubDropdownText1:
      'Protection de la résidence principale de la personne indépendante :',
    step5SidePanelDropdown1SubDropdownText2:
      "Le risque pesant sur le domicile de la personne indépendante a été quelque peu réduit par une mesure qui lui permet de protéger sa résidence en la déclarant insaisissable dans certaines circonstances. La protection ne s'applique qu'à la résidence principale de la personne indépendante, c'est-à-dire l'endroit où elle vit la majeure partie de l'année.",
    step5SidePanelDropdown1SubDropdownText3:
      "La protection n'est pas automatique, la personne indépendante doit faire une déclaration d'insaisissabilité devant le notaire de son choix.",
    step5SidePanelDropdown1SubDropdownText4Part1:
      "La protection ne concerne que les dettes liées à l'activité professionnelle après la",
    step5SidePanelDropdown1SubDropdownText4Part2IsUrl:
      "déclaration d'insaisissabilité",
    step5SidePanelDropdown1SubDropdownText4PartsUrl:
      'https://www.notaire.be/entreprendre',
    step5SidePanelDropdown1SubDropdownText4Part3:
      ". Elle ne s'appliquera donc pas aux dettes privées.",
    step5SidePanelDropdown1SubDropdownText5:
      'La personne indépendante peut bien sûr renoncer à la protection en faisant une nouvelle déclaration. Cela prend également fin si la personne indépendante change de statut ou décède.',
    step5SidePanelDropdown1Text4Part1:
      "Pour toutes informations sur cette disposition légale, contactez un notaire. Vous pouvez consulter l'annuaire des notaires sur le site du ",
    step5SidePanelDropdown1Text4Part2IsUrl:
      'Fédération Royale du Notariat Belge.',
    step5SidePanelDropdown1Text4Part2Url: 'https://www.notaire.be//',
    step5SidePanelDropdown1Text5:
      "Dans le cas d'une entreprise individuelle, en cas de changement d'adresse (le siège), aucune action n'est requise de la part de l'entreprise. La commune modifiera l'adresse dans le registre national et la BCE sera adaptée en fonction de ces nouvelles informations.",
    step5SidePanelDropwdon2Title: 'Société',
    step5SidePanelDropdown2Text1:
      "Dans une société, il y a une séparation entre les actifs de l'entreprise et les actifs de l'entrepreneur. Une partie des actifs peut donc être exemptée du risque entrepreneurial. Par conséquent, l'adresse officielle de la société est convenue par le(s) propriétaire(s) de l'entreprise.",
    step5SidePanelDropdown2ListItem1: 'Responsabilité limitée',
    step5SidePanelDropdown2ListItem1Text1:
      "Dans les sociétés à responsabilité limitée (SA, SPRL, SCOP), l'actionnaire n'est responsable des dettes de la société qu'à hauteur de sa contribution. Cela signifie que les créanciers de la société ne peuvent pas faire valoir leurs droits sur les actifs personnels de l'entrepreneur. Les actifs personnels de l'entrepreneur et des actionnaires sont donc protégés.",
    step5SidePanelDropdown2ListItem1Text2:
      "Dans certains cas, cependant, la responsabilité peut être invoquée. C'est le cas notamment si la société est déclarée en faillite dans les trois ans qui suivent sa constitution et si le capital initial ou la contribution en capital était clairement insuffisant pour assurer le fonctionnement normal de la société pendant deux ans.",
    step5SidePanelDropdown2ListItem2: 'Responsabilité illimitée',
    step5SidePanelDropdown2ListItem2Text1:
      "Dans les sociétés à responsabilité illimitée (société en nom collectif, société en commandite simple), les associés (à l'exception des associés commandités) mettent leurs propres actifs en garantie des éventuelles dettes de la société. Si la société est dans l'incapacité de payer ses dettes, ses créanciers peuvent poursuivre le paiement de leur créance sur les actifs personnels des associés.",
    step5SidePanelDropdown2ListItem2Text2:
      "Dans le cas d'une société, en cas de changement d'adresse (le siège), des démarches sont nécessaires de la part de l'entreprise. L'entreprise doit publier le changement d'adresse au Moniteur belge, puis le tribunal de l'entreprise se chargera de mettre à jour ces informations dans la BCE.",
    step6Text1:
      "Choisir un nom pour votre entreprise peut sembler simple, mais c'est une étape cruciale : vous devez trouver le nom idéal qui attire les clients et vous démarque de vos concurrents.",
    step6Text2:
      "De plus, vous devez respecter différentes dispositions spécifiques en fonction de la manière dont vous lancez votre entreprise, que ce soit en tant qu'entreprise individuelle ou en tant que société.",
    step6Text3:
      "Dans certains cas, les entrepreneurs individuels peuvent choisir d'exercer leur activité sous leur propre nom.",
    step6SidepanelTitle: 'Choisissez le nom de votre entreprise',
    step6SidePanelText1:
      "Le nom de votre entreprise a d'autres implications, telles que la marque des biens et services que vous vendez, votre domaine de site web, votre adresse e-mail, l'emballage de vos produits, etc.",
    step6SidePanelText2Part1:
      "Avant de choisir un nom commercial ou une dénomination sociale pour votre entreprise, vous pouvez vérifier s'il existe une marque antérieure comparable ou similaire pour des activités et des territoires similaires. Vous pouvez effectuer une recherche via le site web de l",
    step6SidePanelText2Part2IsUrl:
      'Office Benelux de la Propriété Intellectuelle (BOIP)',
    step6SidePanelText2Url: 'https://www.boip.int/fr',
    step6SidePanelText2Part3:
      ', qui gère le registre des marques Benelux. Ce registre donne accès aux marques protégées sur le territoire du Benelux.',
    step6SidePanelTextPart4:
      'Il existe différentes options pour choisir et protéger le nom commercial et la dénomination sociale, selon le type de structure juridique.',
    step6SidePanelDropdown1Title:
      "Choisissez un nom commercial et/ou une dénomination sociale (les entrepreneurs individuels peuvent avoir uniquement besoin d'un nom commercial)",
    step6SidePanelDropdown1ListItem1:
      "Tenez compte des connotations négatives ou controversées éventuelles du nom de votre entreprise avant de prendre une décision finale. Il est conseillé de vérifier sa signification dans d'autres langues, notamment si vous prévoyez de vendre vos biens et services en dehors de la Belgique.",
    step6SidePanelDropdown1ListItem2Part1:
      'Recherchez dans la Banque-Carrefour des Entreprises les entreprises existantes pour ',
    step6SidePanelDropdown1ListItem2Part2IsUrl:
      'vérifier si un nom existe déjà',
    step6SidePanelDropdown1ListItem2Part2Url:
      'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?lang=fr',
    step6SidePanelDropdown2Title: 'Protégez votre nom commercial',
    step6SidePanelDropdown1Text1:
      "Le droit au nom commercial ne nécessite aucune formalité préalable d'enregistrement. Ce droit découle de la première utilisation publique du nom commercial.",
    step6SidePanelDropdown1Text2:
      "La publication du nom commercial dans la Banque-Carrefour des Entreprises peut constituer cette première utilisation, mais en cas de litige, il faudra fournir une preuve, ce qui signifie que les règles du Code civil s'appliqueront.",
    step6SidePanelDropdown3Title: 'Protégez votre dénomination sociale',
    step6SidePanelDropdown2Text1:
      "Le droit à la dénomination sociale d'une personne morale est acquis lors de l'enregistrement au tribunal de l'entreprise, car la société acquiert la personnalité juridique à cette date.",
    step6SidePanelDropdown4Title: 'Marque commerciale',
    step6SidePanelDropdown4Text2Part1:
      'Alors que le nom commercial et la dénomination sociale sont des signes distinctifs de la société elle-même, la marque commerciale est le signe distinctif des biens ou services de la société.',
    step6SidePanelDropdown4Text2Part2:
      "Pour être protégée, la marque commerciale doit être enregistrée avec diligence selon une procédure. L'",
    step6SidePanelDropdown4Text2Part3IsUrl:
      'Office Benelux de la Propriété Intellectuelle (BOIP)',
    step6SidePanelDropdown4Text2Part3Url: 'https://www.boip.int/fr',
    step6SidePanelDropdown4Text2Part4:
      ' peut vous fournir toutes les informations dont vous avez besoin à ce sujet.',
    step7Text1:
      "La qualité des plans d'affaires et financiers est cruciale pour convaincre vos futurs partenaires de la sérieux du projet, de sa rentabilité et de sa viabilité. Ils sont également nécessaires lorsque vous allez faire une demande de crédit auprès d'un établissement de prêt.",
    step7Text2:
      "Pour la création d'une SA (Société Anonyme), d'une SPRL (Société Privée à Responsabilité Limitée) ou d'une SCOP (Société Coopérative), la présentation d'un plan d'affaires et financier est exigée par la loi, tandis qu'elle est également recommandée pour les entrepreneurs individuels.",
    step7ExtraExternalLinkText:
      "Protégez votre idée d'entreprise avec la propriété intellectuelle",
    step7ExtraExternalLinkTextUrl: 'https://www.boip.int/fr',
    step7SidepanelTitle: "Créez un plan d'affaires",
    step7SidepanelText1:
      "Le plan d'affaires est un document dans lequel vous détaillez vos objectifs à court et à long terme ainsi que votre stratégie pour les atteindre. Le plan financier est la partie numérique du plan d'affaires.",
    step7SidepanelText2:
      "Vous pouvez faire appel à des comptables pour vous aider dans vos plans car ils peuvent détecter toute faiblesse et vous donner des conseils. Les différentes régions fournissent également des modèles gratuits et des ressources utiles pour faciliter la création de votre plan d'affaires.",
    step7SidepanelText3Part1: "Ressources pour créer un plan d'affaires (",
    step7SidepanelText3Part2IsUrl: 'Bruxelles, ',
    step7SidepanelText3Part2Url:
      'https://1819.brussels/infotheque/avant-de-demarrer/le-contenu-du-business-plan',
    step7SidepanelText3Part3IsUrl: 'Wallonie, ',
    step7SidepanelText3Part3Url:
      'https://www.1890.be/outil/business-plan/?search&terms=business%20plan',
    step7SidepanelText3Part4IsUrl: 'Flandre)',
    step7SidepanelText3Part4Url:
      'https://www.vlaio.be/nl/begeleiding-advies/groei-innovatie/je-bedrijf-onder-de-loep/ondernemingsplan',
    step7SidepanelMoreInformation: "Plus d'informations",
    step7SidepanelMoreInformationUrl:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/choisir-le-statut-juridique',
    step7SidePanelDropdown1Title: "Plan d'affaires",
    step7SidePanelDropdown1Text1:
      "Le plan d'affaires vous permet de démontrer la qualité de votre opportunité d'affaires, d'identifier les principaux risques, de définir la stratégie et la vision futures, de projeter la croissance et d'établir des objectifs.",
    step7SidepnaleDropdown1Text2:
      "En tant qu'outil stratégique pour les entrepreneurs, le plan d'affaires comprend une description du projet, une offre commerciale, une analyse de marché, un plan commercial, les ressources humaines, la vision à long terme et le plan financier.",
    step7SidePanelDropdown2Title: 'Plan financier',
    step7SidePanelDropdown2Text1Part1:
      "Le plan financier, qui contient les données financières de votre entreprise, est la partie du plan d'affaires requise par la loi pour les sociétés (SA, SPRL, SC). Il peut être utilisé pour évaluer votre responsabilité en tant que fondateur ",
    step7SidePanelDropdown2Text1Part2IsUrl:
      'si votre entreprise est déclarée en faillite dans les trois ans. ',
    step7SidePanelDropdown2Text1Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/choisir-le-statut-juridique',
    step7SidePanelDropdown2Text1Part3:
      'Si les actifs initiaux sont jugés manifestement insuffisants, vous pouvez être tenu personnellement responsable.',
    step7SidePanelDropdownText2:
      'Le plan financier documente les objectifs financiers à court et à long terme de votre entreprise et comprend une stratégie pour les atteindre. Essayez de faire une estimation réaliste et réalisable pour les années à venir. Au minimum, il devrait inclure : une prévision des résultats après 1 et 2 ans et un bilan projeté après 1 et 2 ans.',
    step7SidePanelListItem1: 'un aperçu de toutes les sources de financement ',
    step7SidePanelListItem2: "un bilan d'ouverture",
    step7SidePanelListItem3:
      'un compte de résultats prévisionnel après 12 et 24 mois',
    step7SidePanelListItem4:
      "un budget des revenus et dépenses prévus pour une période d'au moins deux ans",
    step7SidePanelListItem5:
      'une description des hypothèses faites pour estimer les revenus et la rentabilité prévus.',
    step8Text1:
      "Les activités économiques que vous exercerez dans votre entreprise sont basées sur une liste définie de codes appelés codes 'NACE' et font partie intégrante de l'enregistrement de votre entreprise à la Banque-Carrefour des Entreprises (BCE).",
    step8Text2:
      "Il est important que les codes NACE enregistrés à la BCE soient corrects et précis par rapport aux activités économiques que vous exercez dans votre entreprise. Si vous exercez d'autres activités différentes de celles incluses dans la BCE, vous pourriez rencontrer des problèmes administratifs.",
    step8ToolTitle: 'Assistant',
    step8ToolText:
      "Un outil en ligne qui vous aidera à choisir les bons codes NACE pour définir les activités de votre entreprise, ses autorisations et les qualifications requises. Utilisez les résultats obtenus avec cet assistant pour passer à l'étape suivante de votre parcours afin de créer une entreprise.",
    step8ToolButtonText: "Lancer l'outil",
    step8SidePanelTitle:
      'Définir les activités économiques de votre entreprise',

    step8SidePanelText1:
      'Naviguer parmi les codes NACE par vous-même peut être chronophage et, de plus, certaines des activités économiques que vous exercez peuvent avoir des implications selon la région. Cet outil vous aide à naviguer toutes ces dépendances tout en vous donnant la liberté de définir votre entreprise selon votre idée.',
    step8SidePanelDropdown1Title: 'Changer ou ajouter des codes NACE',
    step8SidePanelDropdown1Text1:
      "Après avoir enregistré l'entreprise à la Banque-Carrefour des Entreprises (BCE), les codes NACE peuvent être supprimés ou ajoutés à l'entreprise dans la BCE. L'entreprise est responsable de l'exactitude des données enregistrées dans la BCE, non seulement au moment de la création de l'entreprise, mais aussi lorsque vous ajoutez ou cessez de réaliser certaines activités par la suite.",
    step8SidePanelDropdown1Text2Part1: 'Cette modification via un ',
    step8SidePanelDropdown1Text2Part2IsUrl: "guichet d'entreprise agréé",
    step8SidePanelDropdown1Text2Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/demarches-aupres-dun-guichet/les-guichets-dentreprises',
    step8SidePanelDropdown1Text2Part3:
      " doit être effectuée dans un délai d'un mois à compter de la date de début des nouvelles activités. Un ajustement des activités de l'entreprise dans la BCE coûte 90,50 € par unité d'établissement.",
    step8SidePanelDropdown2Title: "Unités d'établissement et codes NACE",
    step8SidePanelDropdown2Text1:
      "La définition d'une 'unité d'établissement' considère chaque lieu où l'entreprise exerce une activité (représentée par un code NACE) qui peut être identifiée par une adresse officielle en Belgique.",
    step8SidePanelDropdown2Text2:
      "Toute entreprise ayant une caractéristique obligée d'enregistrement doit avoir au moins une unité d'établissement active.",
    step8SidePanelDropdown2ListItem1:
      "En cas d'entreprise individuelle, l'adresse de l'unité d'établissement principale est le siège de l'entreprise.",
    step8SidePanelDropdown2ListItem2:
      "En cas de société, l'unité d'établissement principale est l'adresse où la personne morale peut être contactée.",
    step8SidePanelDropdown2Text3:
      "Lorsque l'adresse d'une unité d'établissement existante change, l'entreprise peut effectuer ce changement elle-même via l'application My Enterprise ou se rendre à un guichet d'entreprise agréé qui propose ce service moyennant des frais.",
    step8SidePanelDropdown2Text4:
      "L'ajout d'une nouvelle unité d'établissement à votre entreprise entraîne toujours des frais et est effectué par un guichet d'entreprise agréé.",
    step9text1:
      "Si vous êtes ressortissant de l'Espace économique européen (EEE) et souhaitez exercer votre profession en Belgique, vous devrez peut-être faire reconnaître votre qualification professionnelle.",
    step9text2:
      'Certaines des activités économiques (codes NACE) que vous exercerez dans le cadre de votre entreprise peuvent être réglementées et nécessiter une certaine qualification professionnelle ou un diplôme.',
    step9ToolTitle: 'Assistant',
    step9ToolText:
      "Un outil en ligne qui vous aidera à déterminer les codes NACE nécessitant une qualification professionnelle spécifique. Utilisez les résultats de cette assistant pour vos prochaines étapes dans votre parcours de création d'entreprise.",
    step9ToolButtonText: "Lancer l'outil",
    step9SidePanelTitle:
      'Faites reconnaître votre qualification professionnelle',
    step9SidePanelText1:
      "L'outil en ligne vous aide à déterminer si une activité économique (NACE) spécifique nécessitera une qualification professionnelle ou un diplôme spécifique.",
    step9SidePanelText2:
      'Il existe 3 façons de savoir si une profession est réglementée en Belgique :',
    step9SidePanelListItem1Part1: 'En consultant la ',
    step9SidePanelListItem1Part2IsUrl:
      'base de données européenne des professions réglementées',
    step9SidePanelListItem1Part2Url:
      'https://ec.europa.eu/growth/tools-databases/regprof/home',
    step9SidePanelListItem2Part1: "En utilisant l'aide de ",
    step9SidePanelListItem2Part2IsUrl: 'Be-Assist',
    step9SidePanelListItem2Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/conditions-dacces-la/centre-dassistance-belge-pour',
    step9SidePanelListItem2Part3:
      ", le centre d'assistance pour la reconnaissance des qualifications professionnelles.",
    step9SidePanelListItem3Part1: "En faisant appel aux services d'un ",
    step9SidePanelListItem3Part2IsUrl: "guichet d'entreprise agréé",
    step9SidePanelListItem3Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/demarches-aupres-dun-guichet/les-guichets-dentreprises',
    step9SidePanelListItem3Part3:
      ' qui propose ces services moyennant des frais.',
    step9SidePanelText3Part1:
      'Si vous avez besoin de faire reconnaître une qualification professionnelle, vous pouvez vous inscrire sur le site de ',
    step9SidePanelText3Part2IsUrl: 'Reconnaissance des compétences',
    step9SidePanelText3Part2Url:
      'https://competencerecognition.belgium.be/app/form',
    step9SidePanelText3Part3: '.',
    step10Text1:
      "L'enregistrement de votre entreprise à la Banque-Carrefour des Entreprises et l'obtention de votre numéro d'entreprise sont l'une des étapes les plus importantes du processus.",
    step10TextExternalLinkText: "Plus d'informations concernant l'inscription",
    step10TextExternalLinkTextUrl:
      'https://finances.belgium.be/fr/entreprises/tva/declaration/debut-fin-modification-activite',
    step10SidePanelTitle:
      'Enregistrez votre entreprise à la Banque-Carrefour des Entreprises',
    step10SidePanelDropdown1Title: 'Entreprise individuelle',
    step10SidePanelDropdown1Text1Part1: 'Rendez-vous dans un ',
    step10SidePanelDropdown1Text1Part2IsUrl: "guichet d'entreprise agréé",
    step10SidePanelDropdown1Text1Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/demarches-aupres-dun-guichet/les-guichets-dentreprises',
    step10SidePanelDropdown1Text1Part3:
      " pour votre enregistrement à la Banque-Carrefour des Entreprises. Ils vous demanderont les codes NACE qui définissent les activités économiques de votre entreprise, car ils sont utilisés par le guichet d'entreprise agréé pour vous enregistrer en tant qu'entrepreneur individuel.",
    step10SidePanelDropdown1Text2:
      "Une fois votre entreprise enregistrée, vous recevrez votre numéro d'entreprise à 10 chiffres.",
    step10SidePanelDropdown2Title: 'Société',
    step10SidePanelDropdown2SubDropdown1Title1:
      '1. Demande de certificat bancaire',
    step10SidePanelDropdown2SubDropdown1Text1:
      "Vous devez demander un certificat bancaire pour la SA (exigence de capital de départ) et pour la SPRL et la SCRL (capital suffisant). Dans ce certificat, l'institution financière confirme que vous avez versé le montant requis sur un compte courant au nom de la société.",
    step10SidePanelDropdown2SubDropdown2Title:
      "2. Rédaction des statuts de l'entreprise",
    step10SidePanelDropdown2SubDropdown2Text2: 'Inclure : ',
    step10SidePanelDropdown2SubDropdown2Listitem1: 'Identité des fondateurs',
    step10SidePanelDropdown2SubDropdown2Listitem2: 'Nom et objet de la société',
    step10SidePanelDropdown2SubDropdown2Listitem3:
      'Réglementation des assemblées générales',
    step10SidePanelDropdown2SubDropdown2Listitem4:
      "Autres règles qui s'appliqueront dans la société",
    step10SidePanelDropdown2SubDropdown3Title:
      "3. Rédiger l'acte de constitution",
    step10SidePanelDropdown2SubDropdown3Text1:
      "La première obligation est l'acte de constitution écrit. Il contient les statuts de la société, avec toutes ses caractéristiques (nom, siège social, objet, capital, le cas échéant, ...) et ses règles de fonctionnement.",
    step10SidePanelDropdown2SubDropdown3ListItem1:
      "Pour les sociétés à responsabilité limitée (SA, SPRL, SCOP) qui nécessitent un acte authentique/notarié, la procédure de rédaction de l'acte authentique de constitution se fait via un notaire civiliste. Le coût dépend de la complexité de l'acte.",
    step10SidePanelDropdown2SubDropdown3ListItem2:
      "Pour les sociétés à responsabilité illimitée (société en nom collectif et société en commandite simple), qui nécessitent un acte sous seing privé, l'acte sous seing privé est un accord écrit rédigé par les parties elles-mêmes ou par des tiers. Cet accord doit être signé par les parties.",
    step10SidePanelDropdown2SubDropdown4Title:
      "4. Déposer l'acte de constitution",
    step10SidePanelDropdown2SubDropdown4Text1:
      "Le notaire déposera l'acte auprès du tribunal de commerce compétent.",
    step10SidePanelDropdown2SubDropdown4Text2:
      "L'acte de constitution doit être déposé auprès du greffier du tribunal de commerce compétent. Vous avez 30 jours pour le faire.",
    step10SidePanelDropdown2SubDropdown4ListItem1:
      "Pour les sociétés à responsabilité limitée (SA, SPRL, SCOP), le notaire civiliste se charge du dépôt. L'acte authentique est inclus dans le registre des personnes morales et il existe deux façons de déposer l'acte :",
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1:
      'Dépôt physique via un notaire civiliste.',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part1:
      'En ligne sur ',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part2IsUrl:
      'startmybusiness.be.',
    step10SidePanelDropdown2SubDropdown4ListItem1SubListItem1Part2Url:
      'https://startmybusiness.be/?lang=fr',
    step10SidePanelDropdown2SubDropdown4ListItem2:
      "Pour les sociétés à responsabilité illimitée (société en nom collectif et société en commandite simple), l'acte sous seing privé doit être déposé par les associés de l'une des trois manières suivantes :",
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part1:
      'En ligne sur ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part2IsUrl:
      'eGRIFFIE',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part2Url:
      'https://www.e-greffe.be/evzw/fr_BE/homepage',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part3:
      "Dépôt physique auprès d'un greffier du tribunal de commerce compétent",
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part1:
      'Via un ',
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part2IsUrl:
      "guichet d'entreprise agréé.",
    step10SidePanelDropdown2SubDropdown4ListItem2SubListItem1Part4Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/demarches-aupres-dun-guichet/les-guichets-dentreprises',
    step10SidePanelDropdown2SubDropdown4ListItem3:
      'Pour les sociétés à responsabilité illimitée (société en nom collectif et société en commandite simple), les associés doivent le faire dans les quatre mois qui suivent la constitution de la société.',
    step10SidePanelDropdown2SubDropdown5Title:
      "5. Enregistrer l'acte de constitution",
    step10SidePanelDropdown2SubDropdown5Text1:
      "L'acte de constitution d'une société doit être enregistré au bureau de l'enregistrement de l'Administration générale de la Documentation patrimoniale (AGDP) afin de payer une taxe d'enregistrement (fiscale). Vous devez payer des frais d'enregistrement pour cela.",
    step10SidePanelDropdown2SubDropdown5ListItem1:
      "Pour les sociétés à responsabilité illimitée (société en nom collectif et société en commandite simple), l'enregistrement à l'AGDP doit être effectué par le notaire civiliste dans les 15 jours.",
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem1:
      'Enregistrement physique via un notaire civiliste',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part1:
      'En ligne sur ',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part2IsUrl:
      'startmybusiness.be',
    step10SidePanelDropdown2SubDropdown5ListItem1SubListItem2Part2Url:
      'https://startmybusiness.be/?lang=fr',
    step10SidePanelDropdown3Title: 'Organisation à but non lucratif',
    step10SidePanelDropdown3TextPart1: 'La ',
    step10SidePanelDropdown3TextPart2:
      "procédure d'enregistrement d'une organisation à but non lucratif",
    step10SidePanelDropdown3TextPart2Url:
      'https://business.belgium.be/fr/creer_votre_entreprise/creation_dune_societe',
    step10SidePanelDropdown3TextPart3:
      ' telles que VZW ou IVZW, se trouvent sur le site web du SPF Justice.',
    step10SidePanelDropdown4Title: 'Sociétés étrangères',
    step10SidePanelDropdown4Text1:
      'Une société étrangère ouvrant une succursale en Belgique peut effectuer les mêmes démarches en ligne ou en remplissant physiquement certains documents auprès du tribunal de commerce compétent.',
    step10SidePanelDropdown4Text2Part1: "Plus d'informations sur la ",
    step10SidePanelDropdown4Text2Part2IsUrl:
      "constitution d'une société étrangère",
    step10SidePanelDropdown4Text2Part2Url:
      'https://business.belgium.be/fr/creer_votre_entreprise/creation_dune_societe',
    step10SidePanelDropdown4Text2Part3: ' en Belgique',
    step10SidePanelDropdown1Text3IsUrl: "Plus d'informations (SPF Justice)",
    step10SidePanelDropdown1Text3Url:
      'https://justice.belgium.be/fr/themes_et_dossiers/societes_associations_et_fondations',
    step11Text1:
      "Une entreprise établie en Belgique doit s'inscrire à la TVA auprès de l'Administration fiscale si elle est assujettie à la TVA. Une entreprise belge (ou étrangère) est redevable de la TVA si son activité consiste en la fourniture de biens ou de services telle que définie dans le Code TVA belge.",
    step11Text2:
      "Si les seules activités de l'entreprise sont exonérées de TVA (par exemple, certaines activités socio-culturelles, transactions financières, transactions dans le secteur médical) et que l'entreprise n'a donc pas droit à déduction, elle n'est pas tenue de s'inscrire.",
    step11ButtonText: "Mon entreprise doit-elle s'inscrire à la TVA ?",
    step11ButtonTextUrl:
      'https://finances.belgium.be/fr/entreprises/tva/assujettissement-tva',
    step11SidePanelTitle:
      "Inscrire votre entreprise auprès de l'Administration fiscale",
    step11SidePanelText:
      "Une fois votre entreprise inscrite, vous recevez votre numéro d'entreprise à 10 chiffres. Une fois inscrite auprès de l'Administration fiscale, le numéro de TVA de votre entreprise est le même numéro à 10 chiffres que votre numéro d'entreprise.",
    step11SidePanelDropdown1Title: 'Entreprise individuelle',
    step11SidePanelDropdown1TextPart1:
      'Pour activer votre numéro de TVA, vous pouvez remplir un ',
    step11SidePanelDropdown1TextPart2IsUrl: 'formulaire en ligne',
    step11SidePanelDropdown1TextPart2Url:
      'https://finances.belgium.be/fr/entreprises/tva/declaration/debut-fin-modification-activite',
    step11SidePanelDropdown1TextPart3:
      " ou faire appel aux services d'un guichet d'entreprise agréé moyennant des frais.",
    step11SidePanelDropdown2Title: 'Société',
    step11SidePanelDropdown2Text:
      "Si vous avez fait appel à un notaire, celui-ci se chargera d'activer votre numéro de TVA et de vous renvoyer les informations.",
    step12Text1:
      "Avant d'exercer une activité économique dans votre entreprise (codes NACE), vous devez vous assurer d'avoir les autorisations et les permis nécessaires pour le faire. Certains codes NACE nécessitent des permis spécifiques, qui peuvent varier d'une région à l'autre.",
    step12ToolTitle: 'Aggregation platform',
    step12ToolText:
      'Un outil en ligne qui vous aidera à déterminer quels codes NACE définissent le mieux les activités économiques de votre entreprise tout en indiquant les activités qui nécessitent un permis ou une qualification professionnelle. Utilisez les résultats de cet outil dans les prochaines étapes de votre parcours pour démarrer une entreprise.',
    step12ToolButtonText: "Lancer l'outil",
    step12SidePanelTitle: 'Demander des permis',
    step12SidePanelText1:
      'Utilisez cet outil pour rechercher des détails concernant les autorisations, les spécifications techniques des produits et les exigences en matière de qualification professionnelle.',
    step12SidePanelText2Part1:
      "Bien que certains permis dépendent de l'activité économique, tels que ceux de l'",
    step12SidePanelText2Part2IsUrl: 'AFSCA',
    step12SidePanelText2Part2Url:
      'https://www.fasfc.be/approval-authorisation-and-registration',
    step12SidePanelText2Part3:
      " (Agence fédérale pour la sécurité de la chaîne alimentaire), d'autres permis et autorisations supplémentaires (comme ceux de la ",
    step12SidePanelText2Part4IsUrl: 'SABAM',
    step12SidePanelText2Part4Url: 'https://www.sabam.be/fr',
    step12SidePanelText2Part5:
      " ou des permis liés à l'environnement) peuvent être nécessaires dans votre situation même s'ils ne sont pas liés à un code NACE spécifique.",
    step12SidePanelText3Part1:
      "La demande de permis est toujours associée à des frais variables. Vous pouvez effectuer la demande de permis auprès des différentes communes ou régions, ou faire appel aux services d'un ",
    step12SidePanelText3Part2: "guichet d'entreprise agréé.",
    step12SidePanelText3Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/demarches-aupres-dun-guichet',
    step12SidePanelText3: 'Permis spécifiques par région :',
    step12SidePanelListItem1IsUrl: 'Flandre',
    step12SidePanelListItem1Url: 'https://www.omgevingsloketvlaanderen.be/',
    step12SidePanelListItem2IsUrl: 'Wallonie',
    step12SidePanelListItem2Url:
      'https://www.wallonie.be/fr/demarches/demander-un-permis-denvironnement-ou-un-permis-unique-pour-un-etablissement-de-classe-1-ou-2',
    step12SidePanelListItem3IsUrl: 'Bruxelles',
    step12SidePanelListItem3Url:
      'https://be.brussels/services-en-ligne/travailler-entreprendre/entreprendre-a-bruxelles/permis-licences-autorisations?set_language=fr',
    step13Text1:
      "Toute personne indépendante, gérant d'entreprise (à l'exception d'un mandat non rémunéré), associé actif, société, etc., qui n'est pas liée par un contrat de travail doit adhérer à une caisse d'assurances sociales.",
    step13Text2:
      "Cela garantit certains droits et obligations en matière de sécurité sociale tels que les prestations familiales, les indemnités de maladie et d'invalidité, l'assurance maternité, votre pension et l'assurance faillite.",
    step13ExternalLinkText:
      "Liste des caisses d'assurances sociales en Belgique",
    step13ExternalLinkUrl:
      'https://www.inasti.be/fr/caisses-dassurances-sociales?_ga=2.84272153.1048966331.1512132709-1783043858.1512132709',
    step13SidePanelTitle: "Affiliation à une caisse d'assurances sociales",
    step13SidePanelText1Part1:
      "Des informations sur les options de caisses d'assurances sociales en Belgique, le bon moment pour le faire et des détails sur la contribution peuvent être trouvés ",
    step13SidePanelTextPart2IsUrl: 'ici.',
    step13SidePanelTextPart2Url:
      'https://sinstallerenbelgique.be/fr/faire-des-affaires/se-lancer-comme-independant',
    step13SidePanelText2Part1:
      "Notez également que, en tant que travailleur indépendant, lorsque vous adhérez à une caisse d'assurances sociales, vous êtes obligé d'adhérer à une ",
    step13SidePanelText2Part2IsUrl: 'mutuelle',
    step13SidePanelText2Part2Url:
      'https://www.inami.fgov.be/fr/professionnels/autres/mutualites/Pages/contactez-mutualites.aspx',
    step13SidePanelText2Part3: ' aussi.',
    step13SidePanelText3Part1:
      "L'affiliation à une caisse d'assurances sociales et à une mutuelle peut être effectuée individuellement. ",
    step13SidePanelText3Part2IsUrl: "Guichets d'entreprise agréés",
    step13SidePanelText3Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/demarches-aupres-dun-guichet/les-guichets-dentreprises',
    step13SidePanelText3Part3:
      ' proposent également ces services moyennant des frais.',
    step14text:
      "Toute entreprise souhaitant employer du personnel en Belgique doit s'inscrire en tant qu'employeur auprès de l'Office national de sécurité sociale (ONSS).",
    step14SidePanelText:
      "Toute entreprise souhaitant employer du personnel en Belgique doit s'inscrire en tant qu'employeur auprès de l'Office national de sécurité sociale (ONSS).",
    step14SidePanelExternalLink: 'Office national de sécurité sociale',
    step14SidePanelExternalLinkUrl:
      'https://www.socialsecurity.be/site_fr/general/first_visit/todo/first-worker.htm',
    step14SidePanelTitle: "S'inscrire en tant qu'employeur",
    step14SidePanelText1:
      "Si vous embauchez un ou plusieurs travailleurs pour la première fois, voici les étapes à suivre auprès de l'ONSS :",
    step14SidePanelListItem1Part1:
      "Identifiez-vous en tant qu'employeur sur le ",
    step14SidePanelListItem1Part2IsUrl: 'service en ligne WIDE',
    step14SidePanelListItem1Part2Url:
      'https://www.socialsecurity.be/site_fr/employer/applics/dimona/index.htm',
    step14SidePanelListItem1Part3:
      " : vous recevez le numéro ONSS qui vous identifie en tant qu'employeur",
    step14SidePanelListItem2Part1:
      "Faites une déclaration immédiate d'emploi sur le ",
    step14SidePanelListItem2Part2IsUrl: 'service en ligne DIMONA',
    step14SidePanelListItem2Part2Url:
      'https://www.socialsecurity.be/site_fr/employer/applics/dimona/index.htm',
    step14SidePanelListItem2Part3:
      " : lors de l'embauche de votre premier employé",
    step14SidePanelListItem3Part1:
      'Effectuez une déclaration multifonctionnelle trimestrielle avec le ',
    step14SidePanelListItem3Part2IsUrl: 'service en ligne DmfA',
    step14SidePanelListItem3Part2Url:
      'https://www.socialsecurity.be/site_fr/employer/applics/dmfa/index.htm',
    step14SidePanelListItem3Part3:
      " : En outre, vous devez soumettre une déclaration multifonctionnelle trimestrielle à l'ONSS, qui précise le travail et la rémunération de tous vos employés.",
    step14SidePanelText2Part1:
      'Ces formalités sont gratuites si vous les effectuez vous-même. Des frais sont facturés si vous passez par des ',
    step14SidePanelText2Part2IsUrl: "guichets d'entreprise agréés.",
    step14SidePanelText2Part2Url:
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/demarches-pour-creer-une/demarches-aupres-dun-guichet/les-guichets-dentreprises',
    step14SidePanelText3:
      "Plus d'informations sur comment s'inscrire en tant qu'employeur en Belgique.",
  },
};
