/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

export default {
  footer: {
    service: 'Belgian Federal public service ',
    lastUpdated: 'Last updated on: ',
    contactButtonTitle: 'Do you want to contact us ?',
    contactButtonTextImprove: 'Help us improve',
    contactButtonTextReport: 'Report an obstacle',
    contactButtonTextContact: 'Contact Assistance Services',
  },
  header: {
    example:
      'For example: professional qualification, licenses, technical regulations, ...',
    language: 'Language',
    placeholder: 'I am looking for…',
    search: 'Search',
    title: 'How can I help you ?',
  },
  landing: {
    asterix:
      '* Applying for recognition via the central application form is not yet possible for all regulated professions. The form is provisionally also only accessible using eID or itsme®. Access via eIDAS will be possible from a next phase.',
    buttons: [
      'Find out more here',
      'More information',
      'Towards the application form*',
      'Find out more here',
      'Find out more here',
      'Wallonia',
      'Flanders',
      'Brussels',
      'Find out more here',
    ],
    colStepsEndText: 'You are ready to do business!',
    intro: {
      step: {
        descriptions: [
          'In the centre of Europe, Belgium is the ideal location for your company.',
          'After fulfilling some specific obligations, you will be able to fulfill the starting formalities.',
          'Start your business in Belgium with minimal costs.',
        ],
        question: 'How to get started?',
      },
      title: 'Start your business in Belgium',
    },
    links: [
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business',
      'https://economie.fgov.be/fr/themes/entreprises/creer-une-entreprise/affinez-votre-projet',
      'https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/incorporation-company-legal',
      'https://www.wallonie.be/fr/demarches/demander-un-permis-denvironnement-ou-un-permis-unique-pour-un-etablissement-de-classe-1-ou-2',
      'https://www.vlaanderen.be/omgevingsvergunning',
      'https://urbanisme.irisnet.be/lepermisdurbanisme/autres-premis-et-certificats/le-permis-unique?set_language=fr',
      'https://settlinginbelgium.be/en',
      '/search?q=professional%20qualification%20as&lang=en',
      'https://competencerecognition.belgium.be/app/',
    ],
    texts: [
      <>
        Before setting up a business, you need to make some preparations. This
        will allow you to fully concentrate on the development of your company
        after its foundation. These preparations include:
        <br />
        <br />
        <ul>
          <li>
            Opening a professional bank account with a recognized financial
            institution.
          </li>
          <br />
          <li>Choosing a location and property if needed for your business.</li>
          <br />
          <li>
            Deciding on the trade name of your company. Under which status?
          </li>
          <br />
          <li>
            Would you like to work as a self-employed person in your main
            profession, as a secondary profession or as an equivalent to a
            secondary profession?
          </li>
          <br />
          <li>Opt for a company or a one-man business?</li>
          <br />
          <li>
            If you opt for a company, you will also have to perform the
            necessary pre-registration acts. This includes drawing up a
            financial plan and draft articles of association.
          </li>
        </ul>
      </>,
      <>
        Some professions are regulated. This means that only those who meet
        certain conditions can obtain recognition to practise these professions.
        <br />
        <br />
        Such recognition of your qualifications must be requested in advance.
        The rules for this differ in principle depending on whether you
        establish yourself in Belgium or wish to practice this profession
        temporarily or occasionally in Belgium.
      </>,
      <>
        In this step you complete the remaining starter formalities. Your
        company will have to be registered with certain authorities. In
        addition, it is important to obtain the legal permits or authorizations
        needed to carry out all your activities.
        <br />
        <br />
        The starter formalities concern the following elements:
        <br />
        <br />
        <div className="ContentLinks ContentLinks--col">
          <a
            href="https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/steps-take-business-counter"
            target="_blank"
            className="Link Links--small"
            rel="noreferrer"
          >
            Your registration at Crossroads Bank for Enterprises
          </a>
          <a
            href="https://finance.belgium.be/en/enterprises/vat/declaration/registration-change-cancellation-activity"
            target="_blank"
            className="Link Links--small"
            rel="noreferrer"
          >
            Your registration with the VAT administration
          </a>
          <a
            href="https://settlinginbelgium.be/en/do-business/register-as-an-employer-in-belgium"
            target="_blank"
            className="Link Links--small Links--externalLink"
            rel="noreferrer"
          >
            Your possible registration as an employer
          </a>
          <Link
            to="/home?lang=en"
            target="_blank"
            className="Link Links--small"
          >
            Your application for relevant sector and activity permits
          </Link>
        </div>
      </>,
      <>
        Before you can start up a business you first have to put some things in
        order. This both on a personal level (study, training, ...) and for your
        company (market research, financing, business plan, ...):
        <br />
        <br />
        <ul>
          <li>
            Personal conditions that must be met, such as legal capacity, age,
            diploma or relevant knowledge.
          </li>
          <br />
          <li>
            Demonstrate that you have done the necessary research to demonstrate
            the feasibility of your project. Your business plan should include a
            project presentation, an environmental analysis, a commercial plan
            and a financial plan.
          </li>
        </ul>
      </>,
      <>
        Once you have made the decision to carry out your business activities
        through a company, you will need to set up a legal entity.
        <br />
        <br />
        After performing the pre-registration acts, you will then have to draw
        up the deed of incorporation of the legal entity. If you opt for an
        N.V., B.V. or C.V., you will be required to engage the services of a
        civil-law notary.
        <br />
        <br />
        In order to complete the creation of your legal entity, the deed of
        incorporation must be filed with the clerk\'s office of the
        corporate court of the seat of your legal entity. Afterwards, you will
        receive the company number of your legal entity.
      </>,
      <>
        Depending on the concrete activities and characteristics of your
        company, you may be required to apply for some permits:
        <br />
        <br />
        <ul>
          <li>
            You will be able to apply for all permits and applications within
            the framework of environmental, land subdivision and spatial
            planning procedures to the relevant regional services.
          </li>
          <br />
          <li>
            In addition, you may also need a permit to perform certain
            activities.
          </li>
        </ul>
        <br />
        Find out below which permit(s) per Region you need to have to start your
        business.
      </>,
      <>
        As a self-employed person, helper or assisting spouse, you are
        responsible for taking care of your social coverage. You therefore need
        to register before starting your self-employed activity.
        <br />
        <br />
        You pay social security contributions to your insurance fund in order to
        be entitled to family benefits, sickness and disability benefits,
        maternity insurance, your pension and bankruptcy insurance.
        <br />
        <br />
        In addition, you will also have to take out health insurance and join a
        child benefit fund.
      </>,
    ],
    text2:
      'These regulated professions and more information on the application for recognition of qualifications can be found in our catalog.',
    titles: [
      'Take the necessary preparatory actions',
      'Have your professional qualification recognised',
      'Start-up formalities',
      'Arrange your private obligations',
      'Registration of the legal entity',
      'Obtain the necessary environmental or urban planning permits',
      'Affiliation to a social insurance fund',
    ],
  },
  procedure: {
    available: 'At the moment only available for Belgians',
    body: 'Would you like to initiate the procedure of requesting a professional qualification? I\'m happy to redirect you.',
    buttonStart: 'Start procedure',
    disclamer:
      'In order to log in, you will need to identify yourself. Please prepare your electronic ID card, or your smartphone.',
    notFinding: 'Could not find what you were looking for?',
    buttonContact: 'Contact Be-Assist',
    beAssistURL:
      'https://economie.fgov.be/en/themes/enterprises/starting-business/conditions-entering/belgian-support-centre',
    contact:
      'You can always contact the Belgian Support Centre for the Recognition of Professional Qualifications for more information.',
  },
  result: {
    action: 'Action',
    average: 'Average processing time',
    back: 'Back to results',
    competent: 'Competent authority',
    contact: 'Contact information',
    cost: 'Cost',
    description: 'Description',
    eligibility: 'Eligibility criteria',
    fiche: 'Fiche information',
    last: 'Last modified',
    public: 'Public Organisation',
    procedure: 'Procedure',
    required: 'Required documents',
    result: 'Result',
    website: 'Website',
  },
  search: {
    buttonContact: 'Contact assistance services',
    all: 'All results',
    brussel: 'Brussels',
    federal: 'Federal',
    flemish: 'Flanders',
    noResult: {
      button: 'Contact Belgian assistance services',
      desc1: 'I did not find any results.',
      desc2:
        'You can always contact the Belgian assistance services for more information.',
      link: 'https://www.belgium.be/en/belgian_assistance_and_problem_solving_services_single_digital_gateway',
      title: 'No search result',
    },
    title: 'search results',
    walloon: 'Wallonia',
  },
  tab: {
    card: {
      address: {
        country: '1000 Brussels',
        name: 'Belgian assistance center',
        road: 'City Atrium, Vooruitgangstraat 50',
      },
      email: {
        email: 'BE_assist@economie.fgov.be',
        label: 'Email',
      },
      fax: {
        label: 'Fax',
        mention: '(toll free number)',
      },
      online: 'Online form (FR, NL, DE, EN)',
      phone: {
        label: 'Phone',
        mention: '(toll free number)',
      },
    },
    feedback: {
      buttons: [
        'Help us improve',
        'Report an obstacle',
        'Contact assistance services',
      ],
      link: [
        'https://foq.youreurope.europa.eu/info-service-ft?lang=en',
        'https://fosmo.youreurope.europa.eu/',
        'https://www.belgium.be/en/belgian_assistance_and_problem_solving_services_single_digital_gateway'
      ]
    },
    resultCount: ['Displaying ', ' results out of ', ''],
  },
  thumbs: {
    popup:
      'Thanks for your feedback! I\'m learning every day, to show you more relevant articles in the future and to better help you next time!',
  },
};
